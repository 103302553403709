<script setup>
function mouseOverButton(e){
    e.target.style.fill = '#e53155';
}

function mouseleaveButton(e){
    e.target.style.fill = 'crimson';
}

const emit = defineEmits([
    'toggleTimer'
])
</script>

<template>
    <svg
        @click="emit('toggleTimer')"
        @mouseover="mouseOverButton"
        @mouseout="mouseleaveButton"
        class="toggle-timer-button"
        fill="crimson"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24px"
        height="24px">
        <path d="M 5 5 L 5 19 L 19 19 L 19 5 L 5 5 z"/>
    </svg>

</template>

<style scoped lang="scss">

</style>
