<template>

    <SpaceBuilder
        @catchRowClicked="{}"/>

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "ReccuringView.vue",
    components: {SpaceBuilder, SpaceMenu,},
    }
</script>

<style lang="scss">

.reccuring{

    .date{
        $width: 120px!important;
        @include assignValue(width max-width min-width, $width);
    }

    .reccuring_transaction_template_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .reccuring_period_type_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .reccuring_receiver_type_id{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .reccuring_receiver_mail{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }



}

</style>
