<template>
    <routerView/>
</template>

<script>

import {defineAsyncComponent, inject, onMounted, reactive, ref, watch, watchEffect} from "vue";
import Widgets from "@/components/tools/Widgets.vue";
import NotificationsTool from "@/components/tools/NotificationsTool.vue";

export default {
    name: 'App',
    components: {
        NotificationsTool,
        Widgets,
        Datalists: defineAsyncComponent(() => import('@/components/elements/Datalists')),
        Trackers: defineAsyncComponent(() => import('@/components/tools/Trackers.vue'))
    },
    props: {

    },
    setup(props){


        return {};

    }

}
</script>

<style lang="scss">

@import "@/assets/scss/_btn.scss";

body {
    font-weight: normal;
    font-size: 12px;
    background-color: cadetblue;
    color: white;
    margin: 0;
}

#app {
    font-family: Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button{
    @extend .btn__base;
}

.header{
    padding: 15px;
    background-color: #262626;
    flex: 0 1 auto;
}

.content{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    .menu{
        width: 100%;
    }
}

input{
    background-color: transparent;
    border: solid 1px #f0f0f0;
    border-radius: 3px;
    padding-left: 3px;
    color: #212121;
    height: 18px;
}

select{
    border-radius: 3px;
}

input:focus,
input:focus-visible,
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-visible
/*select:focus,
select:focus-visible,
option:focus,
option:focus-visible*/{
    background-color: #e0e0e0!important;
    border-color: darkorange;
    outline-style: none;
    /*font-weight: bold;*/
    /*background-color: transparent!important;*/
    /*-webkit-background-clip: text;*/
    -webkit-text-fill-color: #2c2c2c;
}

table td, table th{
    border: 1px solid #f0f0f0;
    padding: 1px 3px;
}

table th{
    background-color: #3f3f3f;
}

.error-label,
.alert-label,
.success-label,
.no-data{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    span{
        font-weight: bold;
        padding: 3px;
        border-radius: 3px;
    }
}

.error-label{
    span{
        color: #f0f0f0;
        background-color: crimson;
    }
}

.no-data{
    span{
        background-color: $lightGray;
        color: #2c2c2c;
    }
}

.alert-label{
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    span{
        background-color: gold;
        color: $lighterGray;
    }
}

.success-label{
    margin-bottom: 0;
    span{
        color: #f0f0f0;
        background-color: #00b050;
    }
}

a{
    color: #f0f0f0;
    &:hover{
        color: darken(#f0f0f0, 5)
    }
}

.toggle-timer-button{
    &:hover{
        cursor: pointer;
    }
}

.manager,
.dialog{
    .inner{
        .data-tab{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-left: auto;
            margin-right: auto;
            border-radius: 3px;

            .data{
                display: flex;
                flex-flow: column;
                //width: fit-content;
                height: fit-content;
                //min-height: 150px;
                max-height: 50vh;
                overflow-y: auto;
                position: relative;
                padding-bottom: 5px;
                box-sizing: content-box;

                .space {
                    //height: fit-content;
                    margin: 0 auto;
                }

                &.empty{
                    padding-bottom: 0;
                }

            }

            .btn{
                margin: 0;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                padding: 5px;
                color: #f0f0f0;
            }

            .project-transaction,
            .activity{
                margin: 0 auto;
            }

            .error-label,
            .no-data{
                margin-top: 10px;
                margin-bottom: 0;
            }

        }

    }

    &.project-invoice-dropper,
    &.request-invoice-dropper{
        .inner{
            width: 600px;

            .message{
                font-size: 30px;
            }

            .drop-area{
                margin-top: 30px;
            }

            .form{

                div{
                    font-size: 14px;
                    min-height: 25px;
                    padding: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    label{
                        flex-basis: 25%;
                        text-align: right;

                        font-weight: bold;
                    }

                    span{
                        text-align: left;
                        flex-basis: 75%;
                        max-width: 250px;
                    }
                }

                input{
                    height: 25px;
                    width: 200px;
                    color: $darkGray;
                    font-weight: lighter;
                    background-color: $whiteGray;
                    font-size: 12px;
                    border-color: $goldOrange;

                    &:focus,
                    &:focus-visible,
                    &:-webkit-autofill,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:focus-visible{
                        border-color: $goldOrange;
                        font-weight: lighter;
                        background-color: $whiteGray!important;
                        font-size: 12px;
                        -webkit-text-fill-color: $darkGray;
                        -webkit-box-shadow:0 0 0 50px $whiteGray inset;
                    }
                }
            }

            .files{
                display: flex;
                flex-flow: column;
                margin-top: 10px;
                width: 100%;

                .file{
                    height: 25px;
                    padding: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .icon,
                    label,
                    span{
                        display: flex;
                        align-items: center;
                        height: 25px;
                    }

                    .icon{
                        flex-basis: 4rem;
                        justify-content: end;

                        img{
                            width: 20px;
                            height: auto;
                            cursor: pointer;
                        }
                    }



                    label{
                        //flex-basis: 9rem;
                        text-align: right;
                        font-weight: bold;
                    }

                    span{
                        text-align: left;
                        flex-basis: 35rem;
                        max-width: 200px;
                    }
                }

            }

            button{
                @extend .btn__gold;
                margin-top: 16px;
                font-size: 16px;
                padding: 10px 15px;
            }
        }

    }

    &.income-request,
    &.obligation-request{

        .inner {
            min-width: 550px;

            .tool-box{
                margin-top: 5px;
                margin-bottom: 10px;

                button{
                    @extend .btn__gold
                }
            }

            .helper-text,
            .history-text{
                line-height: 20px;

                button{
                    @extend .btn__gold;
                    //padding: 3px;
                    //margin: 0;
                    height: unset;
                }
            }

            .data-tabs{
                .btn{
                    @extend .btn__dark;
                    margin: 0 1px 0 1px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    padding: 5px;
                    color: #f0f0f0;

                    &:disabled{
                        background-color: $whiteGray;
                        color: $darkGray;
                    }

                    &.select{
                        border-bottom-right-radius: 3px;
                        border-bottom-left-radius: 3px;
                        margin: 3px 3px 10px;
                    }
                }

                .data-tab{
                    border: 2px $whiteGray solid;
                    margin-top: 0;
                    padding: 5px 5px 2px;

                    .data{
                        max-height: 40vh;
                    }
                }

                .suggestion,
                .search{
                    .space{
                        tbody tr{
                            cursor: pointer;
                        }
                    }
                }

            }
        }
    }
}

.data-tabs{
    margin: 10px 0;

    .btn{
        margin: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        padding: 5px;
        color: #f0f0f0;
    }
}


</style>
