<template>
    <datalist v-for="datalist in datalistTables" :id="datalist">
        <option v-for="option in getOptions(datalist)" :value="option.name" :data-id="option.id"/>
    </datalist>
</template>

<script>
import {inject} from "vue";
import {datalistTables} from "@/app/Settings";

export default {
    name: "Datalists",
    setup(props){
        const env = inject('env')

        function getOptions(table){
            return env.configuration[table]
        }

        return {datalistTables, getOptions}

    }

}
</script>

<style scoped>

</style>
