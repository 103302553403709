<script setup>
import DialogBase from "@/components/dialog/DialogBase.vue";
</script>

<template>
    <DialogBase
        :dialog="{
                message: 'Invoice request',
                type: 'request-invoice-dropper'
            }"
    >
        drop your invoice
    </DialogBase>

</template>

<style scoped lang="scss">

</style>