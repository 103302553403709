<template>
    <SpaceBuilder
        ref="spaceBuilder"
        :attrs="attrs"
        @dataFetched="dataFetched"
        @catchRowClicked="openCommentManager"/>
</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject, ref} from "vue";
import {useRouter} from "vue-router";
export default {
    name: "ThreadView.vue",
    components: {SpaceBuilder, SpaceMenu,},
    props: [
        'attrs'
    ],
    setup(props){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type ThreadService */
        const threadService = inject('ThreadService')

        /** @type Dialog */
        const dialog = inject('Dialog')

        let spaceBuilder = ref()
        let attrs = props.attrs

        function dataFetched(){
            if(attrs.directCommentLink) {
                env.service = 'thread'
                let thread = spaceBuilder.value.data['original'].find(row=>row.id===Number(props.attrs.id)) // try to get thread row from fetched space builder dat
                if(thread){
                    // thread was found in data
                    openCommentManager({row: thread})
                }
                else{
                    // thread row was not found in data
                    // fetch it
                    threadService.getOne(props.attrs)
                      .then((thread)=>{
                          openCommentManager({row: thread})
                      })
                      .catch(dialog.error)
                }
            }
        }

        function openCommentManager({row:thread}) {
            spaceBuilder.value.methods.openCommentManager({
                thread,
                attrs: {
                    service: thread.service,
                    space: thread.space,
                    id: thread.record,
                }
            })
        }

        return {
            openCommentManager,
            spaceBuilder,
            dataFetched
        }

    }
}
</script>

<style lang="scss">

.thread{

    tbody tr:hover{
        cursor: pointer;
    }

    .thread_created{
        $width: 110px!important;
        @include assignValue(width max-width min-width, $width);
    }

    .thread_space{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .thread_record_id{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
