
export default class CommentRoute{

    constructor(attrs) {
        let table = attrs.table
        if((attrs.path === 'get' || attrs.path === 'filter') && !attrs['threadId']){
            throw new Error('Add first comment to open thread')
        }
        this.get = 'comment/thread/'+attrs['threadId']; // Get thread for one original row
        this.getLast = 'comment/last/'+attrs['threadId']; // Get last thread comment
        this.getOne = 'comment/'+attrs['id'];
        this.create = 'comment/'+attrs['table']+'/'+attrs['recordId'];
        this.filter = 'comment/filter/'+attrs['threadId'];
        this.update = 'comment/'+attrs['id'];
        this.delete = 'comment/'+attrs['id'];
    }

}
