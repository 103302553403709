
import TransactionView from "@/components/view/TransactionView.vue";
import ItemView from "@/components/view/ItemView.vue";
import FileView from "@/components/view/FileView.vue";
import BankView from "@/components/view/BankView.vue";
import ReportView from "@/components/view/ReportView.vue";
import StashView from "@/components/view/StashView.vue";
import ProjectView from "@/components/view/ProjectView.vue";
import BalanceView from "@/components/view/BalanceView.vue";
import PaymentView from "@/components/view/PaymentView.vue";
import ObligationView from "@/components/view/ObligationView.vue";
import CashView from "@/components/view/CashView.vue";
import DueView from "@/components/view/DueView.vue";
import VatView from "@/components/view/VatView.vue";
import InvoicingView from "@/components/view/InvoicingView.vue";
import ContactView from "@/components/view/ContactView.vue";
import CrewMemberSettingView from "@/components/view/CrewMemberSettingView.vue";
import TaskView from "@/components/view/TaskView.vue";
import TopPurchaser from "@/components/view/TopPurchaser.vue";
import TopSupplier from "@/components/view/TopSupplier.vue";
import UnresolvedThread from "@/components/view/UnresolvedThread.vue";
import AwaitingReceivable from "@/components/view/AwaitingReceivable.vue";
import FutureReceivable from "@/components/view/FutureReceivable.vue";
import BankUnpair from "@/components/view/BankUnpair.vue";
import BankBalance from "@/components/view/BankBalance.vue";
import HomeObligation from "@/components/view/HomeObligation.vue";
import TemplateView from "@/components/view/TemplateView.vue";
import BacklogView from "@/components/view/BacklogView.vue";
import ThreadView from "@/components/view/ThreadView.vue";
import InvoicesSeriesView from "@/components/view/InvoicesSeriesView.vue";
import ContractsSeriesView from "@/components/view/ContractsSeriesView.vue";
import BankAccountSettingView from "@/components/view/BankAccountSettingView.vue";
import companySettingView from "@/components/view/CompanySettingView.vue";
import gapiSettingView from "@/components/view/GapiSettingView.vue";
import crewSettingView from "@/components/view/CrewSettingView.vue";
import categorySettingView from "@/components/view/CategorySettingView.vue";
import crewStat from "@/components/view/CrewStat.vue";
import contactAccount from "@/components/view/ContactAccount.vue";
import StatsView from "@/components/view/StatsView.vue";
import ReccuringView from "@/components/view/ReccuringView.vue";
import FutureObligation from "@/components/view/FutureObligation.vue";
import InvoiceRequestIncomeView from "@/components/view/InvoiceRequestIncomeView.vue";
import InvoiceRequestObligationView from "@/components/view/InvoiceRequestObligationView.vue";
import ProformasSeriesView from "@/components/view/ProformasSeriesView.vue";
import HistoryView from "@/components/view/HistoryView.vue";

import {defineAsyncComponent} from "vue";
import historyView from "@/components/view/HistoryView.vue";

export default{
    install: (app)=>{
        app.component('TransactionView', TransactionView)
        app.component('ItemView', ItemView)
        app.component('FileView', FileView)
        app.component('BankView', BankView)
        app.component('ReportView', ReportView)
        app.component('StatsView', StatsView)
        app.component('StashView', StashView)
        app.component('ProjectView', ProjectView)
        app.component('BalanceView', BalanceView)
        app.component('PaymentView', PaymentView)
        app.component('ObligationView', ObligationView)
        app.component('CashView', CashView)
        app.component('DueView', DueView)
        app.component('VatView', VatView)
        app.component('InvoicingView', InvoicingView)
        app.component('InvoiceRequestIncomeView', InvoiceRequestIncomeView)
        app.component('InvoiceRequestObligationView', InvoiceRequestObligationView)
        app.component('ReccuringView', ReccuringView)
        app.component('ContactView', ContactView)
        app.component('CrewMemberSettingView', CrewMemberSettingView)
        app.component('TaskView', TaskView)
        app.component('TopPurchaser', TopPurchaser)
        app.component('TopSupplier', TopSupplier)
        app.component('UnresolvedThread', UnresolvedThread)
        app.component('AwaitingReceivable', AwaitingReceivable)
        app.component('FutureReceivable', FutureReceivable)
        app.component('BankUnpair', BankUnpair)
        app.component('BankBalance', BankBalance)
        app.component('HomeObligation', HomeObligation)
        app.component('FutureObligation', FutureObligation)
        app.component('TemplateView', TemplateView)
        app.component('BacklogView', BacklogView)
        app.component('ThreadView', ThreadView)
        app.component('InvoicesSeriesView', InvoicesSeriesView)
        app.component('ProformasSeriesView', ProformasSeriesView)
        app.component('ContractsSeriesView', ContractsSeriesView)
        app.component('BankAccountSettingView', BankAccountSettingView)
        app.component('companySettingView', companySettingView)
        app.component('gapiSettingView', gapiSettingView)
        app.component('crewSettingView', crewSettingView)
        app.component('categorySettingView', categorySettingView)
        app.component('crewStat', crewStat)
        app.component('historyView', historyView)
        app.component('contactAccount', contactAccount) // cannot load async as style would not be applied
        app.component('subcategorySettingView', defineAsyncComponent(() => import('@/components/view/SubcategorySettingView.vue')))
        app.component('tagSettingView', defineAsyncComponent(() => import('@/components/view/TagSettingView.vue')))
    }
}
