<template>
     <SpaceBuilder
         ref="spaceBuilder"
         :attrs="attrs"
         @catchRowClicked="openRequestManager"
         @run="catchRun"
     />

</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject, reactive, ref} from "vue";
export default {
    name: "InvoiceRequestInView.vue",
    components: {SpaceBuilder},
    props: [
        'attrs'
    ],
    setup({props}){

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type TransactionService */
        const transactionService = inject('TransactionService')

        /** @type DriveService */
        const driveService = inject('DriveService')

        /** @type InvoicingService */
        const invoicingService = inject('InvoicingService')

        /** @type Google */
        const google = inject('Google')

        let spaceBuilder = ref()
        let invoicingDialog = reactive({})

        const methods = {

        }

        function catchRun({method, attrs}){
            methods[method]({attrs})
        }

        function openRequestManager(data){
            spaceBuilder.value.methods.openRequestManager({...data, type: 'income'})
        }

        return {catchRun, spaceBuilder, invoicingDialog, openRequestManager} // must include ref spaceBuilder as well
    }
}
</script>

<style lang="scss">


</style>
