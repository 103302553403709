
import {createApp, reactive} from 'vue'
import App from './App.vue'
import AppRouter from "@/app/AppRouter";
import BaseHeader from "@/components/header/BaseHeader.vue";
import UpperMenu from "@/components/header/UpperMenu.vue";
import ServiceMenu from "@/components/header/ServiceMenu.vue";
import Services from "@/app/Services";
import Views from "@/app/Views";
import Plugins from "@/app/Plugins";

let app = createApp(App);

// Create services container
app.service = {}
app.view = {}

/**
 * @type {SmartEnv}
 */
const env = reactive({
    appName: 'Capp.tn',
    dialog: false,
    data: {},
    google: {
        token: null,
        profile: {}
    },
    trackers: [],
    obligations: [],
    volume: new Date().getFullYear()
})

app.provide('env', env)
app.env = env;

// Build in vue3 router
app.use(AppRouter)

// Custom farmer modules
app.use(Plugins)
app.use(Services)
app.use(Views)

app.mount('#app');

