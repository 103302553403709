<template>
    <div class="cash currency-selector">
        <button
            v-for="currency in env.configuration['currency']"
            @click="selectCurrency(currency['currency_id'])"
            :disabled="attrs.currency===currency['currency_id']"
        >
            {{currency['currency_name']}}
        </button>
    </div>

    <table v-if="Object.keys(balances).length" class="cash balance">
        <tr>
            <th>Input</th>
            <td>{{ utilities.formatCurrency(balances['balance_positive']) }}</td>
        </tr>
        <tr>
            <th>Output</th>
            <td>{{ utilities.formatCurrency(balances['balance_negative']) }}</td>
        </tr>
        <tr>
            <th>Total</th>
            <td :class="utilities.addPositiveNegativeClass(balances['balance_total'])">{{ utilities.formatCurrency(balances['balance_total']) }}</td>
        </tr>
    </table>

    <SpaceBuilder
        ref="spaceBuilder"
        :attrs="attrs"
    />

</template>

<script>

import {inject, reactive, ref, watchEffect} from "vue";
import Button from "@/components/space/grid/Button.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "CashView.vue",
    components: {SpaceBuilder, Button, },
    props: ['attrs'],
    setup(props){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Capi} */
        const capi = inject('Capi')

        /** @type {Dialog} */
        const dialog = inject('Dialog')

        /** @type {Utilities} */
        const utilities = inject('Utilities')

        let balances = reactive({})

        let spaceBuilder = ref()

        props.attrs.currency = 'czk'

        watchEffect(()=>{
            capi.get({
                ...props.attrs,
                path: 'balance'
            })
                .then((balance)=>{
                    Object.assign(balances, balance)
                })
                .catch(dialog.nonCritical)
        })

        function selectCurrency(id){
            props.attrs.currency = id
        }

        return {
            spaceBuilder,
            env,
            selectCurrency,
            balances,
            utilities
        }
    }
}
</script>

<style lang="scss">

.cash{

    &.currency-selector{
        margin-top: 10px;

        button{
            margin: 3px;
            font-size: 12px;
            padding: 4px 6px;
            text-align: center;
            border-width: 0;
            background-color: #d9d8d8;
            color: black;
            text-transform: uppercase;
            height: 25px;
            cursor: pointer;
            font-weight: lighter;

            &:hover:not(:disabled){
                background-color: darken(#d9d8d8, 10);
            }

            &:disabled{
                background-color: $goldOrange;
                color: #f0f0f0;
            }
        }
    }

    &.balance{
        border-collapse: collapse;
        margin-top: 10px;
        align-items: center;

        th, td{
            text-align: right;
            padding: 3px 8px;
            &.negative{
                background-color: $red;
            }
            &.positive{
                background-color: $green;
            }
        }

    }

    .transaction_item{
        $width: 200px;
        @include assignValue(width max-width min-width, $width);
    }

    .subject{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_payment_transaction_type_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

}


</style>
