

export default{
    install: (app)=>{

        /**
         * @name ContactService
         */
        class ContactService {

            /**
             * @param id
             * @param attrs
             * @returns {Promise<Contact>}}
             */
            getOne(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'contact',
                    space: 'contact',
                    path: 'getOne',
                    id
                })
            }

            getContactAccounts(contactId, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'contact',
                    space: 'contact-account',
                    id: contactId
                })
            }


        }

        app.service.contact = new ContactService
        app.provide('ContactService', new ContactService)

    }
}

