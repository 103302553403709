
export default class StashRoute{

    constructor(attrs) {
        this.get =  'stash';
        this.getOne =  'stash/'+attrs['id'];
        this.create =  'file';
        this.filter =  'stash/filter/';
        this.closeStash = 'stash/close/'+attrs['id'];
    }


}
