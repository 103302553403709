<template>
    <SpaceBuilder
        ref="spaceBuilder"
        :attrs="attrs"
        @catchRowClicked="openBankManager"
    />
</template>

<script>

import {inject, onMounted, onUnmounted, reactive, ref} from "vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "BankView.vue",
    components: {SpaceBuilder, },
    props: [
        'attrs'
    ],
    setup(){

        let spaceBuilder = ref()

        function openBankManager({row}){
            spaceBuilder.value.methods.openBankManager({row})
        }

        return {
            openBankManager,
            spaceBuilder
        }

    }
}
</script>

<style lang="scss">

.space.bank{

    tbody tr:hover{
        cursor: pointer;
    }

}

.bank,
.payment,
.transaction-payment
{
    .transaction_item,
    .transaction_note {
        $width: 180px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_record_number,
    .payment_vs_number{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_ks_number{
        $width: 60px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_ss_number{
        $width: 60px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_origin_bank_account_prefix{
        text-align: right;
        $width: 60px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_origin_bank_account,
    .transaction_bank_account{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_origin_bank_code{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_supplier_contact_list{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .amount{
        $width: 75px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_currency_id{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_bank_transaction_type{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .payment_fio_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }
}

</style>
