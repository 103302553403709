<template>
    <button data-type="prompt" class="btn__prompt" :class="env.dialog.reverse?'red':'green'" @click="yes">Yes</button>
    <button data-type="prompt" class="btn__prompt" :class="env.dialog.reverse?'green':'red'" @click="no">No</button>
</template>

<script>
import {inject} from "vue";

export default {
    name: "Prompt",
    setup(props){
        /** @type {SmartEnv} */
        const env = inject('env')

        function yes(){
            env.dialog.hook = true
            env.dialog = null
        }

        function no(){
            env.dialog.hook = false
        }

        return {yes, no, env}

    }
}
</script>

<style scoped lang="scss">
button{
    margin-top: 10px;
}
</style>
