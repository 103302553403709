<script setup>
import {inject, ref, watchEffect} from "vue";
const emit = defineEmits(['openNotifications'])
/**
 * @type SmartEnv
 */
const env = inject('env')
let color = ref()
let notifications = ref(0)

watchEffect(()=>{
    notifications.value = !env.notifications?0:env.notifications.length
    color.value = env.notifications?'crimson':'#545454'
})

</script>

<template>
    <div
        class="notification"
        @click="emit('openNotifications')"
    >
        {{notifications}}
    </div>
</template>

<style scoped lang="scss">

.notification{
    background: v-bind(color);
    border: 2px solid $whiteGray;
    color: $whiteGray;

    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: 6px;
    text-align: center;
    cursor: pointer;
}

</style>
