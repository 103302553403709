
export default class FileRoute{

    constructor(attrs) {
        switch(attrs.space){
            default:
            case 'file':
                this.get =  'file';
                this.getOne =  'file/'+attrs['id'];
                this.create =  'file';
                this.filter =  'file/filter/';
                this.getParentFiles =  'file/parent/'+attrs['parentTable']+'/'+attrs['id'];
                this.getTransactionIssuedInvoices =  'file/transaction/invoices/'+attrs['id'];
                this.param =  'file/param/';
                this.update =  'file/'+attrs['id'];
                this.delete =  'file/'+attrs['id'];
                this.useQrCOdeAndOcrData = 'file/qr-ocr/'+attrs['id'];
                this.useIsdocData = 'file/isdoc/'+attrs['id'];
                this.getIsdocDataFromFile = 'file/isdoc/get';
                this.createContract = 'file/contract/'+attrs['id'];
                this.getObligationRequestFiles= 'file/request/obligation/'+attrs['id'];
                this.approveFile = 'file/approve/'+attrs['id'];
                break;

            case 'stash':
                this.get =  'stash';
                this.getOne =  'stash/'+attrs['id'];
                this.create =  'stash';
                this.filter =  'stash/filter/';
                this.param =  'stash/param/';
                this.update =  'stash/'+attrs['id'];
                this.delete =  'stash/'+attrs['id'];
                break;

            case 'transaction-file':
                this.get = 'file/transaction/'+attrs['id'];
                this.getOne =  'file/'+attrs['id'];
                this.create =  'file';
                this.filter =  'file/filter/';
                this.param =  'file/param/';
                this.update =  'file/'+attrs['id'];
                this.delete =  'file/'+attrs['id'];
                break;
        }
    }


}
