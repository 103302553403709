<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "BankAccountSettingView.vue",
    components: {SpaceBuilder}
}
</script>

<style lang="scss">

.bank-account-setting{

    .bank_account_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_prefix{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_account_number{
        $width: 85px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_iban{
        $width: 160px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_swift{
        $width: 85px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_bank_code{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_company_id{
        $width: 105px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_note{
        $width: 95px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_sync_token{
        $width: 400px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
