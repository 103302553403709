<template>
     <SpaceBuilder
         ref="spaceBuilder"
         :attrs="attrs"
         @catchRowClicked="openCommentManager"
     />
</template>

<script>

import {defineAsyncComponent, ref} from "vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";

export default {
    name: "UnresolvedThread.vue",
    components: {
        CommentManager: defineAsyncComponent(() => import('@/components/tools/CommentManager.vue')),
        SpaceBuilder,
    },
    props: [
        'attrs'
    ],
    setup(){

        let commentManager = ref()
        let spaceBuilder = ref()

        function openCommentManager({row:thread}) {
            spaceBuilder.value.methods.openCommentManager({
                thread,
                attrs: {
                    service: thread.service,
                    space: thread.space,
                    id: thread.record,
                    removeThreadIfClosed: true
                },
            })
        }

        return {
            openCommentManager,
            commentManager,
            spaceBuilder
        }
    }

}
</script>

<style lang="scss">


.unresolved-thread{

    thead{
        position: relative;
    }

    tbody{
        tr:hover{
            cursor: pointer;
        }
    }

    .thread_space{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .thread_record_id{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
