<template>
    <router-link
        v-for="button in env.configuration['menu_service'].filter(menu=>{
            return menu['menu_service_display'] && Number(env.login.user['level'])<=Number(menu['level'])
        })"
        :to="{name: 'view', params: {service: button.service}}">
        <button class="btn__silver" :disabled="button.service===env.service" >{{ button.name }}</button>
    </router-link>
</template>

<script>

import {inject, reactive} from "vue";

export default {
    name: "ServiceMenu",
    setup(){
        /**
         * @type {SmartEnv}
         */
        const env = inject('env')
        return {env}
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_btn.scss";
a.router-link-active button{
    @extend .btn__silver__active;
}

</style>
