import {createRouter, createWebHistory} from 'vue-router'
import BaseView from "@/components/view/BaseView.vue";
import ThreadView from "@/components/view/ThreadView.vue";
import PublicBoard from "@/components/boards/PublicBoard.vue";
import InternalBoard from "@/components/boards/InternalBoard.vue";
import ProjectInvoiceDropper from "@/components/tools/InvoiceDropper/ProjectInvoiceDropper.vue";
import RequestInvoiceDropper from "@/components/tools/InvoiceDropper/RequestInvoiceDropper.vue";

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/public',
        name: 'public',
        component: PublicBoard,
        children: [
            {
                path: '/public/project/:hash',
                component: ProjectInvoiceDropper,
                props: route => ({
                    hash: route.params.hash, // Pull `id` from route params
                })
            },
            {
                path: '/public/request/:uid',
                component: RequestInvoiceDropper
            }
        ]
    },
    {
        path: '/:service',
        name: 'service',
        component: InternalBoard,
        props: true,
        children: [
            {
                path: '/:service',
                name: 'view',
                component: BaseView,
                props: true,
                children: [{
                    path: '/:service/:space',
                    name: 'space',
                    component: BaseView,
                    props: true
                }]
            }
        ]
    },
    {
        path: '/thread/:id',
        name: 'comment',
        components: {
            mainRoute: InternalBoard,
            viewRoute: ThreadView
        },
        props: route => ({
            attrs:{
                directCommentLink: true,
                service: 'thread',
                space: 'thread',
                id: Number(route.params.id), // Pull `id` from route params
            }
        })
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]

const appRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default appRouter

