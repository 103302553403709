<script setup>

import {inject, reactive, ref, watch, watchEffect} from "vue";
import StartTimer from "@/components/tools/components/StartTimer.vue";
import StopTimer from "@/components/tools/components/StopTimer.vue";

const env = inject('env')
const capi = inject('Capi')
const dialog = inject('Dialog')
const props = defineProps([
    'tracker'
])

const emit = defineEmits([''])

/** @type Utilities */
const utilities = inject('Utilities')

let parentRowId = ref()
let close = ref()
let lastTimestamp;

watchEffect(()=>{
    if(props.tracker.active){
        env.trackers.forEach(tracker=> {
            if(tracker.task.id!==props.tracker.task.id) tracker.active = false
        })
        if(!props.tracker.counter) {
            props.tracker.counter = setInterval(()=>{
                increaseTimer()
            }, 1000)
        }

        if(!props.tracker.updater){
            props.tracker.updater = setInterval(()=>{
                updateTimer()
            }, 20000)
        }
    }
    else{
        updateTimer()
        clearTrackerVars()
        if(close.value) {
            props.tracker.open = false
        }
    }
})

watchEffect(()=>{
    if(props.tracker.task.id!==parentRowId.value || !parentRowId.value){
        parentRowId.value=props.tracker.task.id
        capi.create(
            {
                service: 'timer',
                path: 'create',
                automated: true
            },
            {
                timer_task_id: props.tracker.task['task_id'],
            })
            .then((response)=>{
                props.tracker.timer = response.item
            })
            .catch(dialog.error)
    }
})

function toggleTimer(){
    props.tracker.active = !props.tracker.active
}

function increaseTimer(){
    let seconds = 1;
    if(lastTimestamp){
        let endDate = new Date()
        seconds = Math.round((endDate.getTime() - lastTimestamp.getTime()) / 1000)
    }
    lastTimestamp = new Date()
    if(props.tracker.timer){
        props.tracker.task['tracked_time']=Number(props.tracker.task['tracked_time'])+seconds
        if(props.tracker.updateTotal)props.tracker.total['tracked_time']=Number(props.tracker.total['tracked_time'])+seconds
        props.tracker.timer['timer_tracked_time']=Number(props.tracker.timer['timer_tracked_time'])+seconds
    }
}

function updateTimer(){
    if(!props.tracker.timer) return
    capi.update(
        {
            service: 'timer',
            path: 'update',
            automated: true,
            id: props.tracker.timer.id
        },
        {
            timer_task_id: props.tracker.task['task_id'],
            timer_tracked_time: props.tracker.timer['timer_tracked_time']
        })
        .then((response)=>{
            //console.log('response', response)
        })
        .catch((e)=>{
            console.log('e', e)
        })
}

function getTaskName(){
    let typeName = props.tracker.task['task_type_id']?' : '+env.configuration['task_type'].find(item=>{
        return item['task_type_id'] === props.tracker.task['task_type_id']
    })['task_type_name']:''
    let name = props.tracker.task.name?` : ${props.tracker.task.name}`:``
    return props.tracker.task.parentTable === 'project'?`${props.tracker.task['project_name']}${typeName}${name}`:`${typeName}${name}`;
}

function getTrackedTime(){
    return utilities.formatTime(props.tracker.timer['timer_tracked_time'])
}

function closeTracker(){
    props.tracker.active = false
    close.value = true
}

function clearTrackerVars(){
    if(props.tracker.updater)clearInterval(props.tracker.updater)
    props.tracker.updater = null
    if(props.tracker.counter)clearInterval(props.tracker.counter)
    props.tracker.counter = null
    lastTimestamp = null
}

</script>

<template>
    <div class="tracker" v-if="tracker.timer">

        <span class="task">{{ getTaskName() }}</span>
        <span class="timer">{{ getTrackedTime() }}</span>
        <StopTimer
            v-if="props.tracker.active"
            @toggleTimer="toggleTimer"
        />
       <StartTimer
            v-else
            @toggleTimer="toggleTimer"
        />
        <div class="close" @click="closeTracker">
            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="14px" height="14px"><path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"/></svg>
        </div>
    </div>
</template>

<style scoped lang="scss">
.tracker{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    gap: 3px;
    background-color: $lighterGray;
    padding: 3px 6px;
    border-radius: 3px;

    .close{
        display: flex;
        align-items: center;
        color: $lighterGray;
        background-color: $whiteGray;
        padding: 3px;
        border-radius: 3px;
        &:hover{
            cursor: pointer;
            background-color: $lightGray;
        }
    }

    .timer{
        background-color: $red;
        padding: 3px 6px;
        border-radius: 3px;
        margin-left: 5px;
    }

    .task{
        font-weight: bold;
    }

}
</style>
