<template>

    <svg class="circular-loader"viewBox="25 25 50 50" >
        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" :stroke="color" stroke-width="8" />
    </svg>

</template>

<script>
import {ref} from "vue";

export default {
    name: "LoadingCircle",
    props: [
        'width',
        'color'
    ],
    setup(props){
        let width = ref(props.width?props.width:'20px')
        let color = ref(props.color?props.color:'#363636')
        return {
            width, color
        }
    }
}
</script>

<style scoped lang="scss">



.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: v-bind(width);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: v-bind(width);
    margin: 0;
}

.loader-path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
@-webkit-keyframes color {
    0% {
        stroke: v-bind(color);
    }
    40% {
        stroke: v-bind(color);
    }
    66% {
        stroke: v-bind(color);
    }
    80%, 90% {
        stroke: v-bind(color);
    }
}
@keyframes color {
    0% {
        stroke: v-bind(color);
    }
    40% {
        stroke: v-bind(color);
    }
    66% {
        stroke: v-bind(color);
    }
    80%, 90% {
        stroke: v-bind(color);
    }
}

</style>
