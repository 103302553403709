<script setup>

import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
const props = defineProps(['errors'])
console.log('errors mounted')
</script>

<template>
    <div class="error-label" v-if="errors">
        <span
            v-for="error in errors"
        >
            {{error}}
        </span>
    </div>
</template>

<style scoped lang="scss">

.error-label{
    //margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        margin: 2px;
    }
}

</style>
