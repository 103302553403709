
export default class CashRoute{

    constructor(attrs) {

        this.get = attrs.space+'/'+attrs.currency;
        this.getOne = "transaction/"+attrs["id"];
        this.create = attrs.space+'/'+attrs.currency;
        this.filter = attrs.space+'/filter/'+attrs.currency;
        this.update = 'transaction/'+attrs["id"];
        this.delete = 'transaction/'+attrs["id"];
        this.balance = attrs.space+'/balance/'+attrs.currency;

    }

}
