<template>

    <SpaceBuilder
        @catchRowClicked="{}"
    />

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "ItemView.vue",
    components: {SpaceBuilder, SpaceMenu,}
    }
</script>

<style lang="scss">

.item{

    .item_name{
        $width: 345px;
        @include assignValue(width max-width min-width, $width);
    }

    .item_vat_type_id{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
