<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "PaymentView.vue",
    components: {SpaceBuilder, }
}
</script>

<style lang="scss">


</style>
