<script setup>

import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
const props = defineProps(['successes'])
console.log('errors mounted')
</script>

<template>
    <div class="success-label" v-if="successes">
        <span
            v-for="success in successes"
        >
            {{success}}
        </span>
    </div>
</template>

<style scoped lang="scss">

.success-label{
    //margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        margin: 2px;
    }
}

</style>
