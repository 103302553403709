

export default{
    install: (app)=>{

        /**
         * @name NotificationService
         */
        class NotificationService {

            /**
             * @param id
             * @param attrs
             * @returns {Promise<Notification>}}
             */
            getOne(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'notification',
                    space: 'notification',
                    path: 'getOne',
                    id
                })
            }

            getPendingNotifications(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'notification',
                    space: 'notification',
                    path: 'getPendingNotifications'
                })
            }

            utilities = class {

            }

        }

        app.service.notification = new NotificationService()
        app.provide('NotificationService', new NotificationService)

    }
}

