
export default class InvoicingRoute{

    constructor(attrs) {
        switch(attrs.space){
            default:
                this.get = attrs.space;
                this.getOne = "transaction/"+attrs['id'];
                this.update = "transaction/"+attrs['id'];
                this.filter = attrs.space+"/filter/";
                this.searchTransactions = 'transaction/search/params';
                this.createInvoice = 'invoicing/'+attrs['id']
                this.reissueInvoice = 'invoicing/'+attrs['id']
                this.tryToGetExistingObligationRequest = 'invoicing/request/obligation/existing/'+attrs['id']
                this.tryToGetExistingIncomeRequest = 'invoicing/request/income/existing/'+attrs['id']
                this.createInvoiceObligationRequest = 'invoicing/request/obligation/create'

                break;

            case 'reccuring':
                this.get = attrs.space;
                this.getOne = attrs.space+"/"+attrs["id"];
                this.create = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.update = attrs.space+"/"+attrs["id"];
                this.delete = attrs.space+"/"+attrs['id'];
                this.duplicate = attrs.space+'/duplicate/'+attrs['id'];
                break;

            case 'invoice-request-income':
                this.createOrUpdateIncomeRequest = 'invoicing/request/income/create'
                this.get = 'invoicing/request/income';
                this.getOne = 'invoicing/request/income/'+attrs['id'];
                this.createTransaction = 'invoicing/request/income/transaction/create/'+attrs['id']
                this.createTransactionFromTemplate = 'invoicing/request/income/transaction/template/'+attrs['id']+'/'+attrs['templateId']
                this.cloneTransaction = 'invoicing/request/income/transaction/clone/'+attrs['id']+'/'+attrs['transactionId']
                this.pairTransaction = 'invoicing/request/income/transaction/pair/'+attrs['id']+'/'+attrs['transactionId']
                this.unpairTransaction = 'invoicing/request/income/transaction/unpair/'+attrs['id']

                break;

            case 'invoice-request-obligation':
                this.get = 'invoicing/request/obligation';
                this.getOne = 'invoicing/request/obligation/'+attrs['id'];
                this.createTransaction = 'invoicing/request/obligation/transaction/create/'+attrs['id']
                this.createTransactionFromTemplate = 'invoicing/request/obligation/transaction/template/'+attrs['id']+'/'+attrs['templateId']
                this.cloneTransaction = 'invoicing/request/obligation/transaction/clone/'+attrs['id']+'/'+attrs['transactionId']
                this.pairTransaction = 'invoicing/request/obligation/transaction/pair/'+attrs['id']+'/'+attrs['transactionId']
                this.unpairTransaction = 'invoicing/request/obligation/transaction/unpair/'+attrs['id']
                break;

        }

    }

}
