<template>
    <SpaceBuilder
        :attrs="attrs"
        @run="run"
    />
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject} from "vue";
export default {
    name: "ReportView.vue",
    components: {SpaceBuilder},
    props: [
        'attrs'
    ],
    setup(){

        /** @type Google */
        const google = inject('Google')

        /** @type Dialog */
        const dialog = inject('Dialog')
        function run({method, ...rest}){
            if(methods[method]) methods[method]({...rest})
        }

        const methods = {
            downloadPdfFile({row}){
                if(!row['pdfFile']) dialog.error('No valid PDF file')
                google.drive.files.open(row['pdfFile'])

            },
            downloadGpcFile({row}){
                if(!row['gpcFile']) dialog.error('No valid GPC file')
                google.drive.files.open(row['gpcFile'])
            }
        }

        return {
            run
        }
    }
}
</script>

<style lang="scss">

.report{

    margin-top: 0;

    .date{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .balance_date{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
    }

    .balance{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .report_button{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

}



</style>
