<template>

    <div class="container" :class="env.waiter.setting['dropBg']?'drop':''">
        <div class="message"  :class="{'fade-in': showMessageTimer, 'hide': !showMessageTimer}">
            <span v-if="env.waiter.message">{{env.waiter.message}}</span>
            <span v-else>Please wait</span>
        </div>

        <svg class="loader" :class="{'fade-in': showLoaderTimer}" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80px" height="80px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
            <path opacity="0.7" fill="#c3a551" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                <path fill="#f89634" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z">
            <animateTransform attributeType="xml"
                              attributeName="transform"
                              type="rotate"
                              from="0 20 20"
                              to="360 20 20"
                              dur="2s"
                              repeatCount="indefinite"/>
            </path>
        </svg>
    </div>



</template>

<script>
import {inject, reactive, ref} from "vue";

export default {
    name: "Waiter",
    setup(){
        /** @type {SmartEnv} */
        const env = inject('env')
        const css = inject('css')
        let showMessageTimer = ref(false);
        let showLoaderTimer = ref(false);

        showLoaderTimer.value = true;

        /*setTimeout(()=>{
            showLoaderTimer.value = true;
        }, 500)*/

        setTimeout(()=>{
            showMessageTimer.value = true;
        }, 1500)

        return {env, showMessageTimer, showLoaderTimer, css}
    }
}
</script>

<style scoped lang="scss">

.container{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.drop{
        background:rgba(10,10,0,0.6);
        padding: 10px;
        border-radius: 3px;
        width: 150px;
    }

}

.message{
    font-weight: bold;
}

.hide{
    opacity: 0;
}

.loader{

}


</style>
