<template>
    <div v-for="crew in env.login.user['crews']" >
        <a :class="{selected: highlightedName===crew['crew_id']}" @click="env.crew_id = crew.id" @mouseover="highlightCrew(crew.id)" @mouseout="highlightCrew()">

            <span v-if="highlightedName === crew.id">>>> </span>
            {{crew.name}}
            <span v-if="highlightedName === crew.id"> &lt&lt&lt </span>

        </a>
    </div>

</template>

<script>
import {inject, onUnmounted, reactive, ref} from 'vue';
import Utilities from "@/plugins/Utilities";
import ConfigurationService from "@/services/ConfigurationService";
import UserService from "@/services/UserService";
import {volumes, css} from "@/app/Settings";

/**
 * @typedef {Object} env
 * @param {Object} login
 */
export default {
    name: 'SelectCrew',
    props: [
        'dialog',
        'highlightedName',
        'crew'
    ],
    setup(props, context){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Utilities} */
        const Utilities = inject('Utilities')

        let highlightedName = ref(null)
        let crews = env.login.user['crews'];

        props.dialog.message = 'Select your crew'

        let defaultId = crews[0]['crew_id']

        const highlightCrew = (id = null) => {
            highlightedName.value = id;
        }

        /*const selectCrew = (crewId = defaultId) => {
            //Utilities.setCookie('crew_id', crewId);

            env.crew_id = crewId;
        }*/

        /**
         * Select crew after hitting enter
         */
        function selectCrewWithEnter(e){
            if(e.which === 13) {
                if(highlightedName.value) env.crew_id = highlightedName.value;
            }
        }

        /**
         * Browse crews with arrows
         * @param e
         */
        function browseCrews(e){
            if(e.which === 38 || e.which === 40) {
                if(e.which === 40){
                    selectNextCrew()
                }
                if(e.which === 38){
                    selectPreviousCrew()
                }
            }
        }

        function getActualCrewSelection(){
            return highlightedName.value?crews.findIndex(crew => crew['crew_id']===highlightedName.value):-1
        }

        /**
         * Select next crew by down arrow
         */
        function selectNextCrew(){
            let actualCrewIndex = getActualCrewSelection()
            if(crews.length>actualCrewIndex+1){
                highlightCrew(crews[actualCrewIndex+1]['crew_id'])
            }
        }

        /**
         * Select previous crew by up arrow
         */
        function selectPreviousCrew(){
            let actualCrewIndex = getActualCrewSelection()
            if(actualCrewIndex>0) highlightCrew(crews[actualCrewIndex-1].id)
            if(actualCrewIndex===-1) highlightCrew(crews[crews.length-1].id)
        }

        window.addEventListener('keyup', selectCrewWithEnter);
        window.addEventListener('keyup', browseCrews);

        onUnmounted(()=>{
            window.removeEventListener('keyup', selectCrewWithEnter)
            window.removeEventListener('keyup', browseCrews)
        })

        highlightCrew(defaultId)

        return {env, highlightCrew, highlightedName, css}
    }
}
</script>

<style scoped lang="scss">

button, input{
    margin-top: 5px;
}

div{
    a{
        font-size: 14px;
        cursor: pointer;

        &.selected {
            color: white;
            background-color: $goldOrange;
            border-radius: 3px;
        }
    }
}




</style>
