<template>
    <div data-type="dialog" class="dialog" :class="{[dialog.type]: dialog.type}" @click.self="close">
        <div class="inner" :class="{[dialog.type]: dialog.type}">
            <div class="message" :class="{[dialog.type]: dialog.type}" data-type="dialog" v-html="dialog.message?dialog.message:dialog.type==='error'?'Unknown error':''"></div>
            <slot>
                <component
                    :is="dialog.type"
                    :dialog="dialog
                "/>
            </slot>
        </div>
    </div>
</template>

<script>

import Login from "@/components/dialog/Login";
import SelectCrew from "@/components/dialog/SelectCrew.vue";
import Prompt from "@/components/dialog/Prompt.vue";
import Loading from "@/components/dialog/Loading.vue";
import DateTimePicker from "@/components/dialog/DateTimePicker.vue";

import {inject, onMounted, onUnmounted, ref} from "vue";

export default {
    name: "Dialog.vue",
    components: {Login, SelectCrew, Prompt, Loading, DateTimePicker},
    props: [
        'dialog'
    ],
    emits: [
        'close'
    ],
    setup(props, {emit}){

        /** @type Utilities */
        const utilities = inject('Utilities')

        let overrideDisableScrolling = props.dialog.setting?.doNotHoldScrolling

        function close(){
            if(!props.dialog.strict) emit('close')
        }

        onMounted(()=>{
            if(overrideDisableScrolling) return
            // To get the scroll position of current webpage
            let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
            let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;

            // if scroll happens, set it to the previous value
            window.onscroll = function() {
                window.scrollTo(LeftScroll, TopScroll);
            };
        })

        onUnmounted(()=>{
            if(overrideDisableScrolling) return
            let dialogs = utilities.getNumberOfOpenDialogs()
            if(!dialogs) window.onscroll = function() {};
        })

        let zindex = utilities.getZindex()+3

        return {close, zindex}
    }
}
</script>

<style lang="scss">

.dialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 2650px;
    height: 100%;
    background: rgba(0,0,0, 0.5);
    z-index: v-bind(zindex);

    .inner{
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: cadetblue;
        border-radius: 3px;
        padding: 20px 10px;
        min-width: 400px;

        .message{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;

            // todo prevratit, nechat nadpis velky a jenom v pripade succes nebo error, font zmensit
            &.success,
            &.error{
                font-size: 14px;
                margin-bottom: 0;
            }
        }

        &.success{
            background-color: #00b050;
        }

        &.error{
            background-color: crimson;
            span{
                font-size: 16px;
            }
        }

        .list{
            display: block;
            font-size: 14px;
            font-weight: normal;
        }

        span{
            /*background-color: $goldOrange;
            border-radius: 3px;
            padding: 5px 20px;*/

        }

    }

}

</style>
