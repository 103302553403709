<template>
    <button
        v-if="(column['space_column_no_content_if_null']&&value)||!column['space_column_no_content_if_null']"
        :class="[column.class?column.class:column.property]"
        @click="catchClick"
        >
        <component v-if="row.selected">
            ☒
        </component>
        <component v-else>
            ☐
        </component>

    </button>
</template>

<script>
import {inject, reactive, ref, watchEffect} from "vue";

export default {
    name: "Button",
    components: {},
    props:[
        'setting',
        'column',
        'value',
        'row'
    ],
    emits: [
        'rowsSelectionChanged'
    ],
    setup(props, {emit}){

        const catchClick = () => {
            props.row.selected = !props.row.selected
            emit('rowsSelectionChanged')
        }

        return {catchClick}
    }
}
</script>

<style scoped>



</style>
