
export default class BankRoute{

    constructor(attrs) {

        switch(attrs.space){
            case "bank":
                this.get = attrs.space;
                this.getOne = "payment/"+attrs["id"];
                this.filter = attrs.space+"/filter/";
                this.param = attrs.space+"/param/";
                this.update = "payment/"+attrs["id"];
                this.delete = attrs.space+"/";
                this.unpairPayment = attrs.space+"/unpair/"+attrs["id"];
                this.pairPayment = attrs.space+"/pair/"+attrs["id"]+"/"+attrs["transactionId"];
                this.cloneTransaction = attrs.space+"/clone/"+attrs["id"]+"/"+attrs["transactionId"];
                this.useTemplate = attrs.space+"/template/"+attrs["id"]+"/"+attrs["templateId"];
                this.createTransaction = attrs.space+"/create/"+attrs["id"];
                break;

            case "balance":
                this.get = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.total = attrs.space+"/total";
                break;

            case "payment":
                this.get = attrs.space;
                this.getOne = attrs.space+"/"+attrs["id"];
                this.create = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.param = attrs.space+"/param/";
                this.update = attrs.space+"/"+attrs["id"];
                this.delete = attrs.space+"/";
                break;

            case "transaction-payment":
                this.get = "payment/transaction/"+attrs["id"];
                this.getOne = "payment/"+attrs["id"];
                this.create = 'payment';
                this.filter = "payment/filter/";
                this.param = "payment/param/";
                this.update = "payment/"+attrs["id"];
                this.delete = "payment/";
                break;

            case "report":
                this.get = "report";
                this.getOne = "report/"+attrs["id"];
                this.filter = "report/filter/";
                this.downloadReportFile = "report/download/"+attrs["id"];
                this.downloadReportsFiles = "report/batch-download/";
                break;

            case 'bank-suggestion':
                this.get = 'bank/suggest/'+attrs['id'];
                this.getOne = 'transaction/'+attrs['id'];
                this.create = attrs.space;
                this.filter = 'bank/suggest/filter/';
                this.param = 'bank/suggest/param/';
                this.update = 'transaction/'+attrs['id'];
                this.delete = 'transaction/';
                break;

        }
    }
}
