<template>
    <SpaceBuilder
        ref="spaceBuilder"
        :attrs="attrs"
        @catchRowClicked="openTransactionManager"
    />
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {ref} from "vue";
export default {
    name: "FutureReceivable.vue",
    components: {SpaceBuilder, },
    props: [
        'attrs'
    ],
    setup(){

        let transactionManager = ref()
        let spaceBuilder = ref()

        function openTransactionManager(e) {
            spaceBuilder.value.methods.openTransactionManager(e)
        }

        return {
            openTransactionManager,
            transactionManager,
            spaceBuilder
        }
    }
}
</script>

<style lang="scss">

.future-receivable{

    thead{
        position: relative;
    }

    tbody tr:hover{
        cursor: pointer;
    }

    .transaction_supplier_contact_list{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_item{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
