import {ocrReadableFileTypes} from "@/app/Settings";
import {TextEncoder} from 'text-encoding'
import $ from 'jquery'
import ApiRouter from "@/app/ApiRouter";
import * as pdfjsLib from "pdfjs-dist";
import jsQR from "jsqr";

export default{
    install: (app)=>{
        class FileService{

            /**
             * @param attrs
             * @return {Promise<File>}
             */
            getOne(attrs){
                return app.capi.get({
                    ...attrs,
                    'service': 'file',
                    'space': 'file',
                    'path': 'getOne'
                })
            }

            /**
             * Get all transaction files
             * @param attrs
             * @return {Promise<array>}
             */
            getTransactionFiles(attrs = {}){
                return app.capi.get(
                    {
                        ...attrs,
                        service: 'file',
                        space: 'file',
                        path: 'getParentFiles',
                        parentTable: 'transaction'
                    }
                )
            }

            getTransactionIssuedInvoices(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'file',
                    space: 'file',
                    path: 'getTransactionIssuedInvoices',
                    id
                })
            }

            /**
             * Create new file
             * @param attrs
             * @param data
             * @return {Promise<File>}
             */
            createFile(attrs = null, data){
                return app.capi.create(
                    {
                        ...attrs,
                        service: 'file'
                    }, data
                )
            }

            approveFile(id, attrs = {}){
                return app.capi.update(
                    {
                        ...attrs,
                        service: 'file',
                        space: 'file',
                        path: 'approveFile',
                        id
                    }
                )
            }

            getAndWriteOcrDataFromFile(attrs, file){
                return app.capi.get({
                    ...attrs,
                    service: 'drive',
                    path: 'getAndUseOcrData',
                    id: file.id
                })
            }

            useQrCodeAndOcrDataFromFile(attrs, fileId, file){
                return app.service.file.getQrDataFromFile(attrs, file)
                    .catch(()=>{
                        return null // in case of failure continue with null data
                    })
                    .then((qrData )=>{
                        return app.capi.update({
                            ...attrs,
                            service: 'file',
                            path: 'useQrCOdeAndOcrData',
                            id: fileId
                        }, qrData)
                    })
            }

            useIsdocDataFromFile(attrs, fileId, isdocData){
                return app.capi.update({
                    ...attrs,
                    service: 'file',
                    path: 'useIsdocData',
                    id: fileId
                }, isdocData)
            }

            /**
             * Get Qr code data directly from file
             * @param attrs
             * @param file
             * @return {Promise<unknown>}
             */
            getQrDataFromFile(attrs, file){
                return new Promise((resolve, reject) => {
                    let fileReader = new FileReader();
                    fileReader.onload = function() {
                        // Read QR code data
                        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js';
                        let typedarray = new Uint8Array(this.result);
                        let loadingTask = pdfjsLib.getDocument(typedarray);
                        loadingTask.promise.then(function(pdf) {
                            // TODO read all pages
                            pdf.getPage(1)
                                .then(function(page) {
                                let scale = 1.5;
                                let viewport = page.getViewport({ scale: scale, });
                                let canvas = document.getElementById('canvas');
                                let context = canvas.getContext('2d');
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;

                                let renderContext = {
                                    canvasContext: context,
                                    viewport: viewport
                                };

                                let pdfDoc = page.render(renderContext);
                                pdfDoc.promise.then(function() {
                                    let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                                    let code = jsQR(imageData.data, imageData.width, imageData.height);
                                    let data = code?.data?code.data:null
                                    resolve(data);
                                });
                            });
                        });
                    };
                    fileReader.readAsArrayBuffer(file);
                })
            }

            getIsdocDataFromFile(attrs, file){
                let pack = app.utilities.createPackObject(attrs);

                let formData = new FormData()
                formData.append('file', file, file.name);
                formData.append('setting', JSON.stringify(pack.setting));

                return app.capi.uploadFormData(
                    {
                        ...attrs,
                        service: 'file',
                        path: 'getIsdocDataFromFile'
                    }, formData)
            }

            downloadAndEncodeFile(setting, url, sendData, callback) {
                return new Promise((resolve, reject)=>{
                    let pack = app.utilities.createPackObject(setting, sendData);
                    $.ajax({
                        url: url,
                        data: pack,
                        cache: false,
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        xhr: function () {
                            let xhr = new XMLHttpRequest();
                            xhr.onreadystatechange = function () {
                                if (xhr.readyState == 2) {
                                    if (xhr.status == 200) {
                                        xhr.responseType = "blob";
                                    } else {
                                        xhr.responseType = "text";
                                    }
                                }
                            };
                            return xhr;
                        },
                        success: function (data, textStatus, request) {
                            let filename = request.getResponseHeader('file-name');
                            let reader = new FileReader();
                            reader.onload = function () {
                                let encodedData = app.service.file.encodeFile(reader.result);
                                app.service.file.blobDownload(filename, [encodedData]);
                                resolve();
                            }
                            reader.readAsText(data);
                        },
                        error: function ({responseJSON: error}) {
                            reject(error);
                        }
                    });
                })

            }

            blobDownload(Filename, Bytes, Mimetype) {
                let filData = new Blob(Bytes, { type: Mimetype });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    window.navigator.msSaveOrOpenBlob(filData, Filename);
                } else { // for Non-IE (chrome, firefox etc.)
                    let link = URL.createObjectURL(filData);
                    let a = $("#file-download");
                    a.attr("download", Filename);
                    a.attr("href", link);
                    a[0].click();
                }
            };

            encodeFile(data) {
                return new TextEncoder('windows-1250', { NONSTANDARD_allowLegacyEncoding: true }).encode(data);
            }

            zip = class{

                static read(file){
                    return new Promise((resolve, reject) => {
                        let zip = new JSZip();
                        zip.loadAsync(file)
                            .then(resolve)
                    })
                }
            }

            utilities = class {

                static getIsdocParent(filesCollection, file){
                    return filesCollection.find((collection)=>{
                        return (app.utilities.getFileExtension(collection.file.name) === 'isdoc' && app.utilities.getFileName(collection.file.name) === app.utilities.getFileName(file.name));
                    });
                }
                static getIsdocChild(files, isdocFile){
                    return files.find((file)=>{
                        return (app.utilities.getFileExtension(file.name) !== 'isdoc' && app.utilities.getFileName(file.name) === app.utilities.getFileName(isdocFile.name));
                    });
                }

                static findIsdocFile(files){
                    return files.find((file)=>{
                        return (app.utilities.getFileExtension(file.name) === 'isdoc');
                    });
                }

                static findIsdocxFile(files){
                    return files.find((file)=>{
                        return (app.utilities.getFileExtension(file.name) === 'isdocx');
                    });
                }

                static sortFilesCollection(files){
                    return files.reduce((acc, element) => {
                        if (app.utilities.getFileExtension(element.name)==='isdoc') {
                            return [element, ...acc];
                        }
                        return [...acc, element];
                    }, []);
                }

                static removeIsdocParentFromCollection(filesCollection, file){
                    return filesCollection.filter(function( collection ) {
                        return !(app.utilities.getFileExtension(collection.file.name) === 'isdoc' && app.utilities.getFileName(collection.file.name) === app.utilities.getFileName(file.name));
                    });
                }

                static updateFileAndParent(attrs, data){
                    return new Promise((resolve, reject) => {
                        let responses = {}
                        // Put parent changes
                        app.capi.update(
                            {
                                ...attrs,
                                id: attrs['parentId'],
                                'automated': true,
                                //'doNotUpdateFile': false // do not update file as file has already been updated in previous task
                            },
                            data
                        )
                            .then(app.utilities.reloadConfigurationIfNeeded)
                            // Put file changes
                            .then((response)=>{
                                responses.parent = response
                                app.capi.update(
                                    {
                                        ...attrs,
                                        service: 'file',
                                        automated: true
                                    },
                                    data
                                )
                                    .then((response)=>{
                                        return {
                                            ...responses,
                                            file: response
                                        }
                                    })
                                    // Reload transaction in content screen
                                    .then(resolve)
                                    .catch(reject)
                            })
                            .catch(reject);
                    })
                }

                /**
                 * Read file and return objects with found QR data
                 * @param attrs
                 * @param file
                 */
                static getQrAndOcrData(attrs, file){

                }

                static checkGapiSetting(){
                    return new Promise((resolve, reject)=>{
                        if(!app.env.configuration.gapi) return reject('No gapi settings found. <br> Please go to Settings > Gapi and add your crew\'s gapi setting.')
                        if(!app.env.configuration.gapi['dumpFolder'] && !app.env.configuration.gapi['trashFolder']) return reject('Dump and Trash folders must be set.<br> Please go to Settings > Gapi and insert these folders\'s ids')
                        if(!app.env.configuration.gapi['dumpFolder']) return reject('Dump folder must be set.<br> Please go to Settings > Gapi and insert dump folder id.')
                        if(!app.env.configuration.gapi['trashFolder']) return reject('Trash folder must be set.<br> Please go to Settings > Gapi and insert dump folder id.')
                        resolve()
                    })
                }

            }

        }

        app.service.file = new FileService
        app.provide('FileService', new FileService)
    }
}

