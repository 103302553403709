import $ from 'jquery'

export default{
    install: (app)=>{

        /**
         * @class
         * @name SmartUtilities
         */
        class Utilities{

            space = class {

                /**
                 *
                 * @param attrs
                 * @return {SpaceSetting}
                 */
                static getSpaceSetting(attrs){

                    /** @type SpaceSetting */
                    let setting =  app.env.configuration['space_setting'].find(setting=>{
                        return setting.space === attrs.space
                    })

                    let set = JSON.parse(JSON.stringify(setting)) // must create new objects, using original objects slows down execution time

                    set.grid = {
                        main: setting.gridMain?setting.gridMain:'active',
                        tool: setting.gridTool?setting.gridTool:'active'
                    }

                    set.loader = {
                        type: setting.loaderType?setting.loaderType:'auto',
                        span:{
                            first: setting.loaderSpan?setting.loaderSpan:30,
                            further: setting.loaderSpanFurther?setting.loaderSpanFurther:setting.loaderSpan?setting.loaderSpan:30
                        }
                    }

                    set.grid = attrs.grid?attrs.grid:set.grid[attrs.spaceLocation?attrs.spaceLocation:'main']
                    set.type = attrs.spaceLocation?attrs.spaceLocation:null //For file manager purposes //todo needed?

                    return {...attrs, ...set}

                }

                static getSpaceName(attrs){
                    return app.env.configuration['space_setting'].find(setting=>{
                        return setting.space === attrs.space
                    })['space_setting_name']
                }

                static getSpaceRecordName(attrs){
                    return app.env.configuration['space_setting'].find(setting=>{
                        return setting.space === attrs.space
                    })['space_setting_record_name']
                }

                static getSpaceColumns(attrs){
                    return app.env.configuration['space_column'].filter(column => {
                        return column.space === attrs.space && column.visible && (attrs.spaceLocation!=='tool' || column['space_column_visible_in_tool'])
                    })
                }

            }

            data = class {

                /**
                 * Set searchData or addData
                 * @param data
                 * @param column
                 * @param value
                 */
                static set(data, column, value){
                    if(value!==''){
                        data[column.property] = app.utilities.parseValueByDataType(value, column, 'update')
                    }
                    else{
                        delete data[column.property]
                    }
                }

                static addItem(data, item){
                    //item.new = true
                    //data.shown.forEach(row=>row.new=false)
                    data.original.unshift(item)
                    if(!data.fetched.length) data.fetched.push(item)
                    data.shown.unshift(item)
                    return data.shown[0]
                }

                static removeItem(data, item){
                    data.original = data.original.filter(row=>row.id!==item.id)
                    data.fetched = data.fetched.filter(row=>row.id!==item.id)
                    data.shown = data.shown.filter(row=>row.id!==item.id)
                }

                static assignRowData(row, item){
                    return new Promise(resolve => {
                        // offer new value if element is focused and new value is received
                        if(row.focusedElement) {
                            if(row[row.focusedElement] !== item[row.focusedElement]){ // pozor, tohle porovnava puvodni hodnotu v db, ne tu vyplnovanou
                                let update = {}
                                update[row.focusedElement] = item[row.focusedElement]
                                if(!row.alerts) row.alerts = {}
                                row.alerts[row.focusedElement] = {
                                    type: 'new-value-while-editing',
                                    update
                                }
                            }
                            delete item[row.focusedElement]
                        }
                        Object.assign(row, item)
                        resolve(row)
                    })

                }

            }

            selectComponent(column, row){
                if(column['space_column_data_type_in_condition']){
                    switch(column['space_column_data_type_in_condition']){
                        case 'countItems':
                            if(row['items_count']>1) return 'button'
                            break;

                        case 'receiverTypeIsMember':
                            if(row['reccuring_receiver_type_id']==='member') return 'select'
                            break;

                        case 'hasAnyAction':
                            if(row['function_run']) return 'button'
                            break;
                    }
                }

                switch(column.dataType){
                    case 'text':
                    case 'date':
                    case 'number':
                    case 'currency':
                    case 'tracked-time':
                        return 'Input'

                    case 'timer':
                        return 'TimerButton'

                    case 'history':
                        return 'History'

                    default:
                        return column.dataType
                }
            }

            isColumnConditionallyDisabled(column, data){
                if(column['space_column_disabled_in_condition']){
                    switch(column['space_column_disabled_in_condition']){
                        case 'items_count':
                            return data['items_count'] > 1

                        case 'receiverIsNotCustom':
                            return data['reccuring_receiver_type_id'] !== 'custom' && data['reccuring_receiver_type_id'] !== 'member'
                    }
                }
                else{
                    return false
                }
            }

            resolvePositiveNegative(column, value, row = null){
                let resolver = column.resolvePositiveNegative==='byTransaction' && !row?'default':column.resolvePositiveNegative
                switch(resolver){
                    case 'default':
                    case '1':
                        return value>0?'positive':value<0?'negative':''

                    case 'reverse':
                        return value<0?'positive':value>0?'negative':''

                    case 'balance-type':
                        switch(value){
                            case 1: return 'positive'
                            case 2: return 'negative'
                            case '3': return ''
                        }
                        break;

                    case 'byTransaction':
                        if(!row) return ''
                        return app.utilities.resolvePositiveNegativeByTransactionType(column, row)
                }
            }
            resolvePositiveNegativeByTransactionType(column, row){
                let transactionPaymentTransactionTypeId = row['transaction_payment_transaction_type_id']
                let transactionDonorSubjectId = row['transaction_donor_subjects_id']
                let transactionAcceptorSubjectId = row['transaction_acceptor_subjects_id']
                let amount = row['transaction_amount_inc_vat']

                switch(column.property){
                    case 'transaction_donor_subjects_id':
                        if(transactionDonorSubjectId==='crew' && transactionPaymentTransactionTypeId==='purchase' && transactionAcceptorSubjectId==='external') return 'negative'
                        if(transactionDonorSubjectId==='crew' && transactionPaymentTransactionTypeId==='payment') return 'negative'
                        if(transactionDonorSubjectId==='crew' && transactionPaymentTransactionTypeId==='refund') return 'negative'
                        if(transactionDonorSubjectId==='crew' && transactionPaymentTransactionTypeId==='sale') return 'positive'
                        if(transactionDonorSubjectId!=='crew' && transactionDonorSubjectId!=='external' && transactionPaymentTransactionTypeId==='deposit') return 'positive'
                        if(transactionDonorSubjectId!=='crew' && transactionDonorSubjectId!=='external' && transactionPaymentTransactionTypeId==='refund') return 'positive'
                        if(transactionDonorSubjectId!=='external' && transactionDonorSubjectId!=='crew' && (transactionPaymentTransactionTypeId==='deposit' || transactionPaymentTransactionTypeId==='payment' || transactionPaymentTransactionTypeId==='refund' || transactionPaymentTransactionTypeId==='purchase' ) && transactionAcceptorSubjectId==='crew') return 'positive'
                        if(transactionDonorSubjectId!=='external' && transactionDonorSubjectId!=='crew' && (transactionPaymentTransactionTypeId==='withdrawal' ) && transactionAcceptorSubjectId==='crew') return 'negative'
                        break;

                    case 'transaction_acceptor_subjects_id':
                        if(transactionPaymentTransactionTypeId==='purchase' && transactionAcceptorSubjectId!=='external') return 'negative'
                        if(transactionDonorSubjectId==='external' && (transactionPaymentTransactionTypeId==='deposit' || transactionPaymentTransactionTypeId==='payment' || transactionPaymentTransactionTypeId==='refund' ) && transactionAcceptorSubjectId==='crew') return 'positive'
                        if(transactionPaymentTransactionTypeId==='payment' && transactionAcceptorSubjectId!=='external' && transactionAcceptorSubjectId!=='crew') return 'negative'
                        if(transactionPaymentTransactionTypeId==='refund' && transactionAcceptorSubjectId!=='external' && transactionAcceptorSubjectId!=='crew') return 'negative'
                        break;

                    case 'transaction_amount_inc_vat':
                        if(!amount) return ''
                        if(transactionPaymentTransactionTypeId==='purchase' || transactionPaymentTransactionTypeId==='withdrawal') return 'negative'
                        if(transactionPaymentTransactionTypeId==='sale') return 'positive'
                        if(transactionDonorSubjectId!=='crew' && transactionPaymentTransactionTypeId==='refund') return 'positive'
                        if(transactionDonorSubjectId==='crew' && (transactionPaymentTransactionTypeId==='payment' || transactionPaymentTransactionTypeId==='refund')) return 'negative'
                        if(transactionDonorSubjectId!=='crew' && (transactionPaymentTransactionTypeId==='payment' || transactionPaymentTransactionTypeId==='deposit' || transactionPaymentTransactionTypeId==='refund')) return 'positive'
                        break;

                    // todo add all cases

                }
            }

            createPackObject(attrs = {}, data = null){
                if(!attrs.crew_id) attrs.crew_id = app.env.crew_id
                attrs.environment = process.env.VUE_APP_ENVIRONMENT

                return {
                    setting: attrs,
                    //payload: data,
                    data: data // stop using data objects in the future, as data will be nested in payload objects
                };
            }

            injectGapiSetting(attrs){
                attrs.gapi = app.env.configuration['gapi']
            }

            resetEnvTempVariable(){
                this.clearTimerIntervals()
                app.env.trackers = []
                app.env.obligations = []
                app.env.company = null
            }

            clearTimerIntervals(){
                if(app.env.trackers.length){
                    for (let tracker of app.env.trackers){
                        if(tracker.updater){
                            clearInterval(tracker.updater)
                            tracker.updater = null
                        }
                        if(tracker.counter){
                            clearInterval(tracker.counter)
                            tracker.counter = null
                        }
                    }
                }
            }

            formatDate(date, format = 'eu'){


                date = this.clearWhiteSpacesAfterDots(date);

                let time = "";
                if(date) {
                    switch (format) {
                        case "eu":
                            if(date.includes(" ")){
                                let datetime = date.split(" ");
                                date = datetime[0];
                                time = " "+datetime[1];

                            }
                            date = date.replaceAll("/", ".");
                            if (date.includes("-")) {
                                date = date.split("-");
                                date = date[2] + "." + date[1] + "." + date[0]+time;
                            }

                            break;


                        case "us":
                            date = date.replaceAll("/", ".");
                            if (date.includes(".")) {
                                date = date.split(".");
                                /*console.log(date[1])
                                console.log(date[1]+' d1l', date[1].length)*/
                                date = date[2] + "-" + date[1] + "-" + date[0];

                            }

                            break;
                    }
                }


                return date;
            }

            formatTime(seconds){
                seconds=seconds?seconds:0
                let d = Math.floor(seconds / (3600*24));
                let h = Math.floor(seconds % (3600*24) / 3600);
                let m = Math.floor(seconds % 3600 / 60).toString();
                let s = Math.floor(seconds % 60).toString();
                h = (h + d*24).toString();
                return h.padStart(2,'0')+'h:'+m.padStart(2,'0')+'m:'+s.padStart(2,'0')+'s';
            }

            cleanHtmlChars(text){
                let r = text;

                r = r.replace(new RegExp(/[&]/g),"and");
                r = r.replace(new RegExp(/[/"]/g),"");

                return r;
            };

            setCookie(cName, cValue, expDays = 7) {
                let date = new Date();
                date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
                const expires = "expires=" + date.toUTCString();
                document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            }

            centerPage(){
                let mainTableWidth = $('#app').width();
                let screenWidth = screen.width;
                let scrollOffset = mainTableWidth-screenWidth;
                if(scrollOffset>0){
                    window.scrollTo( scrollOffset/2, 0 );
                }

            }

            clearWhiteSpacesAfterDots(str){
                if(str){
                    str = str.replace(/\.\s+/g, '.');
                }
                return str;
            }

            getValue(column, row){
                return row[column.property]
            }

            getDisplayValue(column, row){
                if(column.dataType==='datalist' && this.getValue(column, row)===null && row[column.columnDatalist]) return row[column.columnDatalist]
                return this.parseValueByDataType(this.getValue(column, row), column)
            }

            /**
             *
             * @param value
             * @param column {SmartColumn}
             * @param method {string}
             * @returns {undefined}
             */
            parseValueByDataType(value, column, method = 'get'){
                //console.log('parsing column property', column.property)
                //console.log('value: '+value)
                if(value==='') return value;
                let dateFormat;
                let currencyFormat;
                let direction;
                switch(method){
                    case "put":
                    case "post":
                    case "update":
                    case "create":
                    case 'send':
                        dateFormat = "us";
                        currencyFormat = null;
                        direction = 'send';
                        break;

                    case "get":
                        dateFormat = "eu";
                        currencyFormat = "de";
                        direction = 'get';
                }

                switch(column.dataType){
                    case 'date':
                        value = this.formatDate(value, dateFormat);
                        break;

                    case 'time':
                    case 'tracked-time':
                        value = this.formatTime(value);
                        break;

                    case 'currency':
                        if(currencyFormat){
                            value = this.formatCurrency(value, currencyFormat)
                        }
                        else{
                            value = this.saveNumber(value, true)
                        }
                        break;

                    case 'datalist':
                        if(direction==='get')value = this.getSourceTableValue(value, column)
                        if(direction==='send')value = this.getSourceTableId(value, column)
                        break;

                    case "select":
                        if(direction==='get')value = this.getSourceTableValue(value, column)
                        // send value for select is original value itself
                        break;
                }
                return value;
            }

            /**
             * Retrieve value from source table by provided id
             * @param id
             * @param column
             * @returns {*|string}
             */
            getSourceTableValue(id, column){
                if(id==='' || id===null) return id
                let sourceTable = app.env.configuration[column.sourceTable]
                if(typeof sourceTable === 'undefined') throw new Error(`Table ${column.sourceTable} does not exist in configuration`)
                if(!sourceTable) return null
                let source = app.env.configuration[column.sourceTable].find(item=>{
                    return item[column.sourceTable+'_id'] == id
                })

                return source?source[column.sourceTable+'_name']:'not found'
            }

            /**
             * Retrieve ud from source table by provided value
             * @param value
             * @param column
             * @returns {*|string}
             */
            getSourceTableId(value, column){
                if(value==='' || value===null) return value
                let sourceTable = app.env.configuration[column.sourceTable]
                if(typeof sourceTable === 'undefined') throw new Error(`Table ${column.sourceTable} does not exist in configuration`)
                if(!sourceTable) return null
                let source = app.env.configuration[column.sourceTable].find(item=>{
                    return item[column.sourceTable+'_name']&&item[column.sourceTable+'_name'].toLowerCase() === value.toLowerCase()
                })

                return source?source[column.sourceTable+'_id']:null
            }

            saveNumber(data, returnAsNum = false){
                if(!data) return '';
                if(typeof data === 'number') data = data.toFixed(2)
                data = data.toString();
                if(data){
                    data = data
                        .replaceAll(",",".")
                        .replaceAll(" ", "");
                    data = data.replace(/[^\d.-]/g,'');
                    let parts = data.split(".");
                    let num = null;
                    for(let part of parts){
                        if(!num){
                            num = part;
                        }
                        else{
                            if(part.length>2){
                                num += part;
                            }else{
                                num = num+"."+part;
                            }
                        }
                    }
                    data = num;

                }
                if(returnAsNum){
                    data = Number(data);
                }

                return data;
            }

            formatCurrency(value, format = 'de'){
                value = this.saveNumber(value, true);
                const formatter = new Intl.NumberFormat(format+'-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                    // These options are needed to round to whole numbers if that's what you want.
                    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                });

                value = formatter.format(value)
                return value
            }

            toggleRow(row){
                row.selected = !row.selected // select/deselect current row
            }

            deselectAllRows(data, row = null){
                if(row){
                    data.shown
                        .filter(item => item.id!==row.id) // filter all rows except current row
                        .forEach(item => item.selected = false) // deselect other rows
                }
                else{
                    data.shown
                        .forEach(item => item.selected = false)
                }

            }

            isAnyRowSelected(data){
                return data.shown.find(row=>row.selected)
            }

            getSelectedRow(data){
                return data.shown.find(row=>row.selected)
            }

            getSelectedRows(data){
                return data.shown.filter(row=>row.selected)
            }

            defocusAllRows(data){
                data.shown
                    .forEach(item => item.focused = false)
            }

            getColumnName(attrs, property){
                let column = app.env.configuration['space_column'].find(column => {
                    return column.space === attrs.space && column['space_column_id'] === property
                })
                return column?column['space_column_name']:'Unknown column'
            }

            getFileExtension(fileName){
                return fileName.split('.').pop();
            }

            getFileName(fileName){
                return fileName.replace(/\.[^/.]+$/, "");
            }

            copyObject(object){
                return object?JSON.parse(JSON.stringify(object)):{}
            }

            addPositiveNegativeClass(value){
                if(Number(value)<0) return 'negative'
                else if(Number(value)>0) return 'positive'
                else return 'neutral'
            }

            sanitizeAccents(text){
                if(!text) return text
                let r=text;
                r = r.replace(new RegExp(/[àáâãäå]/g),"a");
                r = r.replace(new RegExp(/æ/g),"ae");
                r = r.replace(new RegExp(/[çč]/g),"c");
                r = r.replace(new RegExp(/[ř]/g),"r");
                r = r.replace(new RegExp(/[èéêëě]/g),"e");
                r = r.replace(new RegExp(/[ìíîï]/g),"i");
                r = r.replace(new RegExp(/ñ/g),"n");
                r = r.replace(new RegExp(/[òóôõö]/g),"o");
                r = r.replace(new RegExp(/ň/g),"n");
                r = r.replace(new RegExp(/[ùúûüů]/g),"u");
                r = r.replace(new RegExp(/[ýÿ]/g),"y");
                r = r.replace(new RegExp(/[ž]/g),"z");
                r = r.replace(new RegExp(/[ř]/g),"r");
                r = r.replace(new RegExp(/[š]/g),"s");
                r = r.replace(new RegExp(/[ť]/g),"t");
                r = r.replace(new RegExp(/[ď]/g),"d");
                r = r.replace(new RegExp(/[ÁÄ]/g),"A");
                r = r.replace(new RegExp(/[Č]/g),"C");
                r = r.replace(new RegExp(/[Ř]/g),"R");
                r = r.replace(new RegExp(/[ÉĚ]/g),"E");
                r = r.replace(new RegExp(/[Í]/g),"I");
                r = r.replace(new RegExp(/Ď/g),"D");
                r = r.replace(new RegExp(/Ň/g),"N");
                r = r.replace(new RegExp(/[Ó]/g),"O");
                r = r.replace(new RegExp(/[ÚŮ]/g),"U");
                r = r.replace(new RegExp(/[Ý]/g),"Y");
                r = r.replace(new RegExp(/[Ž]/g),"Z");
                r = r.replace(new RegExp(/[Š]/g),"S");
                r = r.replace(new RegExp(/[Ť]/g),"T");
                r = r.replace(new RegExp(/[Ď]/g),"D");
                return r;
            }

            getDateDistance(date) {
                if(!date) return ''
                let valueRange;
                let today = new Date();
                let valueDate = new Date(this.formatDate(date, "us"));
                switch (true) {
                    case(valueDate > today):
                        if (((valueDate.getTime() - today.getTime()) / (1000 * 3600 * 24)) < 8) {
                            valueRange = 'close-future';
                        } else {
                            valueRange = 'future';
                        }
                        break;

                    case(valueDate < today):
                        valueRange = 'past';
                        break;

                    case(valueDate == today):
                        valueRange = 'today';
                        break;
                }

                return valueRange
            }

            preserveElementWidth(element){
                element.style.width = element.getBoundingClientRect().width+'px'
            }

            /**
             * Check if some of configuration tables must be reloaded
             * @param response
             * @return {Promise<unknown>}
             */
            reloadConfigurationIfNeeded(response){
                return new Promise(resolve=>{
                    let chain = Promise.resolve()
                    for(let affected of Object.keys(response.affected)){
                        let reloads = app.env.configuration['table'].find(table=> table['table_id'] === affected)['table_reload_configuration_table']
                        if(reloads){
                            for(let reload of reloads.split(',')){
                                chain = chain
                                    .then(()=>{
                                        return app.service.configuration.reloadTable(reload)
                                    })
                            }
                        }
                        else{
                            resolve(response)
                        }
                    }

                    chain
                        .then(()=>{
                            resolve(response)
                        })
                })
            }

            getNumberOfOpenTools(){
                return $('#app').find('div[data-type="tool"], div[data-type="dialog"]').length
            }

            getNumberOfOpenDialogs(){
                return $('#app').find('div[data-type="dialog"]').length
            }

            getZindex(){
                return app.utilities.getNumberOfOpenTools()+10
            }

            createRowAlerts(alerts, row){
                if(alerts){
                    if(!row.alerts) row.alerts = {}
                    Object.assign(row.alerts, alerts)
                }
            }

            isThereAnyOpenTracker(){
                return app.env.trackers.length && app.env.trackers.find(tracker => tracker.open)
            }

        }

        app.utilities = new Utilities
        app.provide('Utilities', new Utilities)
    }
}


