
export default{

    install:(app)=>{

        /**
         * @name Waiter
         */
        class Waiter{

            show(message, setting = {}){
                app.env.waiter = {
                    message,
                    setting
                };
            }

            hide(){
                app.env.waiter = false
            }

        }

        app.provide('Waiter', new Waiter)

    }
}
