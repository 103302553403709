
export default class DueRoute{

    constructor(attrs) {

        this.get = "due/transactions/"+attrs.member;
        this.getOne = "due/transactions/"+attrs.member+'/'+attrs['id']; // TODO add to backend
        this.update = "transaction/"+attrs['id'];
        this.balance = "due/balance/"+attrs.member;
        this.checkout = "due/checkout/"+attrs.member;
        this.filter = "due/transactions/filter/"+attrs.member;

    }

}
