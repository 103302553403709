
export default class ContactRoute{

    constructor(attrs) {

        switch(attrs.space){
            case 'contact-account':
                this.get = 'contact/account/'+attrs['id'];
                this.create = 'contact/account/'+attrs['id'];
                this.update = 'contact/account/'+attrs['id'];
                this.delete = 'contact/account/'+attrs['id'];
                break;

            default:
                this.get = attrs.space;
                this.getOne = attrs.space+'/'+attrs['id'];
                this.loadFromAres = attrs.space+'/ares/'+attrs['ico'];
                this.create = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.update = attrs.space+"/"+attrs["id"];
                this.delete = attrs.space+"/"+attrs["id"];
        }


    }

}
