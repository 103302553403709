<template>
     <div class="features">
         <div class="block" v-for="block in featureBlocks">
             <div class="feature" :class="[visibility[space]?'':'hidden', space]"  v-for="(space, index) in block">
             <span class="label" :class="space">

                 {{utilities.space.getSpaceName({space})}}

                 <span class="sum" v-if="sums[space]">
                     {{utilities.formatCurrency(sums[space])}}
                 </span>

             </span>
                 <component
                     :is="space"
                     :attrs="
                 {
                    ...attrs,
                    space
                 }
                "
                     @dataFetched="setToolVisibility({...$event, space})"
                 />
             </div>
         </div>
     </div>
</template>

<script>


import {inject, reactive} from "vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "HomeView.vue",
    components: {SpaceBuilder},
    props: ['attrs'],
    setup(props){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type Capi */
        const capi = inject('Capi')

        let featureBlocks = [
            [
                'top-purchaser',
                'top-supplier',
                'crew-stat',
                'unresolved-thread',
                'bank-balance',
            ],
            [
                'future-obligation',
                'home-obligation',
                'awaiting-receivable',
                'future-receivable',
            ],
            [
                'bank-unpair'
            ]
        ]

        function hasSumFeature(attrs){
            return utilities.space.getSpaceSetting(attrs).sumFeature
        }

        let sums = reactive({});
        let visibility = reactive({});

        (function getSums(){
            for(let features of featureBlocks){
                for(let space of features){
                    if(!hasSumFeature({space})) continue
                    capi.get({
                        ...props.attrs,
                        space,
                        path: 'getSum'
                    })
                        .then((sum)=>{
                            sums[space] = sum['total']
                        })
                        .catch((e)=>console.log(e))
                }
            }

        })();

        function setToolVisibility({data, space}){
            data.fetched.length?visibility[space]=true:visibility[space]=false
        }

        return {utilities, sums, setToolVisibility, visibility, featureBlocks}
    }
}
</script>

<style lang="scss">

.features{

    width: 1600px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 20px;

    .block:not(:first-child) {
        margin-top: 20px;
    }

    .block{
        display: flex;
        flex-flow: row;
        justify-content: center;
        margin-top: 10px;

        .feature{
            margin-top: 5px;
            margin-left: 5px;
            margin-right: 5px;

            .data{
                margin-top: 15px;
                .space{
                    margin: 0;
                }
            }

            &.future-obligation,
            &.home-obligation,
            &.future-receivable,
            &.awaiting-receivable{
                .data{
                    display: flex;
                    height: fit-content;
                    max-height: 70vh;
                    overflow-x: hidden;
                    overflow-y: auto;
                    position: relative;
                    padding-bottom: 5px;
                    box-sizing: content-box;

                    .space{
                        thead{
                            z-index: 3;
                            top: 0;
                            position: sticky;
                        }

                    }
                }
            }

            &.hidden{
                display: none;
            }

            .label{
                background-color: $lighterGray;
                padding: 5px;
                border-radius: 3px;
                font-weight: bold;


                .sum{
                    margin-left: 5px;
                }

                &.home-obligation,
                &.future-obligation{
                    background-color: $red;
                }

                &.awaiting-receivable{
                    background-color: $green;
                }

                &.future-receivable{
                    background-color: $lightGreen;
                }
            }

        }
    }



}

</style>
