<template>

    <NextRecordNumber
        type="proforma"
        :nextRecordNumber="nextRecordNumber"
    />

    <SpaceBuilder
        :attrs="attrs"
        @catchRowClicked="{}"
    />

</template>

<script>

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject, ref, watchEffect} from "vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
import NextRecordNumber from "@/components/view/elements/NextRecordNumber.vue";
export default {
    name: "ProformasSeriesView.vue",
    components: {NextRecordNumber, LoadingDots, SpaceBuilder,},
    props: [
        'attrs'
    ],

    setup(props){

        /** @type SeriesService */
        const seriesService = inject('SeriesService')

        let nextRecordNumber = ref()

        watchEffect(()=>{

            nextRecordNumber.value = null

            seriesService.getUpcomingVs(props.attrs)
                .then((data)=>{
                    nextRecordNumber.value = data
                })
                .catch((e)=> {
                    nextRecordNumber.value = null
                })

        })

        return {nextRecordNumber}

    }
}
</script>

<style lang="scss">


</style>
