
export default class TaskRoute{

    constructor(attrs) {
        this.get = attrs.space;
        this.getOne = attrs.space+'/'+attrs['id'];
        this.getParentTasks =  'task/parent/'+attrs['parentTable']+'/'+attrs['parentId'];
        this.getParentTasksSums =  'task/parent/sum/'+attrs['parentTable']+'/'+attrs['parentId'];
        this.create = attrs.space+'/'+attrs['parentTable']+'/'+attrs['parentId'];
        this.update = attrs.space+'/'+attrs['id'];
        this.delete = attrs.space+'/'+attrs['id'];
    }

}
