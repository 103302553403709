<template>
    <button
        v-if="(column['space_column_no_content_if_null']&&value)||!column['space_column_no_content_if_null']"
        :disabled="((value===null || value==='') && column.disabledIfNull) || wait || isButtonDisabledByCondition()"
        @click="catchClick"
        >
        <template v-if="!wait">
            <template v-if="column.buttonLabelFromValue">
                <template v-if="(value===null || value==='') && column.nullContent">
                    {{column.nullContent}}
                </template>
                <template v-else>
                    {{value}}
                </template>
            </template>
            <template v-else-if="column.buttonLabelFromFunction">
                {{ getButtonLabelFromFunction() }}
            </template>
            <template v-else>
                {{ column.name }}
            </template>
        </template>
        <LoadingDots v-else/>
    </button>
</template>

<script>
import {inject, reactive, ref, watchEffect} from "vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";

export default {
    name: "Button",
    components: {LoadingDots},
    emits: [
        'run'
    ],
    props:[
        'attrs',
        'setting',
        'column',
        'value',
        'row'
    ],
    setup(props, {emit}){
        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type {SmartColumn} */
        let column = props.column
        let options = env.configuration[column.sourceTable]

        let position = ref(10)
        let wait = ref()

        function getButtonLabelFromFunction(){
            let functionName = getButtonFunctionToRun();

            switch(functionName){
                case 'sendForApproval':
                    return 'Send for approval'

                case 'approveFile':
                    return 'Approve file'

                default:
                    return ''
            }

        }

        function getButtonFunctionToRun(){
            // hodnota ve value, napr. ve sloupci function_run
            if(column['space_column_function_from_db_value']){
                return props.row[column['space_column_id']]
            }
            // fce primo definovana v space_column_function
            else if(column['space_column_function']){
                return column['space_column_function']

            }else{
                return null
            }
        }

        // not using, just ready for use
        function isButtonDisabledByCondition(){
            let condition = column['space_column_disabled_in_condition']

            switch(condition){

                case 'hasNoDriveFile':
                    return !props.row['file_drive_id']

                default:
                    return false

            }
        }

        const catchClick = (e, property) => {
            let functionToRun = getButtonFunctionToRun()

            // check if method exists, if not emit method name
            if(methods[functionToRun]){
                methods[functionToRun](e)
            }
            else{
                emit('run', {
                    wait,
                    method: functionToRun,
                    row: props.row,
                    e,
                    setting: props.setting,
                    attrs: props.attrs,
                    callback: ()=>{
                    }
                })
            }
        }

        const methods = {

        }

        return {
            env,
            options,
            catchClick,
            utilities,
            position,
            wait,
            getButtonLabelFromFunction,
            isButtonDisabledByCondition
        }
    }
}
</script>

<style scoped>

</style>
