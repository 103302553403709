
export default class NotificationRoute{

    constructor(attrs) {
        this.get = attrs.space;
        this.getOne = attrs.space+"/"+attrs["id"];
        this.update = attrs.space+"/"+attrs["id"];
        this.getPendingNotifications = 'notification/pending';

    }

}
