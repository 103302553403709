<template>
    <LoadingDots/>
</template>

<script>
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";

export default {
    name: "Loading",
    components: {LoadingDots}
}
</script>

<style scoped>

</style>
