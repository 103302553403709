<script setup>
import Tracker from "@/components/tools/components/Tracker.vue";
import {inject, watchEffect} from "vue";
const env = inject('env')

</script>

<template>
<div
    class="trackers"
>
    <template v-for="tracker in env.trackers">
        <Tracker
            v-if="tracker.open"
            :tracker="tracker"
        />
    </template>

</div>
</template>

<style scoped lang="scss">
.trackers{
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-flow: column-reverse;
    gap: 5px;
    align-items: flex-end;
}
</style>
