import Capi from "@/plugins/Capi";
import Google from "@/plugins/Google";
import Utilities from "@/plugins/Utilities";
import Dialog from "@/plugins/Dialog";
import Loader from "@/plugins/Loader";
import Context from "@/plugins/Context";
import Waiter from "@/plugins/Waiter";
import Isdoc from "@/plugins/Isdoc";
import Napi from "@/plugins/Napi";
import Ocr from "@/plugins/Ocr";

export default{

    install:(app)=>{
        app.use(Capi)
        app.use(Google)
        app.use(Utilities)
        app.use(Dialog)
        app.use(Loader)
        app.use(Context)
        app.use(Waiter)
        app.use(Isdoc)
        app.use(Napi)
        app.use(Ocr)
    }

}
