<template>
    <div class="create-contact-guide-drop" @click.self="$emit('close')"></div>

    <div class="create-contact-guide">
            <button class="btn__close tool" @click="$emit('close')">X</button>
            <h2 class="headline"><span>Create contact guide</span></h2>

            <div class="ico-loader">

                    <span class="desc">IČO: </span><input class="ico" type="text" v-model="ico" @keydown.enter="loadFromAres">

                    <button
                        id="ares-button"
                        :disabled="!ico || aresLoading"
                        class="btn__dark ares"
                        @click="loadFromAres"
                    >
                        <template v-if="!aresLoading">Load from ARES</template>
                        <LoadingDots v-else/>
                    </button>

            </div>

            <div v-if="showForm" class="form">
                <hr>
                <div class="block"><span class="desc">Name: </span><input type="text" v-model="data['contact_name']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">Street name: </span><input type="text" v-model="data['contact_street_name']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">Building number: </span><input type="text" v-model="data['contact_building_number']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">City name: </span><input type="text" v-model="data['contact_city_name']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">Postal zone: </span><input type="text" v-model="data['contact_postal_zone']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">Country: </span><input type="text" v-model="data['contact_country_id']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">IČO: </span><input type="text" v-model="data['contact_ico']" @keydown.enter="createContact"></div>
                <div class="block"><span class="desc">DIČ: </span><input type="text" v-model="data['contact_dic']" @keydown.enter="createContact"></div>

                <hr>

                <h3 class=""><span>Bank accounts</span><button class="btn__dark add-account-button" @click="addAccount">+</button></h3>
                <div v-for="(account, index) in data.accounts" class="account">
                    <input type="text" class="account_element prefix" v-model="data.accounts[index]['contact_account_prefix']" @keydown.enter="addAccount">
                    -
                    <input type="text" class="account_element account_number" v-model="data.accounts[index]['contact_account_number']" @keydown.enter="addAccount">
                    /
                    <input type="text" class="account_element bank_code"  v-model="data.accounts[index]['contact_account_bank_code']" @keydown.enter="addAccount">
                </div>

                <hr>

                <button
                    :disabled="adding"
                    class="btn__dark btn__add"
                    @click="createContact($event)"
                >
                    <template v-if="!adding">Create contact</template>
                    <LoadingDots v-else/>
                </button>
            </div>

            <div class="loading-message" v-if="loadingMessage">
                <span class="message">{{ loadingMessage }}</span>
                <LoadingDots/>
            </div>

            <div class="error-label" v-if="errorMessage">
                <span v-html="errorMessage"></span>
            </div>

        </div>

</template>

<script>
import {inject, onMounted, onUnmounted, reactive, ref, watch, defineExpose} from "vue";
import DataFooterWaiter from "@/components/elements/waiter/DataFooterWaiter.vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";

export default {
    name: "CreateContactGuide",
    components: {LoadingDots, DataFooterWaiter},
    props: [
        'attrs'
    ],
    emits: [
        'update',
        'close',
        'dataTouched',
        'add'
    ],
    setup(props, {emit}) {

        /** @type {SmartEnv}*/
        const env = inject('env')

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type Google */
        const google = inject('Google')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type Capi */
        const capi = inject('Capi')
        let ico = ref()
        let data = ref({
            accounts: [{}]
        })

        let adding = ref(false)
        let errorMessage = ref()
        let loadingMessage = ref()
        let aresLoading = ref(false)
        let showForm = ref(false)

        let position = window.scrollY
        function loadFromAres(){
            errorMessage.value = null
            if(!ico.value) dialog.error('Please insert IČO first')
            utilities.preserveElementWidth(document.getElementById('ares-button'))
            aresLoading.value = true
            capi.get({
                ...props.attrs,
                path: 'loadFromAres',
                ico: ico.value
            })
                .then((ares)=>{
                    Object.assign(data.value, ares)
                    //addAccount()
                })
                .catch(e => errorMessage.value = e.message)
                .finally(()=>{
                    aresLoading.value = false
                    showForm.value = true
                })
        }

        function createContact(e){
            utilities.preserveElementWidth(e.target)
            checkCreateData()
                .then(()=>{
                    adding.value = true
                    errorMessage.value = null
                    emit('add', {
                        addData: data,
                        success: (row)=>{
                            adding.value = false
                            emit('close')
                        },
                        error: (e)=>{
                            adding.value = false
                            errorMessage.value = e.message
                        }
                    })
                })
                .catch((e)=>{
                    errorMessage.value = e.message
                })


        }

        function checkCreateData(){
            return new Promise(resolve=>{
                if(!data.value['contact_name']) throw new Error('Name cannot be empty')
                resolve()
            })
        }

        function addAccount(){
            let i = data.value.accounts.length
            data.value.accounts[i] = {}
        }

        onMounted(()=>{
            // To get the scroll position of current webpage
            let TopScroll = window.pageYOffset || document.documentElement.scrollTop;

            // if scroll happens, set it to the previous value
            window.onscroll = function() {
                window.scrollTo(window.scrollX, TopScroll);
            };
        })

        onUnmounted(()=>{
            // remove disabled scrolling
            window.onscroll = function() {};
        })

        let zindex = utilities.getZindex()+1

        return {
            createContact,
            loadFromAres,
            addAccount,
            data,
            ico,
            aresLoading,
            showForm,
            errorMessage,
            position,
            loadingMessage,
            adding,
            utilities,
            zindex
        }
    }
}
</script>

<style lang="scss" scoped>

.create-contact-guide-drop {
    position: fixed;
    top: 0;
    left: 0;
    width: 2650px;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: v-bind(zindex);
}

.create-contact-guide {
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: cadetblue;
        border-radius: 3px;
        padding: 10px 10px 20px;
        width: fit-content;
        min-width: 350px;
        z-index: v-bind(zindex);

        input,
        textarea{
            margin-right: 5px;
            border-radius: 3px;
            background-color: #f0f0f0;
            color: $lightenGray;
            border-color: $lighterGray;
            text-align: left;
            flex-basis: 20rem;

            &:focus,
            &:focus-visible,
            &:-webkit-autofill,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:focus-visible{
                background-color: #f0f0f0!important;
                border-color: darkorange;
                outline-style: none;
                -webkit-text-fill-color: $lightenGray;
            }

            &.ico{
                width: 65px;
            }
        }

        .ares{
            height: 23px;
        }

        .btn__add{
            width: fit-content;
            height: fit-content;
            padding: 3px 6px;
            margin-top: 7px;
            margin-left: 0;
            margin-right: 0;
        }

        .form{
            margin-top: 10px;

            .block{
                display: flex;
                flex-flow: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 2px;

                .desc{
                    flex-basis: 9rem;
                    text-align: right;
                    margin-right: 5px;
                }
            }

            .add-account-button{
                margin-left: 5px;
            }

            .account{
                margin-bottom: 3px;

                &:last-of-type{
                    margin-bottom: 10px;
                }
            }

            .account_element{
                margin: 0;
            }

            .prefix,
            .bank_code{
                width: 35px;
            }

            .prefix,
            .account_number{
                text-align: right;
            }

            .account_number{
                width: 80px;
            }
        }

        .loading-message{
            margin-top: 10px;

            .message{
                display: block;
                font-weight: bold;
                color: #f0f0f0;
            }

        }

        .error-label{
            margin-top: 20px;
            margin-bottom: 0;
        }

    }


</style>
