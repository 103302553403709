

export default{
    install: (app)=>{

        /** @name CommentService */
        class CommentService {

            /**
             * @param attrs
             * @returns {Promise<Comment>}}
             */
            getOne(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'comment',
                    path: 'getOne'
                })
            }

            /**
             * @param attrs
             * @return {Promise<Comment>}
             */
            getLast(attrs){
                return app.capi.get({
                    ...attrs,
                    'service': 'comment',
                    'path': 'getLast'
                })
            }

            openCommentManagerFromThread(thread){
                app.env.commentManager = {
                    attrs: {
                        service: thread.value.service,
                        space: thread.value.space,
                        id: thread.value.record
                    },
                    thread
                }
            }


            static utilities = class {

            }

        }

        app.service.comment = new CommentService
        app.provide('CommentService', new CommentService)

    }
}

