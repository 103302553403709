<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "ContactAccount.vue",
    components: {SpaceBuilder, }

}
</script>

<style lang="scss">

.contact-account{

    .contact_account_name{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_account_prefix{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_account_number{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_account_bank_code{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
