<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "CrewStat.vue",
    components: {SpaceBuilder}

}
</script>

<style lang="scss">

.crew-stat{

    thead{
        position: unset;
    }

    .name{
        text-align: right;
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .incomes_exc_vat,
    .outcomes_exc_vat,
    .balance_exc_vat{
        text-align: right;
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    tbody tr:last-child td,
    tbody tr:nth-child(3) td{
        border-top-style: solid;
        font-weight: bold;
    }

    tbody tr:nth-child(4) td{
        border-top-style: solid;
        border-top-width: 2px;
    }

    tbody tr:last-child td{
        border-top-width: 2px;
    }

}

</style>
