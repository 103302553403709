
export default class SettingRoute{

    constructor(attrs) {

        switch(attrs.space){
            case "crew-setting":
                this.get = 'setting/crew';
                this.getOne = 'setting/crew';
                this.update = 'setting/crew';
                break;

            case "company-setting":
                this.get = 'setting/company';
                this.getOne = 'setting/company/'+attrs['id'];
                this.create = 'setting/company';
                this.update = 'setting/company/'+attrs['id'];
                break;

            case "crew-member-setting":
                this.get = 'setting/member';
                this.getOne = 'setting/member/'+attrs['id'];
                this.create = 'setting/member';
                this.update = 'setting/member/'+attrs['id'];
                this.delete = 'setting/member/'+attrs['id'];
                break;

            case "bank-account-setting":
                this.get = 'setting/bank';
                this.getOne = 'setting/bank/'+attrs['id'];
                this.create = 'setting/bank';
                this.update = 'setting/bank/'+attrs['id'];
                break;

            case "gapi-setting":
                this.get = 'setting/gapi';
                this.getOne = 'setting/gapi/'+attrs['id'];
                this.create = 'setting/gapi'
                this.update = 'setting/gapi/'+attrs['id'];
                break;

            case "category-setting":
                this.get = 'setting/category';
                this.getOne = 'setting/category/'+attrs['id'];
                this.create = 'setting/category';
                this.update = 'setting/category/'+attrs['id'];
                break;

            case "subcategory-setting":
                this.get = 'setting/subcategory';
                this.getOne = 'setting/subcategory/'+attrs['id'];
                this.create = 'setting/subcategory';
                this.update = 'setting/subcategory/'+attrs['id'];
                this.filter = 'setting/subcategory/filter/';
                break;

            case "tag-setting":
                this.get = 'setting/tag';
                this.getOne = 'setting/tag/'+attrs['id'];
                this.create = 'setting/tag';
                this.update = 'setting/tag/'+attrs['id'];
                this.filter = 'setting/tag/filter/';
                break;

        }

    }

}
