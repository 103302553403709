<template>

    <SpaceBuilder
        @catchRowClicked="{}"
    />

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "HistoryView.vue",
    components: {SpaceBuilder, SpaceMenu,}
    }
</script>

<style lang="scss">

.history{

    .history_content{
        $width: 400px;
        @include assignValue(width max-width min-width, $width);
    }

    .date{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }
}

</style>
