<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "TopSupplier.vue",
    components: {SpaceBuilder}
}
</script>

<style lang="scss">

.top-supplier{

    thead{
        position: relative;
    }

    .contact_name{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .amount_total{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
        text-align: right;
    }

}

</style>
