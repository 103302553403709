<template>
     <SpaceBuilder
         :attrs="attrs"
         @run="catchRun"
     />
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject} from "vue";

export default {
    name: "StashView.vue",
    props: [
        'attrs'
    ],
    components: {SpaceBuilder},
    setup(){

        /** @type Google */
        const google = inject('Google')

        /** @type Dialog */
        const dialog = inject('Dialog')


        /** @type StashService */
        const stashService = inject('StashService')

        function catchRun({method, ...rest}){
            methods[method]({method, ...rest})
        }

        const methods = {

            closeStash({row, attrs}){
                // TODO check if close could be performed
                dialog.loading('Closing stash')

                stashService.closeStash(attrs, row.id)
                    .then((response)=>{
                        dialog.success(response.message)
                        Object.assign(row, response.updated)
                    })
                    .catch(dialog.error)
            },

            downloadStash({row}){
                if(!row?.driveId) dialog.error('Drive ID is not defined')
                google.drive.files.open(row.driveId)
            }

        }

        return {catchRun}
    }
}
</script>

<style lang="scss">

.stash{

    .stash_company_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .stash_status{
        $width: 55px;
        @include assignValue(width max-width min-width, $width);
        button{
            width: 50px;
        }
    }

    .stash_download{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
        text-align: center;
        button{
            width: 25px;
        }
    }

    .stash_closed{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
