<script setup>

import {inject, onMounted, ref} from "vue";

const emit = defineEmits([
    'filesDropped'
])

/** @type Dialog */
const dialog = inject('Dialog')

let files = ref([])
let dropping = ref()
function setActive(){
    dropping.value = true
}

function setInactive(){
    dropping.value = false
}

function onDrop(e){
    let droppedFiles = [...e.dataTransfer.files]

    if(!droppedFiles || !droppedFiles.length){
        //dialog.error('Error dropping files.<br>Try it again please.')
        console.log('drop error')
        // todo add border flash
    }
    else{
        files.value = [
            ...files.value,
            ...droppedFiles
        ]
        console.log('files.value', files.value)
        emit('filesDropped', files.value)
    }
    setInactive()
}




function preventDefaults(e) {
    e.preventDefault()
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

onMounted(() => {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, preventDefaults)
    })
})

</script>

<template>
    <div class="drop-area">
        <div
            class="rect"
            :class="[
                dropping?'dropping':'',
            ]"
            @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive"
            @drop.prevent="onDrop"
        >
            <span @drop.prevent="onDrop">Drop your file(s) here</span>
        </div>

    </div>

</template>

<style scoped lang="scss">
.drop-area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    .rect{
        width: fit-content;
        border: 1px solid #f0f0f0;
        border-radius: 3px;
        padding: 15px;

        span{
            pointer-events: none;
        }

        &.dropping{
            background-color: $lightGreen;
        }
    }


}

</style>
