import ApiRouter from "@/app/ApiRouter";
import $ from "jquery";

export default{
    install: (app)=>{

        /**
         * @class
         * @name Napi
         * @returns {Promise}
         */
        class Napi{

            getStatus(){
                return new Promise((resolve, reject) => {
                    $.ajax({
                        url: process.env.VUE_APP_NAPI_ROOT+'/status',
                        dataType: 'json',
                        type: 'GET',
                        success: function (data) {
                            resolve(data)
                        },
                        error: function (xhr) {
                            let responseJSON = xhr.responseJSON;
                            let message = responseJSON.message;
                            reject(message);
                        }
                    });
                })
            }

            extractIsdocDataFromPdf(attrs, file){
                return new Promise((resolve, reject) => {

                    let sendData = new FormData();
                    sendData.append('file', file);

                    $.ajax({
                        url: process.env.VUE_APP_NAPI_ROOT+'/pdf/isdoc-extract',
                        data: sendData,
                        dataType: 'json',
                        type: 'POST',
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            resolve(data.message)
                        },
                        error: function () {
                            reject();
                        }
                    });
                })
            }


        }

        app.napi = new Napi;
        app.provide('Napi', new Napi)
    }
}
