<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "CategorySettingView.vue",
    components: {SpaceBuilder, }
}
</script>

<style lang="scss">

.category-setting{

    .member_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .member_mail{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .member_user_group_id{
        $width: 85px;
        @include assignValue(width max-width min-width, $width);
    }

    .member_crew_share{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .member_vat_cashback{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .involved_transaction_count{
        $width: 30px;
        @include assignValue(width max-width min-width, $width);
    }

    .involved_backlog_count{
        $width: 30px;
        @include assignValue(width max-width min-width, $width);
    }


}

</style>
