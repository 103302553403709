<script setup>
import {inject, onUnmounted, ref, watchEffect} from "vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
const env = inject('env')

/** @type Utilities */
const utilities = inject('Utilities')


/** @type BankService */
const bankService = inject('BankService')

let sum = ref(0)
let sums = ref({})
let balance = ref()
let accounts = ref()

bankService.getBankBalance()
    .then((response)=>{
        balance.value = response
    })

watchEffect(()=>{
    sum.value = 0
    sums.value = {}
    if(env.obligations.length && balance.value){
        let uniqueAccountIds  = [...new Set(env.obligations.map(row => row['bank_account_id']))];
        accounts.value = balance.value.filter(account=>uniqueAccountIds.includes(account.id))
        env.obligations.forEach((row)=>{
            let amount = Number(row['transaction_amount_to_paid_czk'])
            if(!sums.value[row['bank_account_id']]) sums.value[row['bank_account_id']] = 0
            sums.value[row['bank_account_id']] += amount
            sum.value += amount
        })
    }
})

onUnmounted(()=>{
    env.obligations = []
})


</script>

<template>
    <div class="obligations-assistant">
        <template v-if="balance">
            <div class="total">
                Total amount {{ utilities.formatCurrency(sum) }}
            </div>
            <table class="accounts">
                <tbody>
                <tr v-for="account in accounts">
                    <td class="name">{{account.name}}</td><td class="currency original">{{utilities.formatCurrency(account.balance)}}</td><td class="currency">{{utilities.formatCurrency(sums[account.id])}}</td><td class="currency" :class="account.balance-sums[account.id]>0?'positive':'negative'">{{utilities.formatCurrency(account.balance-sums[account.id])}}</td>
                </tr>
                </tbody>
            </table>
        </template>
        <LoadingDots v-else/>
    </div>
</template>

<style lang="scss">
.obligations-assistant{
    background-color: $lighterGray;
    padding: 8px;
    border-radius: 3px;

    .total{
        margin-bottom: 5px;
        font-weight: bold;
    }

    table.accounts{
        border-collapse: collapse;
        border: 1px dotted #f0f0f0;

        td{
            padding: 3px 6px;
            border: 1px dotted #f0f0f0;
        }
        .name{
            text-align: left;
        }
        .currency{
            text-align: right;
            &.negative{
                background-color: $red;
            }

            &.positive{
                background-color: $green;
            }
        }
    }
}
</style>
