import {inject} from "vue";

export default {
    install: (app) => {

        class ConfigurationService{

            /**
             * @return {Promise<unknown>}
             */
            loadGlobal(){
                return app.capi.get({
                    service: 'configuration',
                })
            }

            reloadTable(table){
                app.capi.get({
                    service: 'configuration',
                    path: 'getOne',
                    table
                })
                    .then((data)=>{
                        app.env.configuration[table] = data
                    })
                    .catch(app.dialog.nonCritical)
            }
        }

        app.service.configuration = new ConfigurationService
        app.provide('ConfigurationService', new ConfigurationService)
    }
}
