

export default{
    install: (app)=>{

        /**
         * @name SeriesService
         */
        class SeriesService {

            getUpcomingVs(attrs){

                return app.capi.get({
                    ...attrs,
                    path: 'getUpcomingVs'
                },{
                    volume: app.env.volume,
                    company: app.env.company
                })
            }

            static utilities = class {

            }

        }

        app.service.series = new SeriesService()
        app.provide('SeriesService', new SeriesService)

    }
}

