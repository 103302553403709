
export default{
    install: (app)=>{
        /** @name UserService */
        class UserService {

            /**
             * @returns {Promise<User>}
             */
            login(data){
                return app.capi.create({
                        service: 'user',
                        path: 'login'
                    },
                    data )
            }

            /**
             * @returns {Promise<User>}
             */
            cookiesLogin(){
                return app.capi.create({
                        service: 'user',
                        path: 'cookiesLogin'
                    })
            }

            /**
             * @returns {Promise<User>}
             */
            googleUserLogin(userData){
                return app.capi.create({
                        service: 'user',
                        path: 'googleLogin'
                    }, userData)
            }

            logout(){
                app.env.crew_id = null
                app.env.google.token = null
                app.env.login = null
                app.env.configuration = null
                app.env.logout = true
            }

            utilities = class {

                static saveLoginCookies(data){
                    document.cookie = "user_id="+data['user']['user_id'];
                    document.cookie = "sid="+data["sid"];
                    document.cookie = "secret_key="+data["secretKey"];
                    document.cookie = "member_group_level="+data["user"]['member_group_level'];
                }

            }


        }

        app.service.user = new UserService
        app.provide('UserService', new UserService)
    }
}

