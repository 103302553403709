<template>
     <SpaceBuilder/>
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "BankBalance.vue",
    components: {SpaceBuilder}
}
</script>

<style lang="scss">

.bank-balance{

    thead{
        position: relative;
    }

    .bank_account_name{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .bank_account_balance{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
        text-align: right;
    }

}

</style>
