
export default class ThreadRoute{

    constructor(attrs) {
        // If original service is set, define routes containing table variable
        if(attrs['original_service']){
            let originalService = services.get({service: attrs['original_service']});
            console.log('originalService attrs: '+JSON.stringify(originalService.attrs));
            let table = originalService.attrs['db_table']?originalService.attrs['db_table']:attrs['original_service'];
            this.create = 'thread/'+table+'/'+attrs['parent-id'];

        }

        this.getRecordThread = 'thread/table/'+attrs.table+'/'+attrs['id']; // Get thread for one record from certain table
        this.get = 'thread';
        this.openThread = 'thread/open/'+attrs['id'];
        this.closeThread = 'thread/close/'+attrs['id'];
        this.getOne = 'thread/'+attrs['id'];
        this.filter = 'thread/filter/';
        this.update = 'thread/'+attrs['id'];
        this.delete = 'thread/'+attrs['id'];
    }

}
