<script setup>
const props = defineProps(['toggle'])
defineEmits(['selected'])
function swap(){
    props.toggle.selected=props.toggle.selected===props.toggle.left.emit?props.toggle.right.emit:props.toggle.left.emit
}
</script>

<template>
<div class="toggle" @click="swap(); $emit('selected')">
    <span class="left" :class="props.toggle.selected===toggle.left.emit&&'selected'">{{ toggle.left.label }}</span><span class="right" :class="props.toggle.selected===toggle.right.emit&&'selected'">{{ toggle.right.label }}</span>
</div>
</template>

<style scoped lang="scss">
.toggle{
    display: flex;
    height: auto;
    background-color: $lightGray;
    padding: 3px;
    border-radius: 3px;

    span{
        color: #2c2c2c;
        background-color: $whiteGray;
        padding: 6px 8px;
        width: 80px;
        font-weight: bold;
        cursor: pointer;
        text-transform: uppercase;
        &.left{
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        &.right{
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
        &.selected{
            background-color: $goldOrange;
            color: $whiteGray;
        }
    }
}
</style>
