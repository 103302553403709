import {reactive, ref} from "vue";

export default class Monolog{
    constructor(){
        this.errors = reactive([])
        this.alerts = reactive([])
        this.successes = reactive([])
    }

    addError(e){
        if(typeof e === 'object') {
            e = e.message?e.message:e.code!==undefined&&e.code===0?'Internet connection error':'Unknown error'
        }
        this.errors.push(e)
    }

    addAlert(a){
        this.alerts.push(a)
    }

    addAlerts(alerts){
        for(let alert of alerts){
            this.addAlert(alert)
        }
    }

    addSuccess(s){
        this.successes.push(s)
    }

    reset(){
        this.errors.splice(0,this.errors.length);
        this.alerts.splice(0,this.alerts.length);
        this.successes.splice(0,this.successes.length);
    }

}
