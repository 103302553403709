
export default class VatRoute{

    constructor(attrs) {

        this.get = attrs.space;
        this.create = attrs.space;
        this.filter = attrs.space+"/filter/";
        this.update = attrs.space+"/"+attrs["id"];
        this.delete = attrs.space+"/";
        
    }

}