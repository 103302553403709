<script setup>

import {inject, onMounted, onUnmounted, ref} from "vue";
import Alerts from "@/components/tools/components/Alerts.vue";
import Errors from "@/components/tools/components/Errors.vue";
import Successes from "@/components/tools/components/Successes.vue";

/** @type Utilities */
const utilities = inject('Utilities')

const props = defineProps(['name', 'type', 'droppingFiles', 'monolog', 'disableScrolling'])
const emit = defineEmits(['droppedFiles', 'close'])

let zindex = utilities.getZindex()+1
let position = window.scrollY
let dropping = ref(false)

function onDrop(e){
    if(props.droppingFiles) return
    setInactive()
    emit('droppedFiles', e.dataTransfer.files)
}

function setActive() {
    dropping.value = !props.droppingFiles && dropWhitelist.includes(props.type)
}

function setInactive() {
    dropping.value = false
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']
const dropWhitelist = ['transaction', 'file']

function preventDefaults(e) {
    e.preventDefault()
}

function close(){
    console.log('close')
    emit('close')
}

onMounted(()=>{
    // file drop events
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, preventDefaults)
    })

    // To get the scroll position of current webpage
    let TopScroll = window.pageYOffset || document.documentElement.scrollTop;
    let LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;

    // if scroll happens, set it to the previous value
    window.onscroll = function() {
        window.scrollTo(props.disableScrolling?LeftScroll:window.scrollX, TopScroll);
    };
})

onUnmounted(()=>{
    // remove file drop events
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, preventDefaults)
    })
    // remove disabled scrolling
    console.log('number of', utilities.getNumberOfOpenDialogs())
    // todo use disable-scrolling counting on opened windows
    if(utilities.getNumberOfOpenDialogs()===0) window.onscroll = function() {};
})

</script>

<template>
    <div class="manager-drop" @click.self="close()"></div>

    <div class="manager"
         data-type="tool"
         :style="'top: '+(position+80)+'px'"
         :class="[props.type, [dropping?'dropping':'']]"
         @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive"
         @drop.prevent="onDrop"
    >
        <div class="inner">
            <button class="btn__close tool" @click="$emit('close')">X</button>
            <h2 class="headline" :class="props.type">
                <span>{{ props.name }}</span>
            </h2>
            <slot></slot>

            <Successes
                v-if="monolog.successes.length"
                :successes="monolog.successes"
            />

            <Errors
                v-if="monolog.errors.length"
                :errors="monolog.errors"
            />

            <Alerts
                v-if="monolog.alerts.length"
                :alerts="monolog.alerts"
            />
        </div>
    </div>

</template>

<style lang="scss">
.manager-drop {
    position: fixed;
    top: 0;
    left: 0;
    width: 2650px;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: v-bind(zindex);
}

.manager {
    background-color: cadetblue;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: v-bind(zindex);

    &.dropping{
        background-color: lighten(cadetblue, 10);
    }

    .inner {
        border-radius: 3px;
        padding: 10px 10px 10px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: fit-content;

        .space{
            thead {
                z-index: calc(v-bind(zindex) + 1);
            }
            tbody{
                z-index: v-bind(zindex);
            }
        }

        .single{
            thead {
                z-index: calc(v-bind(zindex) + 3);
            }
            tbody{
                z-index: calc(v-bind(zindex) + 2);
                .tooltip{
                    z-index: calc(v-bind(zindex) + 2);
                }
            }
        }

        .data-tabs{
            .data-tab{
                margin-top: 10px;
                border: none;
            }

            .btn{
                margin: 3px;
                border-radius: 3px;
                width: fit-content;
                max-width: unset;
                min-width: unset;

                color: $darkGray;
                background-color: transparentize($lightGray, 0.5);

                &:hover:not(:disabled){
                    background-color: $whiteGray;
                }

                &:disabled{
                    background-color: $whiteGray;
                }

            }
        }

        .loading-message{
            margin-top: 10px;

            .message{
                display: block;
                font-weight: bold;
                color: #f0f0f0;
            }

        }

        .error-label{
            margin-bottom: 0;
        }

    }
}
</style>
