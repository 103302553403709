

export default{
    install: (app)=>{

        /**
         * @name ProjectService
         */
        class ProjectService {

            /**
             * @param attrs
             * @returns {Promise<Project>}}
             */
            getOne(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'project',
                    space: 'project',
                    path: 'getOne'
                })
            }

            getProjectByHash(hash, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'project',
                    space: 'project',
                    path: 'getProjectByHash',
                    publicRoute: true,
                    hash
                })
            }

            uploadExpensesFiles(hash, files, data){
                let formData = new FormData()
                files.forEach((file)=>{
                    formData.append('file[]', file);
                })

                formData.append('data', JSON.stringify(data));

                return app.capi.uploadFormData(
                    {
                        service: 'project',
                        path: 'uploadExpensesFiles',
                        publicRoute: true,
                        hash
                    }, formData)
            }

            uploadExpenseFile(hash, file, data){
                let formData = new FormData()
                formData.append('file', file);
                formData.append('data', JSON.stringify(data));

                return app.capi.uploadFormData(
                    {
                        service: 'project',
                        path: 'uploadExpenseFile',
                        publicRoute: true,
                        hash
                    }, formData)
            }

            /**
             * @param attrs
             * @return {Promise}
             */
            shareProject(attrs){
                return app.capi.create({
                    ...attrs,
                    service: 'project',
                    space: 'project',
                    path: 'share'
                })
            }

            utilities = class {


            }

        }

        app.service.project = new ProjectService
        app.provide('ProjectService', new ProjectService)

    }
}

