
export default class ItemRoute{
    constructor(attrs) {
        switch(attrs.space){
            default:
            case 'item':
                this.get = 'item';
                this.getOne = 'item/'+attrs['id'];
                this.create = 'item/'+attrs['id'];
                this.filter = 'item/filter/';
                this.parentItems = 'item/parent/'+attrs['id'];
                this.deleteParentItems = 'item/parent/'+attrs['id'];
                this.param = 'item/param/';
                this.update = 'item/'+attrs['id'];
                this.delete = 'item/'+attrs['id'];
                break;
        }
    }

}
