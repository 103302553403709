<script setup>
function mouseOverButton(e){
    e.target.style.fill = '#00cb5e';
}

function mouseleaveButton(e){
    e.target.style.fill = '#00b050';
}

const emit = defineEmits([
    'toggleTimer'
])
</script>

<template>
    <svg
        @click="emit('toggleTimer')"
        @mouseover="mouseOverButton"
        @mouseout="mouseleaveButton"
        class="toggle-timer-button"
        fill="#00b050"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24px"
        height="24px">
        <path d="M8,5v14l11-7L8,5z"/>
    </svg>


<!--    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px">
    <path d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M10,16.5v-9l6,4.5L10,16.5z"/></svg>-->

</template>

<style scoped lang="scss">

</style>
