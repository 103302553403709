
export default{

    install:(app)=>{

        /**
         * @name Loader
         */
        class Loader{


            show(message, setting = {}){
                app.env.loader = {
                    message,
                    setting
                };
            }

            hide(){
                app.env.loader = false
            }

        }

        app.provide('Loader', new Loader)

    }
}
