<template>

    <div class="menu">
        <!--<router-link
            v-for="button in menu"
            :data-space="button.space"
            :to="{name: 'space', params: {space: button.space}}">
            <button :disabled="button.space===attrs.space">{{ button.name }}</button>
        </router-link>-->

        <button
            v-for="button in menu.filter(item=>{
                return filterMenuItems(item)
            })"
            :data-space="button.space"
            :disabled="button.space===attrs.space"
            @click="navigate(button.space)"
        >
            {{ button.name }}
        </button>
    </div>

</template>

<script>

import {useRouter} from "vue-router";
import {inject, reactive, watchEffect} from "vue";

export default {
    name: "ViewMenu",
    props: [
        'menu',
        'attrs'
    ],
    setup(props){
        /**
         * @type {SmartEnv}
         */
        const env = inject('env')

        function navigate(space){
            props.attrs.space = space
        }

        function filterMenuItems(item){
            return item['menu_view_display'] && Number(env.login.user['level'])<=Number(item['level'])
        }
        return {env, filterMenuItems, navigate}
    }
}
</script>

<style scoped lang="scss">

@import "@/assets/scss/_btn.scss";

.menu{

    button{
        @extend .btn__silver;
    }

    padding: 5px;
    background-color: rgba(250,250,250,0.5);

    a{

        &.router-link-active button{
            @extend .btn__silver__active;
        }

    }
}








</style>
