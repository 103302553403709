
export default class ProjectRoute{

    constructor(attrs) {

        switch(attrs.space){
            case 'project':
            default:
                this.get = attrs.space;
                this.getOne = "project/"+attrs["id"];
                this.getProjectByHash = "project/"+attrs["hash"]; //public route
                this.uploadExpenseFile = "project/"+attrs["hash"]; //public route
                this.create = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.update = "project/"+attrs["id"];
                this.delete = attrs.space+"/"+attrs['id'];
                this.share = attrs.space+"/"+attrs['projectId']+'/'+attrs['memberId'];
                break;

            case 'project-transaction':
                this.get = "transaction/"+attrs.type+"/project/"+attrs["projectId"];
                this.getOne = "transaction/"+attrs["id"]; //Get one transaction
                this.getPayments = "transaction/payments/"+attrs["id"]; //Get one transaction's payments
                this.create = "transaction";
                this.filter = "transaction/"+attrs.type+"/filter/";
                this.update = "transaction/"+attrs["id"];
                this.delete = "transaction/"+attrs["id"];
                this.useTemplate = 'template/use/'+attrs['id'];
                this.duplicate = 'transaction/duplicate/'+attrs['id'];
                break;

            case 'project-balance':
                this.get = 'project/balance/';
                break;
        }

    }

}
