import * as pdfjsLib from 'pdfjs-dist'
import jsQR from 'jsqr'
import $ from 'jquery'
export default{
    install: (app)=>{

        const regs = {
            contact_ico: { // preokopat na transaction_supplier_contact_id
                type: "exp-string",
                dataType: "string", // prekopat na datalist a automaticke ziskani hodnoty datalistu
                resultType: "regExp",
                expString: "((?!(3622304|08910529 ))\\d+)/i", //Vsechna IC krome Separation IC
                prependStrings: [
                    {
                        relevance: 96,
                        value: "ičo"
                    },
                    {
                        relevance: 97,
                        value: "ico"
                    },
                    {
                        relevance: 95,
                        value: "ič"
                    }
                ]
            },
            // todo zkontrolovat, jeslti pouzivame spravne
            contact_account: { // pozdeji se rolozi na contact_bank_account_number a contact_prefix a contact_bank_code
                type: "exp-string",
                dataType: "string",
                resultType: "regExp",
                expString: "((?:(?:\\d){2,6}(?:-))*(?:\\d){2,10}(?:\\/)(?:\\d){4})/i" // jeste ladit

            },
            transaction_vs: {
                type: "exp-string",
                dataType: "string",
                resultType: "regExp",
                expString: "(([aA-zZ]*)(\\d+))/i",
                prependStrings: [
                    {
                        relevance: 96,
                        value: "vs"
                    },
                    {
                        relevance: 97,
                        value: "Variabilní symbol"
                    },
                    {
                        relevance: 45,
                        value: "Faktura č."
                    },
                    {
                        relevance: 44,
                        value: "faktura c."
                    },
                    {
                        relevance: 43,
                        value: 'invoice number'
                    },
                    {
                        relevance: 42,
                        value: "invoice"
                    },
                    {
                        relevance: 41,
                        value: "faktura"
                    },
                    {
                        relevance: 40,
                        value: "invoice"
                    },
                    {
                        relevance: 50,
                        value: "Daňový doklad"
                    },
                    {
                        relevance: 51,
                        value: "Daňový doklad číslo"
                    },
                    {
                        relevance: 55,
                        value: "symbol platby"
                    },
                    {
                        relevance: 54,
                        value: "symbol úplaty"
                    },
                    {
                        relevance: 53,
                        value: "Zálohový-daňový doklad"
                    },
                    {
                        relevance: 50,
                        value: "variabilní"
                    }

                ]

            },
            transaction_ks: {
                type: "exp-string",
                dataType: "string",
                resultType: "regExp",
                expString: "(\\d+|-)/i",
                prependStrings: [
                    {
                        relevance: 96,
                        value: "ks"
                    },
                    {
                        relevance: 97,
                        value: "Konstantní symbol"
                    },
                    {
                        relevance: 45,
                        value: "konstantní"
                    }

                ]

            },
            transaction_ss: {
                type: "exp-string",
                dataType: "string",
                resultType: "regExp",
                expString: "(\\d+|-)/i",
                prependStrings: [
                    {
                        relevance: 96,
                        value: "ss"
                    },
                    {
                        relevance: 97,
                        value: "specifický symbol"
                    },
                    {
                        relevance: 45,
                        value: "specifický"
                    }

                ]

            },
            transaction_issue_date: {
                type: "exp-string",
                dataType: "date",
                resultType: "regExp",
                expString: "(\\d{1,4}(?:\\.|\\-|\\/| |\\. )(?:\\d{1,2})(?:\\.|\\-|\\/| |\\. )(?:\\d{1,4}))/i",
                prependStrings: [
                    {
                        relevance: 99,
                        value: "Datum fakturace"
                    },
                    {
                        relevance: 97,
                        value: "Datum vystavení"
                    },
                    {
                        relevance: 95,
                        value: "Datum vystavení daňového dokladu"
                    },
                    {
                        relevance: 93,
                        value: "Vystavení faktury"
                    },
                    {
                        relevance: 90,
                        value: "Date of issue"
                    },
                    {
                        relevance: 88,
                        value: "Datum vyhot, a dodání"
                    },
                    {
                        relevance: 86,
                        value: "Datum (DUZP)"
                    },
                    {
                        relevance: 84,
                        value: "Vystavení"
                    },
                    {
                        relevance: 83,
                        value: "Vystaveni"
                    },
                    {
                        relevance: 80,
                        value: "Vytvořena"
                    },
                    {
                        relevance: 50,
                        value: 'byl vystaven dne'
                    }

                ]
            },
            transaction_due_date: {
                type: "exp-string",
                dataType: "date",
                resultType: "regExp",
                expString: "(\\d{1,4}(?:\\.|\\-|\\/| |\\. )(?:\\d{1,2})(?:\\.|\\-|\\/| |\\. )(?:\\d{1,4}))/i",
                prependStrings: [
                    {
                        relevance: 99,
                        value: "Datum splatnosti"
                    },
                    {
                        relevance: 97,
                        value: "Splatnost"
                    },
                    {
                        relevance: 95,
                        value: "Due date"
                    },
                    {
                        relevance: 93,
                        value: "Splatnost dne"
                    },
                    {
                        relevance: 91,
                        value: "Payment due"
                    },
                    {
                        relevance: 89,
                        value: "Splatná dne"
                    },
                    {
                        relevance: 85,
                        value: "Splatnosti"
                    },
                    {
                        relevance: 80,
                        value: "Datum splatnosti částky za zúčtované období"
                    },
                    {
                        relevance: 70,
                        value: "Datum splatnosti částky za zúčtované období je"
                    }
                ]
            },
            transaction_duzp: {
                type: "exp-string",
                dataType: "date",
                resultType: "regExp",
                expString: "(\\d{1,4}(?:\\.|\\-|\\/| |\\. )(?:\\d{1,2})(?:\\.|\\-|\\/| |\\. )(?:\\d{1,4}))/i",
                prependStrings: [
                    {
                        relevance: 99,
                        value: "Datum uskut. zdaň. plnění"
                    }
                ]
            },
            transaction_amount_inc_vat: {
                type: "exp-string-dual",
                dataType: "currency",
                resultType: "regExp",
                expString: "(\\d+(?:(?:\\.\\d+| \\d+|,\\d+)?)+)/i",
                sameRelevancePrecedence: "higherValue",
                prependStrings: [
                    {
                        relevance: 98,
                        value: "Celkem Kč"
                    },
                    {
                        relevance: 97,
                        value: "celkem (Kč)"
                    },
                    {
                        relevance: 96,
                        value: "celkem (Kc)"
                    },
                    {
                        relevance: 95,
                        value: "Celkem k úhradě"
                    },
                    {
                        relevance: 94,
                        value: "Celkem k uhrade"
                    },
                    {
                        relevance: 91,
                        value: "Total amount"
                    },
                    {
                        relevance: 90,
                        value: "celkem"
                    },
                    {
                        relevance: 89,
                        value: "suma"
                    },
                    {
                        relevance: 80,
                        value: "cena"
                    },
                    {
                        relevance: 75,
                        value: "K úhradě"
                    },
                    {
                        relevance: 70,
                        value: "Celkem k úhradě včetně DPH"

                    },
                    {
                        relevance: 69,
                        value: "Celkem k úhradě včetně DPH - CZK"

                    },
                    {
                        relevance: 30,
                        value: "Výše pojistného"
                    }

                ],
                appendStrings: [
                    {
                        relevance: 99,
                        value: "czk"
                    },
                    {
                        relevance: 90,
                        value: "kč"
                    },
                    {
                        relevance: 80,
                        value: "kc"
                    }
                ]
            },
            transaction_record_type_id: {
                type: "include",
                resultType: "string",
                castExclusion: false,
                receiveExclusion: false,
                strings: [
                    "faktura",
                    "smlouva",
                    "dobropis",
                ]
            },
            transaction_payment_paid_type_id: {
                type: "include",
                resultType: "string",
                castExclusion: true,
                receiveExclusion: false,
                sets: {
                    1: [
                        "kartou",
                        "kartou online"
                    ],
                    2: [
                        "kartou na prodejně"
                    ],
                    3: [
                        "převodem"
                    ],
                    4: [
                        "hotově"
                    ],
                    5: [
                        "trvalý příkaz",
                        "příkazem k úhradě",
                        "trvalým příkazem",
                        "příkaz k úhradě"
                    ],
                    6: [
                        "inkaso",
                        "inkasem"
                    ],
                    // We do not use ATM type
                    8: [
                        "zápočet"
                    ]
                },
                strings: [ // pozdeji nebudeme pouzivat
                    "kartou",
                    "kartou na prodejně",
                    "převodem",
                    "hotově",
                    "trvalý příkaz",
                    "inkaso",
                    null,
                    "zápočtem"
                ]
            },
            transaction_vat_type_id: {
                type: "include",
                resultType: "string",
                sets: {
                    1: [
                        "0%",
                        "0 %"
                    ],
                    2: [
                        '7%',
                        '7 %'
                    ],
                    3: [
                        '10%',
                        '10 %'
                    ],
                    4: [
                        '15%',
                        '15 %'
                    ],
                    5: [
                        '21%',
                        '21 %',
                        '21.00%',
                        '21.00 %'
                    ],
                    6: [
                        '23%',
                        '23 %'
                    ],
                    7: [
                        '44%',
                        '44 %'
                    ]

                }
            },
            transaction_company_id: {
                type: "include",
                resultType: "string",
                strings: [
                    "Separation Films",
                    "Separation Limitoffka",
                ]
            }
        };

        /**
         * @class
         * @name Ocr
         */
        class Ocr{

            /**
             * Extract data from file using OCR reader
             * @param attrs
             * @param fileData
             * @param mode
             * @return {Promise<unknown>}
             */
            getDataFromFile(attrs, fileData, mode = 'save'){
                return new Promise((resolve, reject) => {
                    app.google.handle.readFileWithOcr(attrs, fileData)
                        .then((response)=>{
                            // Ve vracenem ocr string musime nahradit nove radky a mezery
                            let text = response['ocr-data'];
                            if(text){
                                //TODO due date wont work on GES invoices
                                text = text.replaceAll("\n", "\n");
                                text = text.replace(/[^\S\r\n]/g, " ");
                                // gas vrati string, ktery jako nove mezery pouziva [ted si to nepamatuju]
                                // nahradime tedy vsechny tyto mezery blank rezetcem, ale ne nove radky
                                let results = app.ocr.matchOcrData(text);
                                //console.log('results: '+JSON.stringify(results));
                                results = app.ocr.resolveOcrMatchedData(results, mode);
                                //console.log('results 2: '+JSON.stringify(results));
                                resolve(results);
                            }
                            else{
                                reject('No ocr text found.')
                            }
                        })
                        .catch(reject)
                })
            }

            /**
             * Extract QR data using OCR reader
             * @param attrs
             * @param file
             * @param mode
             * @return {Promise<unknown>}
             */
            getQrDataFromFile(attrs, file, mode = 'save'){
                return new Promise((resolve, reject) => {
                    let fileReader = new FileReader();
                    fileReader.onload = function() {
                        // Read QR code data
                        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js';
                        let typedarray = new Uint8Array(this.result);
                        let loadingTask = pdfjsLib.getDocument(typedarray);
                        loadingTask.promise.then(function(pdf) {
                            // TODO read all pages
                            pdf.getPage(1).then(function(page) {
                                let scale = 1.5;
                                let viewport = page.getViewport({ scale: scale, });
                                let canvas = document.getElementById('canvas');
                                let context = canvas.getContext('2d');
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;

                                let renderContext = {
                                    canvasContext: context,
                                    viewport: viewport
                                };

                                let pdfDoc = page.render(renderContext);
                                pdfDoc.promise.then(function() {
                                    let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                                    let code = jsQR(imageData.data, imageData.width, imageData.height);
                                    let codeData = app.ocr.utilities.readQrCodeData(code, mode);
                                    resolve(codeData);
                                });
                            });
                        });
                    };
                    fileReader.readAsArrayBuffer(file);
                })
            }

            matchOcrData(text = null){
                let service = new Ocr();
                let results = [];
                let confusions = {};
                let confusionString;
                let confusionDepth;
                let confusedProperties;
                let exclusionsList = [];
                let type;
                let exp;
                let strings;
                let prependStrings;
                let appendStrings;
                let relevance;
                let value;

                function setConfusedProperties(){
                    let confusedProperties = [];
                    let confusedPropertiesList = [];
                    let confusedPropertiesGroup= {};

                    function getComplexPrependString(prependStrings){
                        let prependStringsString = "";
                        for (let [index, prependString] of Object.entries(prependStrings)) {
                            //console.log("prependString: "+JSON.stringify(prependString));
                            if(index==0){
                                prependStringsString = prependString.value;
                            }
                            else{
                                prependStringsString += "|" +prependString.value;
                            }
                        }
                        return "\\b("+prependStringsString+")(?:\\s)*(?::|\\||-|\\s)*(?:\\s)*"; // TODO save as let and reuse
                    }

                    function getRegForThirdLevelForeigns(prependStrings, secondPrependStrings, thirdPrependStrings){

                        let complexPrependString = getComplexPrependString(prependStrings);
                        //console.log("complexPrependString: "+complexPrependString);
                        let complexSecondPrependString = getComplexPrependString(secondPrependStrings);
                        //console.log("complexSecondPrependString: "+complexSecondPrependString);
                        let complexThirdPrependString = getComplexPrependString(thirdPrependStrings);
                        let complexRegString = "/"+complexPrependString + "(?: )*" + complexSecondPrependString+ "(?: )" + complexThirdPrependString+"/i";
                        //console.log("complexRegString: "+complexRegString);
                        let a = complexRegString.split("/");
                        let modifiers = a.pop(); a.shift();
                        let pattern = a.join("/");

                        return new RegExp(pattern, modifiers);

                    }

                    function setConfusionsList(){

                        function getRegFromPerm(perm){
                            let regsCollection = [];
                            for(let property of perm){
                                let setting = regs[property];
                                regsCollection.push(getComplexPrependString(setting.prependStrings)) ;
                            }

                            let regString;
                            for(let i=0; i<regsCollection.length; i++){
                                let reg = regsCollection[i]
                                if(i===0){
                                    regString = '/' + reg;
                                }
                                else{
                                    regString += '(?: )*' + reg;
                                    if((i+1)===regsCollection.length) regString += '/i'
                                }
                            }

                            //console.log('regString: '+regString);

                            let a = regString.split("/");
                            let modifiers = a.pop(); a.shift();
                            let pattern = a.join("/");

                            return new RegExp(pattern, modifiers)

                        }

                        function getCombinations(properties) {
                            var result = [];
                            var f = function(prefix, properties) {
                                for (let i = 0; i < properties.length; i++) {
                                    if(prefix.concat(properties[i]).length>=minDepth) result.push(prefix.concat(properties[i]));
                                    f(prefix.concat(properties[i]), properties.slice(i + 1));
                                }
                            }
                            f([], properties);
                            return result;
                        }

                        const permutator = (inputArr) => {
                            let result = [];

                            const permute = (arr, m = []) => {
                                if (arr.length === 0) {
                                    result.push(m)
                                } else {
                                    for (let i = 0; i < arr.length; i++) {
                                        let curr = arr.slice();
                                        let next = curr.splice(i, 1);
                                        permute(curr.slice(), m.concat(next))
                                    }
                                }
                            }

                            permute(inputArr)

                            return result;
                        }

                        let properties = Object.entries(regs)
                            .filter(function([property, setting]){
                                return (setting.type === 'exp-string' || setting.type === 'exp-string-dual') && setting.prependStrings
                            })
                            .map(function([property, setting]){
                                return property
                            })

                        let minDepth = 2;
                        //let maxDepth = properties.length;
                        let maxDepth = 4;
                        console.log('maxDepth: '+maxDepth)

                        getCombinations(properties)
                            .filter(function(array){
                                return array.length<=maxDepth;
                            })
                            .sort(function (a, b) {
                                return b.length - a.length;
                            })
                            .forEach(function(combination){
                                let perms = permutator(combination)
                                //console.log('perms '+combination.length+': '+JSON.stringify(perms));
                                perms.forEach(function(perm){
                                    // We must check whether some of perm properties are already on confusedPropertiesList
                                    if(!perm.some(r=> confusedPropertiesList.indexOf(r) >= 0)){
                                        let reg = getRegFromPerm(perm);
                                        let matches = text.match(reg);
                                        if (matches) {
                                            let lookupExplanations = [];
                                            //console.log('reg: '+reg)
                                            console.log('matches: '+JSON.stringify(matches))
                                            let string = matches[0]; // Save combination of strings to main string <- this string was found be match
                                            let strings = matches.slice(1); // Save all strings that were found
                                            console.log("perm: " + JSON.stringify(perm));

                                            confusedPropertiesGroup = {
                                                depth: perm.length, // Depth of lookup
                                                string: string, // Main string found on invoice ie: Variabilni: Konstantni: Datum splatnosti:
                                                properties: perm, // Collection of properties being permutated in this iteration
                                                strings: strings // Array collection of strings from main string
                                            };
                                            confusedProperties.push(confusedPropertiesGroup);
                                            confusedPropertiesList = confusedPropertiesList.concat(perm)
                                        }
                                    }
                                    else{
                                        console.log('Some of properties are already on confusedPropertiesList.')
                                    }

                                })
                            })
                    }

                    if(text){
                        setConfusionsList()
                        confusions = {
                            list: confusedPropertiesList,
                            properties: confusedProperties
                        }
                        console.log("confusions: " + JSON.stringify(confusions));
                    }
                }

                // Zkontrolujeme, zda hledany retezec neobsahuje retezec z jineho property
                function checkForExclusion(property, string){

                    function checkStringExclusion(string, setString){
                        // TODO using includes on string? Should be compare operator instead
                        if(string.toLowerCase().includes(setString.toLowerCase())){
                            //console.log(setString + " found in "+string);
                            // TODO zatim nevim, jak nasledne s exclusionsListem nakladame
                            exclusionsList.push(setString);
                        }
                    }

                    //console.log("property to check: "+property);
                    //console.log("string to check: "+string);

                    /* Get all properties instead of property that is being checked */
                    for (let [checkProperty, setting] of Object.entries(regs).filter(function([prop]){
                        return prop!==property;
                    })) {

                        //console.log("property to compare: "+checkProperty);
                        switch(setting.type){
                            case "include":
                                if(setting.sets){
                                    for (let [set, setStrings] of Object.entries(setting.sets)){
                                        //console.log("setStrings: "+JSON.stringify(setStrings));
                                        for(let setString of setStrings){
                                            checkStringExclusion(string, setString);
                                        }
                                    }
                                }
                                else{
                                    for(let setString of setting.strings){
                                        //console.log("setString: "+setString);
                                        if(strings.includes(setString)){
                                            //console.log(setString + "found in "+string);
                                            checkStringExclusion(string, setString);
                                        }
                                    }
                                }
                                break;

                            case "exp-string-dual":
                            case "exp-string":
                                if(setting.prependStrings){
                                    for (let {relevance, value:setString} of setting.prependStrings){
                                        //console.log("setString: "+JSON.stringify(setString));
                                        checkStringExclusion(string, setString);
                                    }
                                }

                                break;
                        }

                    }

                }

                function matchIncludesProperties(){
                    for (let [property, setting] of Object.entries(regs)) {
                        // Vynechame properties, ktere jsou na confusion listu a ktere jsou typu include
                        if (!confusions.list.includes(property) && setting.type === "include") {
                            let data = {
                                property: property,
                                matches: []
                            };

                            // V pripade, ze obsahuje definovane sady: sets
                            if(setting.sets){
                                for (let [set, strings] of Object.entries(setting.sets)) {
                                    //console.log("set: "+set);
                                    for(let string of strings){
                                        //console.log("string to find: "+string);
                                        let matches = {
                                            values: []
                                        };
                                        if(text.toLowerCase().includes(string.toLowerCase())){
                                            //console.log("text includes: "+string);
                                            matches.relevance = 100;
                                            matches.string = string;
                                            matches.values.push(set);
                                            data.matches.push(matches);
                                            if(setting.castExclusion){
                                                checkForExclusion(property, string);
                                            }
                                        }
                                    }
                                }
                            }
                            else{
                                strings = setting.strings;
                                for(let a=0; strings.length>a; a++){
                                    let string = strings[a];
                                    let matches = {};
                                    matches.values = [];
                                    if(string!=null){
                                        if(text.toLowerCase().includes(string.toLowerCase())){
                                            matches.relevance = 100;
                                            matches.values.push(a+1);
                                            data.matches.push(matches);
                                            if(setting.castExclusion){
                                                checkForExclusion(property, string);
                                            }
                                            break;
                                        }
                                    }
                                }
                            }

                            results.push(data);

                        }
                    }
                }

                function matchUnconfusedProperties(){

                    for (let [property, setting] of Object.entries(regs)) {
                        // Vynechame properties, ktere jsou na confusion listu a ktere jsou typu include
                        if(!confusions.list.includes(property) && setting.type!=="include"){
                            let data = {};
                            let expString = setting.expString;
                            prependStrings = setting.prependStrings;
                            appendStrings = setting.appendStrings;
                            type = setting.type;
                            data.property = property;
                            data.matches = [];

                            function passAppendAndPrependMatches(prependStrings, appendStrings){

                                // V pripade, ze k vyhledavani neni k dispozici prependStrings ani appendStrings
                                // Pouzijeme k vyhledavani cisty RegExp, kterz pouze predchazi \s
                                if(!prependStrings && !appendStrings){
                                    let matches = {};
                                    exp = app.ocr.utilities.getOcrRegFromString(expString, "clean");
                                    if(text.match(exp)){
                                        matches.relevance = 50;
                                        matches.string = text.match(exp)[0];
                                        matches.values = [text.match(exp)[1]];
                                        data.matches.push(matches);
                                    }
                                }
                                if(prependStrings){
                                    for(let prependString of prependStrings){
                                        let matches = {};
                                        relevance = prependString.relevance;
                                        value = prependString.value;
                                        if(relevance && value){
                                            exp = app.ocr.utilities.getOcrRegFromString(expString, "prepend", value);
                                            if(text.match(exp)){
                                                matches.relevance = relevance;
                                                matches.string = text.match(exp)[0];
                                                matches.values = [text.match(exp)[1]];
                                                data.matches.push(matches);
                                            }
                                        }
                                    }
                                }

                                if(appendStrings){
                                    for(let appendString of appendStrings) {
                                        let matches = {};
                                        relevance = appendString.relevance;
                                        value = appendString.value;
                                        if (relevance && value) {
                                            exp = app.ocr.utilities.getOcrRegFromString(expString, "append", null, value);
                                            if (text.match(exp)) {
                                                matches.relevance = relevance;
                                                matches.string = text.match(exp)[0];
                                                matches.values = [text.match(exp)[1]];
                                                data.matches.push(matches);
                                            }
                                        }
                                    }
                                }
                            }

                            switch(type){
                                // Pokusi se vyhledat cely retezec. Tedy vcetne prependString a appendString
                                case "exp-string-dual":
                                    for(let prependString of prependStrings){
                                        for(let appendString of appendStrings){
                                            let matches = {};
                                            if(prependString.relevance && appendString.relevance && prependString.value && appendString.value){
                                                exp = app.ocr.utilities.getOcrRegFromString(expString, "dual", prependString.value, appendString.value);
                                                if(text.match(exp)){
                                                    matches.relevance = prependString.relevance+appendString.relevance;
                                                    matches.string = text.match(exp)[0];
                                                    matches.values = [text.match(exp)[1]];
                                                    data.matches.push(matches);
                                                }
                                            }
                                        }
                                    }

                                    passAppendAndPrependMatches(prependStrings, appendStrings);
                                    break;

                                case "exp-string":
                                    passAppendAndPrependMatches(prependStrings, appendStrings);
                                    break;

                            }
                            results.push(data);
                        }
                    }
                }

                function matchConfusedProperties(){
                    console.log('Matching confused properties');
                    // Loop all confusion properties
                    for (let [index, confusion] of Object.entries(confusions.properties)) {
                        //console.log("confusion: "+JSON.stringify(confusion));
                        let reg = getConfusedPropertiesRegExp(confusion);
                        //console.log("reg: "+reg);
                        let matchedConfusionValues = text.match(reg);
                        //console.log("matchedConfusionValues: "+JSON.stringify(matchedConfusionValues));
                        if(matchedConfusionValues){
                            //console.log("matchConfusedProperties: "+JSON.stringify(matchedConfusionValues));
                            confusion.properties.forEach(function(property, index){
                                let data = {};
                                let position = Number(index)+1;
                                data.property = property;
                                data.matches = [];
                                let matches = {
                                    relevance: 100,
                                    string: matchedConfusionValues[0],
                                    values: [matchedConfusionValues[position]]
                                }
                                data.matches.push(matches);
                                //console.log("property: "+property) ;
                                //console.log("index: "+index) ;
                                //console.log("data: "+JSON.stringify(data)) ;
                                results.push(data);
                            });
                        }

                    }

                    function getConfusedPropertiesRegExp(confusion){
                        let regExpString = "";
                        //console.log("confusion: "+JSON.stringify(confusion));
                        confusionString = confusion.string;
                        //console.log("confusionString: "+confusionString);
                        confusionDepth = confusion.depth;
                        //console.log("confusionDepth: "+confusionDepth);
                        confusedProperties = confusion.properties;
                        //console.log("confusedProperties: "+JSON.stringify(confusedProperties));
                        regExpString = "/(?:"+confusion.string+")";
                        for (let [index, property] of Object.entries(confusedProperties)) {
                            //console.log("index: "+index);
                            let propertyRegExpString = "/"+regs[property].expString;
                            //console.log("propertyRegExpString: "+propertyRegExpString);
                            let a = propertyRegExpString.split("/");
                            let modifiers = a.pop(); a.shift();
                            let pattern = a.join("/");
                            //console.log("pattern: "+pattern);
                            //console.log("modifiers: "+modifiers);
                            if(index==0){
                                regExpString += pattern;
                            }
                            else{
                                regExpString += "(?:\\s)+" + pattern;
                            }

                        }
                        regExpString += "/i";
                        let a = regExpString.split("/");
                        let modifiers = a.pop(); a.shift();
                        let pattern = a.join("/");
                        return new RegExp(pattern, modifiers);
                    }
                }

                if(text){
                    setConfusedProperties();
                    matchIncludesProperties();
                    matchUnconfusedProperties();
                    matchConfusedProperties();
                    //console.log("exclusionsList: "+JSON.stringify(exclusionsList));
                }
                return results;
            }

            resolveOcrMatchedData(results, mode = "fill"){
                let dataFormat;
                let data = {};
                switch(mode){
                    case "fill":
                        dataFormat = "eu";
                        data.transaction_donor_subjects_id = {
                            value: 'crew',
                            string: "Predefined"
                        }

                        data.transaction_payment_transaction_type_id = {
                            value: 1,
                            string: "Predefined"
                        }

                        data.transaction_acceptor_subjects_id = {
                            value: 'external',
                            string: "Predefined"
                        }

                        data.transaction_invoice_status_type_id = {
                            value: 'issued',
                            string: "Predefined"
                        }

                        break;

                    case "save":
                        dataFormat = "us";
                        break;

                }

                for(let {property, matches} of results){
                    if(matches.length) {
                        let values = [];
                        let strings = [];
                        let value;
                        let string;
                        let relevantMatch = matches.reduce((max, match) => max.relevance > match.relevance ? max : match);
                        switch(regs[property]["resultType"]){
                            case "regExp":
                                let exp = this.utilities.getOcrRegFromString(regs[property].expString);
                                for(let matchedValue of relevantMatch.values){
                                    if(matchedValue && matchedValue.match(exp)){
                                        value = matchedValue.match(exp)[0];
                                        values.push(value);
                                        strings.push(relevantMatch.string);
                                    }
                                }

                                break;

                            case "string":
                                values = relevantMatch.values;
                                strings.push(relevantMatch.string);
                                break;
                        }

                        if(values.length>1){
                            let sameRelevancePrecedence = regs[property].sameRelevancePrecedence;
                            if(sameRelevancePrecedence){
                                //console.log("sameRelevancePrecedence: "+sameRelevancePrecedence);
                                switch(sameRelevancePrecedence){

                                    case "higherValue":
                                        let higherValue = null;
                                        let index = null;
                                        for(let i=0; values.length>i;i++) {
                                            //console.log("value: "+values[i]);
                                            let number = app.utilities.saveNumber(values[i], true);
                                            //console.log("number: "+number);
                                            if(higherValue == null || number > higherValue){
                                                index = i;
                                                higherValue = number;
                                            }
                                        }
                                        value = higherValue;
                                        string = strings[index];
                                        break;
                                }
                            }
                            else{
                                value = values[0];
                                string = strings[0];
                            }
                        }
                        else{
                            value = values[0];
                            string = strings[0];
                        }

                        switch(regs[property].dataType){
                            case 'currency':
                                value = app.utilities.saveNumber(value, false);
                                if(mode === "fill"){
                                    value = app.utilities.formatCurrency(value, 'us');
                                }
                                break;

                            case 'date':
                                value = app.utilities.formatDate(value, dataFormat);
                                break;

                            case 'number':
                                value = app.utilities.saveNumber(value, true);
                                break;

                        }
                        switch(mode){
                            case "fill":
                                data[property] = {};
                                data[property].string = string;
                                data[property].value = value;
                                break;

                            case "save":
                                data[property] = value;
                                break;
                        }

                    }
                }
                return data;
            }

            utilities = class  {

                static readQrCodeData(code, mode = 'save'){
                    let data = {};
                    if(code && code.data){
                        console.log('code: '+code.data);
                        switch(mode){
                            case 'save':
                                if(code.data.match(/VS:([0-9]+)/i)) data["transaction_vs"] = code.data.match(/VS:([0-9]+)/i)[1];
                                if(code.data.match(/AM:([0-9]+)/i)) data["transaction_amount_inc_vat"] = code.data.match(/AM:([0-9]+)/i)[1];
                                if (code.data.match(/MSG:(.*?)\*/i) && code.data.match(/MSG:(.*?)\*/i)[1]) data["transaction_item"] = code.data.match(/MSG:(.*?)\*/i)[1];
                                break;

                            case 'fill':
                                if(code.data.match(/VS:([0-9]+)/i)){
                                    data["transaction_vs"] = {};
                                    data["transaction_vs"].value = code.data.match(/VS:([0-9]+)/i)[1];
                                    data["transaction_vs"].string = "QR code";
                                }

                                if(code.data.match(/AM:([0-9]+)/i)){
                                    data["transaction_amount_inc_vat"] = {};
                                    data["transaction_amount_inc_vat"].value = code.data.match(/AM:([0-9]+)/i)[1];
                                    data["transaction_amount_inc_vat"].string = "QR code";
                                }

                                if(code.data.match(/MSG:(.*?)\*/i) && code.data.match(/MSG:(.*?)\*/i)[1]){
                                    data["transaction_item"] = {};
                                    data["transaction_item"].value = code.data.match(/MSG:(.*?)\*/i)[1];
                                    data["transaction_item"].string = "QR code";
                                }

                                break;
                        }
                    }
                    return data;

                }

                static getOcrRegFromString(reg, mode, prependString = null, appendString = null){
                    reg = "/"+reg;
                    let a = reg.split("/");
                    let modifiers = a.pop(); a.shift();
                    let pattern = a.join("/");

                    function getComplexPrependString(prependString){
                        let complexPrependString = "(?:"+prependString+")(?:\\s)*(?::|\\||-|\\s)*(?:\\s)*"; // TODO save as let and reuse
                        return complexPrependString;
                    }

                    function getComplexAppendString(appendString){
                        let complexAppendString = "(?: )*(?:"+appendString+")";
                        return complexAppendString;
                    }

                    function getComplexCleanString(){
                        let complexString = "(?:\\s)+";
                        return complexString;
                    }

                    switch(mode){

                        case "dual":
                            pattern = getComplexPrependString(prependString)+pattern+getComplexAppendString(appendString);
                            break;

                        case "prepend":
                            pattern = getComplexPrependString(prependString)+pattern;
                            break;

                        case "append":
                            pattern = pattern+getComplexAppendString(appendString);
                            break;

                        case "clean": // pouzije pouze cisty regExp
                            pattern = getComplexCleanString()+pattern;
                            break;
                    }

                    //console.log("pattern: "+pattern);
                    //console.log("modifiers: "+modifiers);

                    return new RegExp(pattern, modifiers);
                }

            }

        }

        app.ocr = new Ocr
        app.provide('Ocr', new Ocr)
    }
}


