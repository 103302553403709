
export default class SeriesRoute{

    constructor(attrs) {
        this.get = attrs.space+'/';
        this.filter = attrs.space+"/";
        this.getUpcomingVs = attrs.space+'/current-vs/'
    }

}
