<template>
    <div v-if="!members.length" class="error-label">
        <span>
            There are no members with due table in your crew
        </span>
    </div>
    <div v-else>
        <div class="due member-selector">
            <button
                v-for="member in members"
                @click="selectMember(member['member_slug'])"
                :disabled="attrs.member===member['member_slug']"
            >
                {{member['member_name']}}
            </button>
        </div>

        <div class="due balance-header">
            <div>
                <h4><span>{{ member.name }}'s balance</span></h4>
                <SpaceBuilder
                    ref="memberBalanceBuilder"
                    :attrs="{
                        ...attrs,
                        space: 'member-balance',
                        path: 'balance',
                        noMargin: true
                    }"
                />
            </div>

            <template v-if="member['member_crew_share']">
                <div>
                    <h4><span>{{ member.name }}'s checkout</span></h4>
                    <SpaceBuilder
                        ref="memberCheckoutBuilder"
                        :attrs="{
                            ...attrs,
                            service: 'due',
                            space: 'member-checkout',
                            path: 'checkout',
                            noMargin: true
                        }"
                    />
                </div>

                <div>
                    <h4><span>Crew's balance</span></h4>
                    <SpaceBuilder
                        ref="crewBalanceBuilder"
                        :attrs="{
                            ...attrs,
                            service: 'home',
                            space: 'crew-stat',
                            path: 'get',
                            noMargin: true,
                            ignoreScopes: true
                        }"
                    />
                </div>
            </template>
        </div>

        <SpaceBuilder
            ref="spaceBuilder"
            :attrs="attrs"
            @dataTouched="reloadBalances"
        />
    </div>

</template>


<script>

import {inject, reactive, ref, watch, watchEffect} from "vue";
import Button from "@/components/space/grid/Button.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "DueView.vue",
    components: {SpaceBuilder, Button, },
    props: ['attrs'],
    setup(props){

        /** @type {SmartEnv} */
        const env = inject('env')

        /** @type {Utilities} */
        const utilities = inject('Utilities')

        const spaceBuilder = ref()
        const memberBalanceBuilder = ref()
        const memberCheckoutBuilder = ref()
        const crewBalanceBuilder = ref()
        let member = ref()

        const members = env.configuration['member'].filter(member=>member['member_due_table'])
        member.value = members.find(member=>member.id===env.login.user['member_id'])
        if(!member.value) member.value = members[0]

        if(member.value){
            props.attrs.member = member.value.slug
        }

        function selectMember(slug){
            props.attrs.member = slug
            member.value = members.find(member=>member.slug===slug)
        }

        function reloadBalances(){
            memberBalanceBuilder.value.reFetch()
            memberCheckoutBuilder.value.reFetch()
            crewBalanceBuilder.value.reFetch()
        }

        return {
            env,
            members,
            member,
            selectMember,
            utilities,
            spaceBuilder,
            memberBalanceBuilder,
            memberCheckoutBuilder,
            crewBalanceBuilder,
            reloadBalances
        }
    }
}
</script>

<style lang="scss" scoped>

.error-label{
    margin-top: 20px;
}

</style>

<style lang="scss">

.member-balance,
.member-checkout{

    .name{
        text-align: right;
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .balance_positive,
    .balance_negative,
    .balance_total{
        text-align: right;
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    tbody tr:last-child td{
        border-top-style: solid;
        font-weight: bold;
    }

}


.due{

    &.balance-header{
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 10px;

        .member-balance{
            margin-top: 0;
        }

        h4{
            span{
                padding: 3px 6px;
                background-color: $lightenGray;
                color: $whiteGray;
                border-radius: 3px;
            }

        }
    }

    &.member-selector{
        width: fit-content;
        margin: 10px auto auto;

        button{
            margin: 3px;
            font-size: 12px;
            padding: 4px 6px;
            text-align: center;
            border-width: 0;
            background-color: #d9d8d8;
            color: black;
            text-transform: uppercase;
            height: 25px;
            cursor: pointer;
            font-weight: lighter;

            &:hover:not(:disabled){
                background-color: darken(#d9d8d8, 10);
            }

            &:disabled{
                background-color: $goldOrange;
                color: #f0f0f0;
            }
        }
    }

    .subject_balance,
    .subject_balance_czk{
        $width: 80px;
        text-align: right;
        @include assignValue(width max-width min-width, $width);
    }

    .subject_balance_type_id{
        text-align: center;
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
    }

}


</style>
