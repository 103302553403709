
export default class BacklogRoute{

    constructor(attrs) {

        this.get = attrs.space;
        this.filter = attrs.space+"/filter/";

    }

}