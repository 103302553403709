import {ocrReadableFileTypes} from "@/app/Settings";
import {TextEncoder} from 'text-encoding'
import $ from 'jquery'
import ApiRouter from "@/app/ApiRouter";
import * as pdfjsLib from "pdfjs-dist";
import jsQR from "jsqr";

export default{
    install: (app)=>{
        class StashService{

            /**
             * @param attrs
             * @return {Promise<File>}
             */
            getOne(attrs){
                return app.capi.get({
                    ...attrs,
                    'service': 'stash',
                    'space': 'stash',
                    'path': 'getOne'
                })
            }

            closeStash(attrs, id){
                return app.capi.update({
                    ...attrs,
                    'service': 'stash',
                    'space': 'stash',
                    'path': 'closeStash',
                    'id': id
                })
            }

        }

        app.service.stash = new StashService
        app.provide('StashService', new StashService)
    }
}

