
export default class FileRoute{

    constructor(attrs) {
        switch(attrs.space){
            default:
                this.uploadFile = 'drive/upload';
                this.relocateFile = 'drive/relocate/'+attrs['id'];
                this.relocateFiles = 'drive/relocates/'+attrs['id'];
                this.updateContactFolder = 'drive/contact/'+attrs['id'];
                this.createOrUpdateProjectFolder = 'drive/project/'+attrs['id'];
                break;

        }
    }


}
