<template>

    <SpaceBuilder
        ref="spaceBuilder"
        @catchRowClicked="{}"
    />

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {reactive, ref} from "vue";
export default {
    name: "ContactView.vue",
    components: { SpaceBuilder, SpaceMenu},

}
</script>

<style lang="scss">

.contact{

    margin-top: 0;

    .contact_name{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_ico{
        $width: 60px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_dic{
        $width: 75px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_street_name{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_building_number{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_city_name{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_postal_zone{
        $width: 50px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_country_id{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_prefix{
        $width: 70px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_bank_account{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_bank_code{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_mail_contact{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_mail_invoicing_contact{
        $width: 150px;
        @include assignValue(width max-width min-width, $width);
    }

    .contact_account{
        button{
            background-color: $darkOrange;
            color: $darkGray;

            &:hover{
                background-color: lighten($darkOrange, 10);
                color: $darkGray;
            }
        }
    }

}

</style>
