import {ocrReadableItemTypes} from "@/app/Settings";
import {TextEncoder} from 'text-encoding'
import $ from 'jquery'
import ApiRouter from "@/app/ApiRouter";
import * as pdfjsLib from "pdfjs-dist";
import jsQR from "jsqr";

export default{
    install: (app)=>{
        class ItemService{

            /**
             * @param attrs
             * @return {Promise<Item>}
             */
            getOne(attrs){
                return app.capi.get({
                    ...attrs,
                    'service': 'item',
                    'space': 'item',
                    'path': 'getOne'
                })
            }

            /**
             * Get all transaction items
             * @param attrs
             * @return {Promise<array>}
             */
            getParentItems(attrs = {}){
                return app.capi.get(
                    {
                        ...attrs,
                        service: 'item',
                        path: 'parentItems'
                    }
                )
            }

            deleteParentItems(id, attrs = {}){
                return app.capi.delete(
                    {
                        ...attrs,
                        service: 'item',
                        path: 'deleteParentItems',
                        id
                    }
                )
            }

            /**
             * Create new item
             * @param attrs
             * @param data
             * @return {Promise<Item>}
             */
            createItem(attrs = null, data){
                return app.capi.create(
                    {
                        ...attrs,
                        service: 'item'
                    }, data
                )
            }


        }

        app.service.item = new ItemService
        app.provide('ItemService', new ItemService)
    }
}

