<script setup>

import {inject} from "vue";
import DialogBase from "@/components/dialog/DialogBase.vue";

/** @type {SmartEnv}*/
let env = inject('env')

</script>

<template>
    <div class="public">
        <routerView/>

        <DialogBase
            v-if="env.dialog"
            :dialog="env.dialog"
            @close="env.dialog = null"
        />

    </div>
</template>

<style lang="scss">

.public{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

</style>