<script setup>

import Trackers from "@/components/tools/Trackers.vue";
import {inject} from "vue";
import ObligationsAssistant from "@/components/tools/ObligationsAssistant.vue";
const env = inject('env')
const utilities = inject('Utilities')

</script>

<template>
    <div class="widgets">
        <ObligationsAssistant v-if="env.obligations.length && env.service==='obligation'"/>
        <Trackers v-if="utilities.isThereAnyOpenTracker()"/>
    </div>
</template>

<style lang="scss">
.widgets{
    z-index: 1000;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
</style>
