<template>
    <span id="loading" :class="setting?.class">
        <span>&bull;</span>
        <span>&bull;</span>
        <span>&bull;</span>
    </span>
</template>

<script>
export default {
    name: "LoadingDots",
    props: [
        'setting'
    ]
}
</script>

<style scoped lang="scss">

#loading{
    &.addBar{
        color: crimson;
        font-size: 16px;
    }
}

</style>
