<script setup>
import DialogBase from "@/components/dialog/DialogBase.vue";
import {inject, reactive, ref} from "vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
import DropOffArea from "@/components/elements/DropOffArea.vue";
import $ from "jquery";
import Waiter from "@/components/elements/waiter/BaseWaiter.vue";
import LoadingCircle from "@/components/elements/waiter/LoadingCircle.vue";
import DeleteIcon from "@/components/elements/DeleteIcon.vue";
import DoneIcon from "@/components/elements/DoneIcon.vue";
import Alerts from "@/components/tools/components/Alerts.vue";
import AlertIcon from "@/components/elements/AlertIcon.vue";

const props = defineProps([
    'hash'
])

/** @type ProjectService */
const projectService = inject('ProjectService')

/** @type Dialog */
const dialog = inject('Dialog')

/** @type Utilities */
let utilities = inject('Utilities')

let project = ref()
let files = ref()
let wait = ref()
let data = reactive({
    invoice_request_public_mail: 'lucas@separation.cz'
})
let fileInfo = ref([])

projectService.getProjectByHash(props.hash)
    .then((item)=>{
        project.value = item
    })
    .catch(dialog.error)

function processDroppedFiles(droppedFiles){
    files.value = droppedFiles
}

function del(index){
    files.value.splice(index, 1);
}

function showError(i){
    dialog.error(fileInfo.value[i].error)
}

async function sendRequest(e) {

    if (!data['invoice_request_public_mail']) {
        dialog.error('Please provide your e-mail')
        return
    }

    if (!files.value) {
        dialog.error('You must drop file(s) before sending the request')
        return
    }

    utilities.preserveElementWidth(e.target)
    wait.value = true;

    (function call(i = 0){
        let file = files.value[i]
        function setFileStatus(status){
            if(!fileInfo.value[i]) fileInfo.value[i] = {}
            fileInfo.value[i].status = status
        }

        function getFileStatus(){
            return fileInfo.value[i]?.status
        }

        function next(){
            if(i+1<files.value.length) {
                call(i + 1)
            }
            else{
                wait.value = false
            }
        }

        if(getFileStatus()===undefined){
            setFileStatus('wait')
            uploadFile(file)
                .then(()=>{
                    setFileStatus('success')
                })
                .catch((e)=>{
                    fileInfo.value[i].error = e.message
                    setFileStatus('error')
                })
                .finally(()=>{
                    next()
                })
        }
        else{
            next()
        }
    })();
}

function uploadFile(file){
    return projectService.uploadExpenseFile(props.hash, file, data)
}

</script>

<template>
    <DialogBase
        :dialog="{
            message: 'Project invoice box',
            type: 'project-invoice-dropper'
        }"
    >
        <template v-if="project">
            <div class="form">
                <div v-if="project.company_fullname"><label>Company:</label> <span>{{project.company_fullname}}</span></div>
                <div><label>Project name:</label> <span>{{project.name}}</span></div>
                <div><label>Your name:</label> <span><input type="text" v-model="data['invoice_request_public_name']"></span></div>
                <div><label>Your mail:</label> <span><input type="text" v-model="data['invoice_request_public_mail']"></span></div>
            </div>

            <DropOffArea
                @filesDropped="processDroppedFiles"
            />

            <div class="files">
                <div class="file" v-for="(file, index) in files" :class="fileInfo[index]?.status">
                    <div class="icon">
                        <LoadingCircle :color="'#f0f0f0'" :width="'16px'" v-if="fileInfo[index]?.status==='wait'" />
                        <AlertIcon v-else-if="fileInfo[index]?.status==='error'" @click="showError(index)"/>
                        <DoneIcon v-else-if="fileInfo[index]?.status==='success'"/>
                        <DeleteIcon v-else @click="del(index)"/>
                    </div>

                    <label v-on="fileInfo[index]?.status==='error'?{click: ()=> showError(index)}:{}">
                        File {{index+1}}:
                    </label>
                    <span>
                        {{file.name}}
                    </span>
                </div>
            </div>

            <button
                @click="sendRequest"
                :disabled="wait"
            >
                <template v-if="!wait">
                    Send
                </template>
                <LoadingDots v-else/>
            </button>

        </template>
        <LoadingDots v-else/>
    </DialogBase>
</template>

<style scoped lang="scss">

.file{

    label{
        padding-left: 2px;
        padding-right: 2px;
    }

    &.error{
        label{
            background-color: $red;
            border-radius: 3px;
            cursor: pointer;
        }
    }
}

</style>
