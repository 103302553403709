<template>

    <SpaceBuilder
        @catchRowClicked="{}"/>

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "ProjectView.vue",
    components: {SpaceBuilder, SpaceMenu,},

    setup(props){
    }
}
</script>

<style lang="scss">

.backlog
{

    .user,
    .action{
        $width: 90px;
        @include assignValue(width max-width min-width, $width);
    }

    .space,
    .service,
    .column{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }

    .data_changer{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }

    .date{
        $width: 110px;
        @include assignValue(width max-width min-width, $width);
    }

}


</style>
