
<template>
    <div class="next-record-number-wrapper" v-if="env.company && env.volume">
        <span>Next {{ type }} number</span>
        <div class="number-area" >
            <span class="number" v-if="nextRecordNumber">{{nextRecordNumber}}</span>
            <LoadingDots v-else/>
        </div>
    </div>
</template>

<script setup>

import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
import {inject} from "vue";

defineProps({
    type: String,
    nextRecordNumber: String
})

const env = inject('env')

</script>

<style scoped lang="scss">

</style>
