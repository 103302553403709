<template>

    <ManagerBase
        :name="'Notifications'"
        :type="'notification'"
        :monolog="monolog"
        @droppedFiles=""
        @close="$emit('close')"
    >

        <div :style="{display: !env.notifications || !env.notifications.length?'none':''}">
            <SpaceBuilder
                ref="notificationSpaceBuilder"
                :attrs="{
                ...attrs, /* warning: this causes loosing reactivity of attrs */
                service: 'notification',
                space: 'notification',
                spaceLocation: 'tool',
                singleRow: false,
                disableFileDrop: true
            }"
                :pre-fetched-data="env.notifications"
                @catchRowClicked="openNotification"
            />
        </div>

        <template v-if="!env.notifications || !env.notifications.length">
            No notifications to display
        </template>

    </ManagerBase>

</template>

<script>

import {inject, onMounted, onUnmounted, reactive, ref, watchEffect} from "vue";
import LoadingDots from "@/components/elements/waiter/LoadingDots.vue";
import invoicingService from "@/services/InvoicingService";
import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import ManagerBase from "@/components/tools/ManagerBase.vue";
import Monolog from "@/objects/Monolog";

export default {
    name: "NotificationsTool.vue",
    components: {ManagerBase, SpaceBuilder, LoadingDots},
    props: [
        'attrs',
        'setting',
        'invoiceRequest'
    ],
    emits: [
        'close',
        'update',
        'run'
    ],
    setup(props, {emit}){

        console.log('opened')

        /** @type Dialog */
        const dialog = inject('Dialog')

        /** @type Monolog */
        let monolog = new Monolog();

        /** @type Utilities */
        const utilities = inject('Utilities')

        /** @type SmartEnv */
        const env = inject('env')

        let notificationSpaceBuilder = ref({})

        function openNotification({row, attrs, setting}){
            if(row.action){
                // run action from notification
                notificationSpaceBuilder.value.run({method: row.action, row: null, attrs, id: row.recordId})

                // mark notification as read
                notificationSpaceBuilder.value.update({
                    row,
                    update: ref({
                        notification_notification_status_id: 'read'
                    }),
                    attrs,
                    setting,
                    done: ()=>{
                        console.log('update done')
                        row.selected = false
                    }
                })
            }else{
                monolog.addAlert('No action defined for this notification')
            }
        }

        function markNotificationAsRead({row, attrs, setting}){

        }

        watchEffect(()=>{

        })

        return {env, monolog, close, openNotification, notificationSpaceBuilder}
    }
}
</script>

<style lang="scss">

@import "@/assets/scss/btn";

.manager.notification{
    .inner{

        min-width: 400px;

        .space.notification{

            margin-bottom: 10px;

            tbody tr{
                cursor: pointer;
            }

            .date{
                $width: 120px!important;
                @include assignValue(width max-width min-width, $width);
            }

            .notification_message{
                width: fit-content;
                min-width: 300px;
            }
        }

    }
}


</style>
