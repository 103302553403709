

export default{
    install: (app)=>{

        /**
         * @name ThreadService
         */
        class ThreadService {

            /**
             * @param attrs
             * @returns {Promise<Comment>}}
             */
            getOne(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'thread',
                    space: 'thread',
                    path: 'getOne'
                })
            }

        }

        app.service.thread = new ThreadService
        app.provide('ThreadService', new ThreadService)

    }
}

