<template>

    <SpaceBuilder
        @catchRowClicked="{}"/>

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "TemplateView.vue",
    components: {SpaceBuilder, SpaceMenu,},

}
</script>

<style lang="scss">

.template
{

    .transaction_template_name{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }


}


</style>
