<script setup>
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import {inject, ref, watchEffect} from "vue";
import moment from "moment";

const emits = defineEmits(['close', 'rangeChanged', 'reset'])

const props = defineProps(['range', 'mode'])

/** @type Utilities */
const utilities = inject('Utilities')

const timeMode = ref(props.mode === 'dateTime')
const mode = ref(props.mode);
const zindex = utilities.getZindex()+3

watchEffect(()=>{
    mode.value = timeMode.value?'dateTime':'date';
    if(props.range.value && props.range.value.start && props.range.value.end){
        let start = moment(props.range.value.start)
        let end = moment(props.range.value.end)

        if(props.mode==='date') {
            start = start.set({hour:0,minute:0,second:0})
            end = end.set({hour:23,minute:59,second:59})
        }

        start = start.format('YYYY-MM-DD HH:mm:ss')
        end = end.format('YYYY-MM-DD HH:mm:ss')

        emits('rangeChanged', {start, end, mode: mode.value})
    }
})

const attributes = ref([
    {
        key: 'today',
        dot: true,
        dates: new Date()
    },
]);



</script>

<template>

    <div class="date-time-picker-dialog" @click.self="$emit('close')">
        <div class="inner">
            <div class="message">Select date range <button class="btn__clear" @click="$emit('reset')">R</button></div>

            <div class="time_checker">
                <span>Define time</span>
                <input
                    type="checkbox"
                    v-model="timeMode"
                >
            </div>

            <DatePicker
                v-model.range="props.range.value"
                :mode="mode"
                :attributes="attributes"
                :first-day-of-week="2"
                is24hr
            />
        </div>
    </div>


</template>

<style lang="scss">

.date-time-picker-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 2650px;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: v-bind(zindex);

    .inner {
        position: fixed;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        background-color: cadetblue;
        border-radius: 3px;
        padding: 20px 10px;
        min-width: 400px;

        .message {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .time_checker{
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 10px;
        }

        .vc-pane-container{
            //display: flex;
        }
    }
}

</style>
