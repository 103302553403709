
export default class TemplateRoute{

    constructor(attrs) {
        this.get = "template";
        this.getOne = "template/"+attrs["id"];
        this.filter = "template/filter/";
        this.create = "template";
        this.update = "template/"+attrs["id"];
        this.delete = "template/"+attrs["id"];

    }

}
