<template>

    <SpaceBuilder
        @catchRowClicked="{}"/>

</template>

<script>

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "TransactionView.vue",
    components: {SpaceBuilder},
    setup(){

    }
}
</script>

<style lang="scss">

.transaction,
.bank-suggestion,
.project-transaction,
.obligation,
.invoicing,
.template,
.invoices-series,
.contracts-series,
.proformas-series,
.cash,
.due
{

    tbody{
        .transaction_payment_button{
            button{
                background-color: #e400e0;
                color: $lightGray;

                &:hover{
                    background-color: lighten(#e400e0, 15);
                }
            }
        }

        .transaction_has_comment{
            button{
                background-color: #00b050;
                color: #f0f0f0;

                &:hover{
                    background-color: darken(#00b050, 5);
                }
            }
        }

        .transaction_has_file{
            button{
                background-color: $blue;
                color: $lightGray;

                &:hover{
                    background-color: lighten($blue, 15);
                }
            }
        }
    }

    .transaction_vs,
    .transaction_record_number{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_ks{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_ss{
        $width: 55px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_currency_id{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_order_number{
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .transaction_invoice_status_type_id{
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .transaction_donor_subjects{
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .transaction_payment_transaction_type,
    .transaction_payment_paid_type_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_acceptor_subjects{
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .transaction_supplier_contact_list,
    .transaction_supplier_contact_id{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_bank_account,
    .transaction_bank_account_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .company,
    .transaction_company_id{
        $width: 80px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_vat_type,
    .transaction_vat_type_id{
        $width: 45px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_payment_paid_type{
        $width: 95px;
        @include assignValue(width max-width min-width, $width);
    }

    .transaction_item{
        $width: 180px;
        @include assignValue(width max-width min-width, $width);
        button{
            width: auto;
        }
    }

    .transaction_note{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .project_name{
        $width: 100px;
        @include assignValue(width max-width min-width, $width);
    }

    .category{
        $width: 75px;
        @include assignValue(width max-width min-width, $width);
    }


}


</style>
