
export default class CashRoute{

    constructor(attrs) {

        switch(attrs.space){
            case "record":
                this.get = attrs.space;
                this.filter = attrs.space+"/filter/";
                this.param = attrs.space+"/param/";
                this.update = "transaction/"+attrs["id"];
                this.delete = "transaction/";
                this.unpairPayment = attrs.space+"/unpair/"+attrs["id"];
                this.pairPayment = attrs.space+"/pair/"+attrs["id"]+"/"+attrs["transaction-id"];
                this.cloneTransaction = attrs.space+"/clone/"+attrs["id"]+"/"+attrs["transaction-id"];
                this.useTemplate = attrs.space+"/template/"+attrs["id"]+"/"+attrs["template-id"];
                this.createTransaction = attrs.space+"/create/"+attrs["id"];

                break;


            case "record-suggestion":
                this.get = "bank/suggest/"+attrs["id"];
                this.getOne = attrs.space+"/"+attrs["id"];
                this.create = attrs.space;
                this.filter = "bank/suggest/filter/";
                this.param = "bank/suggest/param/";
                this.update = "transaction/"+attrs["id"];
                this.delete = "transaction/";

                break;

        }

    }

}