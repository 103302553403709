<template>
    <tr class="menu">
        <td :colspan="columns.length">
            <button
                v-for="button in menu"
                @click="$emit('run', {
                    e: $event,
                    method: button.run
                })"
            >{{button.name}}</button>
        </td>
    </tr>
</template>

<script>
import {inject, watchEffect} from "vue";

export default {
    name: "SpaceMenu",
    props: [
        'menu',
        'columns',
    ],
    emits: [
        'run'
    ]
}
</script>

<style scoped lang="scss">

@import "@/assets/scss/_btn.scss";

.menu{

    td{
        background-color: cadetblue;
        padding-bottom: 8px;
        padding-top: 8px;
        border: 0;
        text-align: center;

        button{
            @extend .btn__dark;
            width: fit-content;
            font-size: 12px;
            padding: 3px 10px;
        }
    }
}


</style>
