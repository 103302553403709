

export default{
    install: (app)=>{

        /**
         * @name BankService
         */
        class BankService {

            /**
             * @param attrs
             * @returns {Promise<array>}}
             */
            getSuggestedTransactions(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'bank',
                    space: 'bank-suggestion'
                })
            }

            openBankManager(row, data){
                app.env.bankManager = {
                    row,
                    data
                }
            }

            getBankBalance(attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'bank',
                    space: 'balance'
                })
            }

            utilities = class {

            }

        }

        app.service.bank = new BankService
        app.provide('BankService', new BankService)

    }
}

