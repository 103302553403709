

export default{
    install: (app)=>{

        /**
         * @name MailService
         */
        class MailService {

            /**
             * @param attrs
             * @param data
             * @returns {Promise<array>}}
             */
            send(attrs = {}, data){
                return app.capi.create({
                    ...attrs,
                    service: 'mail',
                }, data)
            }

        }

        app.service.mail = new MailService
        app.provide('MailService', new MailService)

    }
}

