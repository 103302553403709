
export default class HomeRoute{

    constructor(attrs) {
        switch(attrs.space){
            case 'top-purchaser':
                this.get = 'home/purchasers';
                this.filter = 'home/purchasers/filter/'
                break;

            case 'top-supplier':
                this.get = 'home/suppliers';
                this.filter = 'home/suppliers/filter/'
                break;

            case 'unresolved-thread':
                this.get = 'thread/get/unresolved';
                break;

            case 'home-obligation':
                this.get = 'home/obligations';
                this.getSum = 'home/obligations/sum'
                break;

            case 'future-obligation':
                this.getOne = `transaction/${attrs['id']}`;
                this.update = `transaction/${attrs['id']}`;
                this.get = 'home/future-obligation';
                this.getSum = 'home/future-obligation/sum'
                break;

            case 'awaiting-receivable':
                this.get = 'home/awaiting-receivable';
                this.getSum = 'home/awaiting-receivable/sum'
                break;

            case 'future-receivable':
                this.get = 'home/future-receivable';
                this.getSum = 'home/future-receivable/sum'
                break;

            case 'bank-balance':
                this.get = 'balance';
                this.getSum = 'balance/total'
                break;

            case 'bank-unpair':
                this.get = 'bank/recent-unpair';
                break;

            case 'crew-stat':
                this.get = 'due/crew';
                this.filter = 'due/crew/filter/';
                break;
        }

    }
}
