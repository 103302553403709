import $ from 'jquery'
import {inject} from "vue";


export default{
    install: (app)=>{
        const ISDCOC_structure = {
            transaction_issue_date: {
                dataType: "date",
                location: "IssueDate"
            },
            transaction_due_date: {
                dataType: "date",
                location: "PaymentMeans.Payment.Details.PaymentDueDate"
            },
            transaction_item: {
                dataType: "text",
                location: "InvoiceLines.InvoiceLine.Item.Description"
            },
            currency_name: {
                dataType: "text",
                location: "LocalCurrencyCode"
            },
            contact_ico: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PartyIdentification.ID"
            },
            contact_dic: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PartyTaxScheme.CompanyID"
            },
            contact_name: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PartyName.Name"
            },
            contact_street_name: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PostalAddress.StreetName"
            },
            contact_building_number: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PostalAddress.BuildingNumber"
            },
            contact_city_name: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PostalAddress.CityName"
            },
            contact_postal_zone: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PostalAddress.PostalZone"
            },
            contact_country_id: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.PostalAddress.Country.IdentificationCode"
            },
            contact_mail_contact: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.Contact.ElectronicMail"
            },
            contact_tel_contact: {
                dataType: "text",
                location: "AccountingSupplierParty.Party.Contact.Telephone"
            },
            contact_account_number: {
                dataType: "text",
                location: "PaymentMeans.Payment.Details.ID"
            },
            contact_account_bank_code: {
                dataType: "text",
                location: "PaymentMeans.Payment.Details.BankCode"
            },
            company_ico: {
                dataType: "text",
                location: "AccountingCustomerParty.Party.PartyIdentification.ID"
            },
            amount_inc_vat: {
                dataType: "currency",
                location: "LegalMonetaryTotal.TaxInclusiveAmount"
            },
            amount_rounding: {
                dataType: "currency",
                location: "LegalMonetaryTotal.PayableRoundingAmount"
            },
            items:{
                dataType: 'array',
                location: 'InvoiceLines.InvoiceLine'
            },
            transaction_vs: {
                dataType: "text",
                location: "PaymentMeans.Payment.Details.VariableSymbol"
            },
            transaction_ks: {
                dataType: "text",
                location: "PaymentMeans.Payment.Details.ConstantSymbol"
            }
        }

        /**
         * @class
         * @name Isdoc
         */
        class Isdoc{

            /**
             * Extract data from any file
             * @param attrs
             * @param file
             * @return {Promise<Isdoc>}
             */
            extractIsdocData(attrs, file){
                return new Promise((resolve, reject)=>{
                    let fileName = file.name;
                    let extension = app.utilities.getFileExtension(fileName);
                    console.log('fileName', fileName)
                    console.log('extension', extension)
                    switch(extension){
                        case 'pdf':
                            this.extractIsdocDataFromPdf(attrs, file)
                                .then(resolve)
                                .catch(reject);
                            break;

                        case 'isdoc':
                            this.extractIsdocDataFromIsdoc(file)
                                .then(resolve)
                                .catch(reject);
                            break;

                        default:
                            return reject('This format does not provide isdoc extraction.')

                    }
                })

            }

            /**
             * Extract data from ISDOC file
             * @param file
             * @return {Promise<Isdoc>}
             */
            extractIsdocDataFromIsdoc(file){
                return new Promise((resolve, reject) => {
                    let fileReader = new FileReader();
                    fileReader.onload = (event)=>{
                        resolve(event.target.result)

                    }
                    fileReader.onerror = (error)=>{
                        reject(error)
                    }
                    fileReader.readAsText(file)
                })
            }

            /**
             * Try to extract embedded isdoc data from pdf file
             * @param attrs
             * @param file
             * @return {Promise<Isdoc>}
             */
            extractIsdocDataFromPdf(attrs, file){
                console.log('extractIsdocDataFromPdf')
                return app.service.file.getIsdocDataFromFile(attrs, file)
                    .then((data)=>{
                        console.log('data', data)
                        if(!data) throw new Error('No isdoc data found');
                        return data;
                    })
            }


            utilities = class {

                static parseXml(xml) {
                    return new Promise((resolve, reject) => {
                        let dom = null;
                        if (window.DOMParser) {
                            try {
                                dom = (new DOMParser()).parseFromString(xml, "text/xml");
                            }
                            catch (e) { dom = null; }
                        }
                        else if (window.ActiveXObject) {
                            try {
                                dom = new ActiveXObject('Microsoft.XMLDOM');
                                dom.async = false;
                                if (!dom.loadXML(xml)) // parse error ..

                                    window.alert(dom.parseError.reason + dom.parseError.srcText);
                            }
                            catch (e) { dom = null; }
                        }
                        else
                            reject("cannot parse xml string!");
                        resolve(dom);
                    })

                }

                /**	This work is licensed under Creative Commons GNU LGPL License.
                 License: http://creativecommons.org/licenses/LGPL/2.1/
                 Version: 0.9
                 Author:  Stefan Goessner/2006
                 Web:     http://goessner.net/
                 */
                static xml2json(xml, tab) {
                    return new Promise((resolve, reject) => {
                        var X = {
                            toObj: function(xml) {
                                var o = {};
                                if (xml.nodeType==1) {   // element node ..
                                    if (xml.attributes.length)   // element with attributes  ..
                                        for (var i=0; i<xml.attributes.length; i++)
                                            o["@"+xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
                                    if (xml.firstChild) { // element has child nodes ..
                                        var textChild=0, cdataChild=0, hasElementChild=false;
                                        for (var n=xml.firstChild; n; n=n.nextSibling) {
                                            if (n.nodeType==1) hasElementChild = true;
                                            else if (n.nodeType==3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
                                            else if (n.nodeType==4) cdataChild++; // cdata section node
                                        }
                                        if (hasElementChild) {
                                            if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                                                X.removeWhite(xml);
                                                for (var n=xml.firstChild; n; n=n.nextSibling) {
                                                    if (n.nodeType == 3)  // text node
                                                        o["#text"] = X.escape(n.nodeValue);
                                                    else if (n.nodeType == 4)  // cdata node
                                                        o["#cdata"] = X.escape(n.nodeValue);
                                                    else if (o[n.nodeName]) {  // multiple occurence of element ..
                                                        if (o[n.nodeName] instanceof Array)
                                                            o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                                                        else
                                                            o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                                                    }
                                                    else  // first occurence of element..
                                                        o[n.nodeName] = X.toObj(n);
                                                }
                                            }
                                            else { // mixed content
                                                if (!xml.attributes.length)
                                                    o = X.escape(X.innerXml(xml));
                                                else
                                                    o["#text"] = X.escape(X.innerXml(xml));
                                            }
                                        }
                                        else if (textChild) { // pure text
                                            if (!xml.attributes.length)
                                                o = X.escape(X.innerXml(xml));
                                            else
                                                o["#text"] = X.escape(X.innerXml(xml));
                                        }
                                        else if (cdataChild) { // cdata
                                            if (cdataChild > 1)
                                                o = X.escape(X.innerXml(xml));
                                            else
                                                for (var n=xml.firstChild; n; n=n.nextSibling)
                                                    o["#cdata"] = X.escape(n.nodeValue);
                                        }
                                    }
                                    if (!xml.attributes.length && !xml.firstChild) o = null;
                                }
                                else if (xml.nodeType==9) { // document.node
                                    o = X.toObj(xml.documentElement);
                                }
                                else
                                    console.log("unhandled node type: " + xml.nodeType);
                                return o;
                            },
                            toJson: function(o, name, ind) {
                                var json = name ? ("\""+name+"\"") : "";
                                if (o instanceof Array) {
                                    for (var i=0,n=o.length; i<n; i++)
                                        o[i] = X.toJson(o[i], "", ind+"\t");
                                    json += (name?":[":"[") + (o.length > 1 ? ("\n"+ind+"\t"+o.join(",\n"+ind+"\t")+"\n"+ind) : o.join("")) + "]";
                                }
                                else if (o == null)
                                    json += (name&&":") + "null";
                                else if (typeof(o) == "object") {
                                    var arr = [];
                                    for (var m in o)
                                        arr[arr.length] = X.toJson(o[m], m, ind+"\t");
                                    json += (name?":{":"{") + (arr.length > 1 ? ("\n"+ind+"\t"+arr.join(",\n"+ind+"\t")+"\n"+ind) : arr.join("")) + "}";
                                }
                                else if (typeof(o) == "string")
                                    json += (name&&":") + "\"" + o.toString() + "\"";
                                else
                                    json += (name&&":") + o.toString();
                                return json;
                            },
                            innerXml: function(node) {
                                var s = ""
                                if ("innerHTML" in node)
                                    s = node.innerHTML;
                                else {
                                    var asXml = function(n) {
                                        var s = "";
                                        if (n.nodeType == 1) {
                                            s += "<" + n.nodeName;
                                            for (var i=0; i<n.attributes.length;i++)
                                                s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue||"").toString() + "\"";
                                            if (n.firstChild) {
                                                s += ">";
                                                for (var c=n.firstChild; c; c=c.nextSibling)
                                                    s += asXml(c);
                                                s += "</"+n.nodeName+">";
                                            }
                                            else
                                                s += "/>";
                                        }
                                        else if (n.nodeType == 3)
                                            s += n.nodeValue;
                                        else if (n.nodeType == 4)
                                            s += "<![CDATA[" + n.nodeValue + "]]>";
                                        return s;
                                    };
                                    for (var c=node.firstChild; c; c=c.nextSibling)
                                        s += asXml(c);
                                }
                                return s;
                            },
                            escape: function(txt) {
                                return txt.replace(/[\\]/g, "\\\\")
                                    .replace(/[\"]/g, '\\"')
                                    .replace(/[\n]/g, '\\n')
                                    .replace(/[\r]/g, '\\r');
                            },
                            removeWhite: function(e) {
                                e.normalize();
                                for (var n = e.firstChild; n; ) {
                                    if (n.nodeType == 3) {  // text node
                                        if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                                            var nxt = n.nextSibling;
                                            e.removeChild(n);
                                            n = nxt;
                                        }
                                        else
                                            n = n.nextSibling;
                                    }
                                    else if (n.nodeType == 1) {  // element node
                                        X.removeWhite(n);
                                        n = n.nextSibling;
                                    }
                                    else                      // any other node
                                        n = n.nextSibling;
                                }
                                return e;
                            }
                        };
                        if (xml.nodeType == 9) // document node
                            xml = xml.documentElement;
                        var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
                        resolve(JSON.parse("{\n" + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}"));
                    })

                }

                /**
                 * Parse Isdoc objects to farmer transaction and file data objects {}
                 * @param invoice
                 * @param mode
                 * @returns {Promise<unknown>}
                 */
                static isdocToFarmerData(invoice, mode = 'save') {
                    console.log('invoice', invoice)
                    return new Promise(resolve => {
                        let dataFormat;
                        switch (mode) {
                            case "fill":
                                dataFormat = "eu";
                                break;

                            case "save":
                                dataFormat = "us";
                                break;
                        }
                        let data = {};
                        //let invoice = json.Invoice; // TODO solve in future
                        let value;
                        for (let [property, spec] of Object.entries(ISDCOC_structure)) {
                            let obj;
                            if(spec.location.includes(".")){
                                let location = spec.location.split(".");
                                for(let a=0;location.length>a;a++){
                                    if(a===0){
                                        obj = invoice[location[a]];
                                    }
                                    else{
                                        if(!obj) continue; // If previously retrieved objects is undefined, break this iteration
                                        obj = obj[location[a]];
                                    }

                                    if(Array.isArray(obj)){
                                        console.log('obj array', obj)
                                        if(obj.length===1){
                                            obj = obj[0];
                                        }
                                        else{
                                            // TODO solve if obj array has more than 1 entries - ie. InvoiceLines
                                            obj = obj;
                                        }
                                    }
                                }
                                value = obj;
                            }
                            else{
                                value = invoice[spec.location];
                            }

                            if(typeof value !== 'undefined'){
                                switch(spec.dataType){
                                    case "currency":
                                        value = app.utilities.saveNumber(value, false);
                                        if(mode === "fill"){
                                            value = app.utilities.formatMoney(value);
                                        }
                                        break;

                                    case "date":
                                        value = app.utilities.formatDate(value, dataFormat);
                                        break;

                                    case "number":
                                        value = app.utilities.saveNumber(value, true);
                                        break;
                                }

                                data[property] = value;
                            }

                        }
                        data['transaction_data_origin_id'] = 'isdoc';
                        console.log('data', data)
                        resolve(data);
                    })


                }


            }

        }

        app.isdoc = new Isdoc
        app.provide('Isdoc', new Isdoc)
    }
}


