
export default class TransactionRoute{

    constructor(attrs) {
        this.get = attrs.space;
        this.getOne = attrs.space+"/"+attrs["id"];
        this.create = attrs.space;
        this.filter = attrs.space+"/filter/";
        this.search = "transaction/search/params";
        //this.invoices = "file/transaction/invoices/"+attrs['id'];
        this.files = "file/transaction/"+attrs['id'];
        this.update = attrs.space+"/"+attrs["id"];
        this.delete = attrs.space+"/"+attrs['id'];
        this.duplicate = attrs.space+'/duplicate/'+attrs['id'];
        this.smartDuplicate = attrs.space+'/duplicate/smart/'+attrs['id'];
        this.useTemplate = 'template/use/'+attrs['id'];
        this.updateTransactionType = 'transaction/update-transaction-type/'+attrs['id'];
        this.rewriteItems = "transaction/rewrite-items/"+attrs["id"];
        this.getIncomeRequestSuggestedTransactions = 'transaction/request/income/suggest/'+attrs['id']
        this.getObligationRequestSuggestedTransactions = 'transaction/request/obligation/suggest/'+attrs['id']
    }

}
