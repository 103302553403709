<template>
     <SpaceBuilder
         ref="spaceBuilder"
         :attrs="attrs"
         @run="catchRun"
     />
</template>

<script>


import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {inject, reactive, ref, watch, watchEffect} from "vue";
export default {
    name: "InvoicingView.vue",
    components: {SpaceBuilder},
    props: [
        'attrs'
    ],
    setup(props){

        let spaceBuilder = ref()

        const methods = {

        }

        /*function openFile(transaction){
            spaceBuilder.value.run({method: 'openFile', row: transaction.invoice})
        }*/


        /*function sendInvoiceIncomeRequest(transaction){
            console.log('transaction', transaction)
            console.log('file', transaction.invoice)
            spaceBuilder.value.run({method: 'requestIncomeInvoice', transaction: transaction, file: transaction.invoice, type: 'income', attrs: props.attrs})
        }*/

        function catchRun({method, attrs}){
            methods[method]({attrs})
        }

        return {catchRun, spaceBuilder} // must include ref spaceBuilder as well
    }
}
</script>

<style lang="scss">

.invoicing{

    margin-top: 0;

    .transaction_due_days{
        $width: 35px;
        @include assignValue(width max-width min-width, $width);
    }
}

.invoice-request-obligation,
.invoice-request-income{

    /* set pointer cursor for whole space, not for tools where request is on single row */
    &.space:not(.single) tbody tr:hover{
        cursor: pointer;
    }

    .date{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }
}

</style>
