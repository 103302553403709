import BankService from "@/services/BankService";
import TransactionService from "@/services/TransactionService";
import ConfigurationService from "@/services/ConfigurationService";
import UserService from "@/services/UserService";
import FileService from "@/services/FileService";
import CommentService from "@/services/CommentService";
import SeriesService from "@/services/SeriesService";
import ThreadService from "@/services/ThreadService";
import ProjectService from "@/services/ProjectService";
import InvoicingService from "@/services/InvoicingService";
import DriveService from "@/services/DriveService";
import StashService from "@/services/StashService";
import MailService from "@/services/MailService";
import ContactService from "@/services/ContactService";
import ItemService from "@/services/ItemService";
import NotificationService from "@/services/NotificationService";

export default{
    install: (app)=>{

        app.use(BankService)
        app.use(TransactionService)
        app.use(ConfigurationService)
        app.use(UserService)
        app.use(FileService)
        app.use(CommentService)
        app.use(SeriesService)
        app.use(ThreadService)
        app.use(ProjectService)
        app.use(InvoicingService)
        app.use(DriveService)
        app.use(StashService)
        app.use(MailService)
        app.use(ContactService)
        app.use(ItemService)
        app.use(NotificationService)

        class Services{

            /** @type ConfigurationService */
            configuration = app.service.configuration;

            /** @type TransactionService */
            transaction = app.service.transaction;

            /** @type FileService */
            file = app.service.file;

            /** @type CommentService */
            comment = app.service.comment;

            /** @type SeriesService */
            series = app.service.series;

            /** @type ThreadService */
            thread = app.service.thread

            /** @type ProjectService */
            project = app.service.project

            /** @type InvoicingService */
            invoicing = app.service.invoicing

            /** @type DriveService */
            drive = app.service.drive

            /** @type StashService */
            stash = app.service.stash

            /** @type MailService */
            mail = app.service.mail

            /** @type ContactService */
            contact = app.service.contact

            /** @type ItemService */
            item = app.service.item

            /** @type NotificationService */
            notification = app.service.notification

        }

        app.provide('Services', new Services)
    }
}
