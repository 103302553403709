<template>

    <SpaceBuilder
        @catchRowClicked="{}"/>

</template>

<script>

import SpaceMenu from "@/components/space/SpaceMenu.vue";

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
export default {
    name: "VatView.vue",
    components: {SpaceBuilder, SpaceMenu,}
    }
</script>

<style lang="scss">


.file_open{
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.id{
    width: 25px;
    min-width: 25px;
    max-width: 25px;
}

.file_is_issued{
    $width: 25px;
    @include assignValue(width max-width min-width, $width);
    text-align: center;
    padding: 3px 0 1px;
}

.date{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.file_name{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

.file_file_type_id{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.file_vs{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.file_ks{
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.file_ss{
    width: 25px;
    min-width: 25px;
    max-width: 25px;
}

.file_drive_id{
    width: 325px;
    min-width: 325px;
    max-width: 325px;
    text-align: left;
}

</style>
