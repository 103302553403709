<template>
     <SpaceBuilder
         ref="spaceBuilder"
         :attrs="attrs"
         @catchRowClicked="openBankManager"
     />
</template>

<script>

import SpaceBuilder from "@/components/space/SpaceBuilder.vue";
import {defineAsyncComponent, inject, ref} from "vue";
export default {
    name: "BankUnpair.vue",
    components: {
        SpaceBuilder,
        BankManager: defineAsyncComponent(() => import('@/components/tools/BankManager.vue'))
    },
    props: [
        'attrs'
    ],
    setup(){

        let spaceBuilder = ref()

        function openBankManager({row}){
            spaceBuilder.value.methods.openBankManager({row})
        }

        return {
            openBankManager,
            spaceBuilder
        }

    }

}
</script>

<style lang="scss">

.bank-unpair{

    thead{
        position: relative;
    }

    tbody tr:hover{
        cursor: pointer;
    }

    .thread_space{
        $width: 120px;
        @include assignValue(width max-width min-width, $width);
    }

    .thread_record_id{
        $width: 40px;
        @include assignValue(width max-width min-width, $width);
    }

}

</style>
