<script setup>

import BaseHeader from "@/components/header/BaseHeader.vue";
import DialogBase from "@/components/dialog/DialogBase.vue";
import BaseWaiter from "@/components/elements/waiter/BaseWaiter.vue";
import NotificationsTool from "@/components/tools/NotificationsTool.vue";
import Datalists from "@/components/elements/Datalists.vue";
import Widgets from "@/components/tools/Widgets.vue";
import {inject, ref, watch, watchEffect} from "vue";

const props = defineProps(['service'])

/** @type {SmartEnv}*/
let env = inject('env');

/** @type Dialog */
let dialog = inject('Dialog');

const utilities = inject('Utilities')

/** @type Waiter */
let waiter = inject('Waiter')

let configurationService = inject('ConfigurationService')

/** @type NotificationService */
let notificationService = inject('NotificationService')

let notificationLoader = ref()
let notificationsTool = ref()

function loadNotifications(){
    function load(){
        notificationService.getPendingNotifications()
            .then((notifications)=>{
                env.notifications = notifications
            })
            .catch(dialog.nonCritical)
    }

    clearInterval(notificationLoader.value)
    load()
    notificationLoader.value = setInterval(()=>{
        load()
    }, 10000)
}

function loadConfiguration(){
    if(!env.login) return;
    if(!env.crew_id) return;
    env.loadingConfiguration = true
    waiter.show('Loading configuration')
    configurationService.loadGlobal()
        .then((data)=>{
            waiter.hide()
            env.configuration = data;
            env.login.user = data.user
            env.loadingConfiguration = false
        })
        .catch(error => {
            waiter.hide()
            dialog.error(error)
            env.loadingConfiguration = true
        });
}

function openNotifications(){
    console.log('opening')
}

watch(() => env.crew_id, ()=>{
    utilities.resetEnvTempVariable()
    loadConfiguration()
    loadNotifications()
})

watchEffect(()=>{

    // User is not logged in
    if(!env.login){
        dialog.login()
        return;
    }

    // Crew is not selected
    if(!env.crew_id){
        dialog.selectCrew()
        return
    }

    utilities.setCookie('crew_id', env.crew_id);

    // USer is logged in and crew is selected
    dialog.close()

})

</script>

<template>
    <div id="internal">
        <div class="header" v-if="env.configuration">
            <BaseHeader
                @openNotifications="notificationsTool = true"
            />
        </div>

        <div class="content">
            <routerView
                v-if="env.configuration && !env.loadingConfiguration"
            />
        </div>

        <DialogBase
            v-if="env.dialog"
            :dialog="env.dialog"
            @close="env.dialog = null"
        />

        <BaseWaiter v-if="env && env.waiter"/>
        <Datalists v-if="env.configuration"/>
        <Widgets v-if="utilities.isThereAnyOpenTracker()||env.obligations.length"/>

        <NotificationsTool
            v-if="notificationsTool"
            @close="notificationsTool = false"
        />
    </div>
</template>

<style lang="scss">
#internal{
    text-align: center;
    color: #f0f0f0;
    display: flex;
    flex-flow: column;
    width: 2650px;
    position: relative;
}
</style>