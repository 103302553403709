

export default{
    install: (app)=>{

        /**
         * @name InvoicingService
         */
        class InvoicingService {

            /**
             * @param transactionId
             * @param data
             * @param attrs
             * @returns {Promise<Transaction>}}
             */
            createInvoice(transactionId, data, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    path: 'createInvoice',
                    id: transactionId
                }, data)
            }

            reissueInvoice(fileId, attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    path: 'reissueInvoice',
                    id: fileId
                })
            }

            tryToGetExistingObligationRequest(transactionId, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'invoicing',
                    path: 'tryToGetExistingObligationRequest',
                    id: transactionId
                })
            }

            tryToGetExistingIncomeRequest(transactionId, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'invoicing',
                    path: 'tryToGetExistingIncomeRequest',
                    id: transactionId
                })
            }

            createInvoiceObligationRequest(data, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    path: 'createInvoiceObligationRequest',
                }, {
                    ...data,
                    invoice_request_created_by_party: 'obligation'
                })
            }

            createOrUpdateIncomeRequest(data, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'createOrUpdateIncomeRequest',
                }, data)
            }

            createIncomeRequestTransaction(id, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'createTransaction',
                    id
                })
            }

            createIncomeRequestTransactionFromTemplate(id, templateId,  attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'createTransactionFromTemplate',
                    id,
                    templateId
                })
            }

            cloneIncomeRequestTransaction(id, transactionId,  attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'cloneTransaction',
                    id,
                    transactionId
                })
            }

            pairIncomeRequestTransaction(id, transactionId,  attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'pairTransaction',
                    id,
                    transactionId
                })
            }

            unpairIncomeRequestTransaction(id, transactionId,  attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'unpairTransaction',
                    id,
                    transactionId
                })
            }

            createObligationRequestTransaction(id, attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-obligation',
                    path: 'createTransaction',
                    id
                })
            }

            createObligationRequestTransactionFromTemplate(id, templateId,  attrs = {}){
                return app.capi.create({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-obligation',
                    path: 'createTransactionFromTemplate',
                    id,
                    templateId
                })
            }

            pairObligationRequestTransaction(id, transactionId,  attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-obligation',
                    path: 'pairTransaction',
                    id,
                    transactionId
                })
            }

            unpairObligationRequestTransaction(id, transactionId,  attrs = {}){
                return app.capi.update({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-obligation',
                    path: 'unpairTransaction',
                    id,
                    transactionId
                })
            }

            getObligationRequest(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-obligation',
                    path: 'getOne',
                    id
                })
            }

            getIncomeRequest(id, attrs = {}){
                return app.capi.get({
                    ...attrs,
                    service: 'invoicing',
                    space: 'invoice-request-income',
                    path: 'getOne',
                    id
                })
            }

            /**
             * Check for missing properties
             * @param transaction
             * @return {Promise<unknown>}
             */
            checkForObligatedProperties(transaction){

                const obligatedProperties = [
                    'transaction_company_id',
                    'transaction_bank_account_id',
                    'transaction_amount_inc_vat',
                    'transaction_item',
                    //'contact_country_id',
                    //'contact_street_name',
                    //'contact_building_number',
                    //'contact_city_name',
                    //'contact_postal_zone',
                    //'company_country_id',
                    //'company_street_name',
                    //'company_building_number',
                    //'company_city_name',
                    //'company_postal_zone',
                ];

                return new Promise(resolve=>{
                    let missingProperties = obligatedProperties.filter(property=>transaction[property]==='' || transaction[property]===null)
                    let message = `Transaction ${transaction.id} is missing values: `
                    missingProperties.forEach(property=>{
                        let propertyName = app.utilities.getColumnName({space: 'transaction'}, property)
                        message += '<span class="list">' + propertyName + '</span>'
                    })
                    if(missingProperties.length) throw new Error(message)
                    resolve(transaction)
                })
            }

            utilities = class {

            }

        }

        app.service.invoicing = new InvoicingService
        app.provide('InvoicingService', new InvoicingService)

    }
}

